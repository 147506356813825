<template>
	<div class="wrapper">
		<breadrcumb />
		<cms-multi-section value="content" />
	</div>
</template>

<script>
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
export default {
	components: { Breadrcumb, CmsMultiSection },
	props: {
		alias: Number,
		title: String,
		description: String
	}
}
</script>

<style lang="scss" scoped>
::v-deep .photo-section {
	background: $gray-500;

	.blockquote-wrapper {
		margin-top: 3rem;
	}

	.content-wrapper {
		p:last-of-type {
			margin-top: 3rem;
		}
	}
}
::v-deep .img-wrapper {
	.base-image {
		border-radius: 0.5rem;
	}
}

::v-deep .blockquote-wrapper {
	margin-bottom: 1rem;

	@include media-breakpoint-up(lg) {
		margin-bottom: 0rem;
	}
}

::v-deep .section-wrapper--white {
	.grid-wrapper {
		@include media-breakpoint-up(xxl) {
			gap: 0 15rem;
		}
	}
}
::v-deep .pricing-tile {
	.pricing-tile__content-title {
		strong {
			display: block;

			@include media-breakpoint-up(xl) {
				display: inline;
			}
		}
	}
}
</style>
