const AppModal = {
	openModal (modal) {
		this.modal = modal
	},
	closeModal () {
		this.modal = null
	},
	afterRoutesCreated () {
		this.router.beforeEach((to, from, next) => {
			this.closeModal()
			next()
		})
	}
}
export {
	AppModal
}
