<template>
	<div class="wrapper">
		<breadrcumb />
		<base-container>
			<div class="section-wrapper">
				<cms-multi-section value="content" />
			</div>
		</base-container>
	</div>
</template>

<script>
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
export default {
	components: { Breadrcumb, CmsMultiSection }

}
</script>

<style lang="scss" scoped>
::v-deep .photo-section:nth-of-type(1) {
	padding: 0;
}
::v-deep .service-wrapper {
	padding-bottom: 0 !important;
	padding-top: 0 !important;

	.container {
		padding: 0;
	}
}

.section-wrapper {
	padding-bottom: 2rem;
}

.wrapper.content-wrapper {
	padding-bottom: 0;
}

::v-deep .heading-wrapper .section-header {
	line-height: 5.5rem;;
}

.section-wrapper {
	padding-bottom: 6rem;
	@include media-breakpoint-up(xl){
		padding-bottom: 12rem;
	}

	::v-deep &.photo-section{
		padding-bottom: 2rem;
	}
}

</style>
