<template>
	<div class="event-wrapper">
		<base-link to="event" :params="{alias: event.alias}">
			<div class="event-image" v-if="event">
				<cms-image
					v-if="event.thumbnail"
					lazy
					:src="event.thumbnail"
					:base="{width: 450, height: 230, position:'entropy'}"
					:sm="{width: 450, height: 230, position:'entropy'}"
					:md="{width: 450, height: 230, position:'entropy'}"
					:lg="{width: 450, height: 230, position:'entropy'}"
					:xl="{width: 450, height: 230, position:'entropy'}"
				/>
				<base-font color="black" class="event-date" :html="addStrong(formatDate)"/>
			</div>
		</base-link>
		<client-only>
			<div class="event-content">
				<base-font tag="h2" size="sm" class="event-content__title">{{ event.name }}</base-font>
				<base-font variant="paragraph" class="event-desc" :html="event.description" :cut="145"/>
				<div class="event-tags" v-if="tags">
					<event-tag v-for="(tag, index) in event.tags" :key="index" :tag="tag" :eventType="getEventType" ref="eventTagsWrapper" :eventWatcher="eventWatcher"/>
				</div>
				<base-link type="event-black" to="event" :params="{alias: event.alias}" class="event-link" :title="event.name">
					Dowiedz się więcej
					<base-svg name="arrow_right" class="arrow_right"/>
				</base-link>
			</div>
		</client-only>
	</div>
</template>

<script>
import EventTag from './EventTag.vue'
import ClientOnly from 'vue-client-only'

export default {
	components: { EventTag, ClientOnly },
	props: {
		prefix: String,
		value: Object,
		event: Object,
		eventWatcher: Function || null,
		tags: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({
		currentPageAlias: null
	}),
	computed: {
		formatDate () {
			const newDate = this.getDate({
				weekday: 'short',
				month: 'numeric',
				day: 'numeric'
			}, this.event.data.startDate)
			return newDate
		},
		getEndDate () {
			const getEndDate = this.getDate({ weekday: 'short' }, this.event.data.endDate)
			return getEndDate
		},
		getStartDate () {
			const getStartDate = this.getDate({ weekday: 'short' }, this.event.data.startDate)
			return getStartDate
		},
		getEventTagsWrapperRef () {
			return this.$refs.eventTagsWrapper
		},
		events () {
			return this.$app.page.events
		},
		getEventType () {
			if (this.currentPageAlias !== 'homepage') {
				return {
					[this.events.remaining.find(e => e === this.event)]: 'remaining',
					[this.events.upcoming.find(e => e === this.event)]: 'upcoming',
					[this.$app.page.pastEvents.find(e => e === this.event)]: 'past'
				}[this.event]
			}
			return null
		}
	},
	methods: {
		addStrong (value) {
			const { startDate, startDateSecondDay, endDateSecondDate, endDate } = this.event.data
			if ((Boolean(startDate) && Boolean(endDate)) && (!startDateSecondDay && !endDateSecondDate)) {
				const startDateDay = this.getDate({ weekday: 'short' }, this.event.data.startDate)
				const firstDayStartDate = this.getDate({
					month: 'numeric',
					day: 'numeric'
				}, this.event.data.startDate, false)
				const dates = `<strong>${startDateDay}</strong>`
				return `<span>${dates} ${firstDayStartDate}</span>`
			} else {
				const firstDay = this.getDate({ weekday: 'short' }, startDate)
				const secondDay = this.getDate({ weekday: 'short' }, startDateSecondDay)
				const firstDayStartDate = this.getDate({
					month: 'numeric',
					day: 'numeric'
				}, this.event.data.startDate, false)
				const secondDayStartDate = this.getDate({
					month: 'numeric',
					day: 'numeric'
				}, this.event.data.startDateSecondDay, false)
				const dates = `<strong>${firstDay}</strong> - <strong>${secondDay}</strong>, ${firstDayStartDate} - ${secondDayStartDate}`
				return `<span>${dates}</span>`
			}
		},
		getDate (params, date, replace = true) {
			const newDate = replace ? new Date(date).toLocaleDateString('pl-Pl', params).replace('.', '') : new Date(date).toLocaleDateString('pl-Pl', params)
			return newDate
		}
	},
	watch: {
		$route: function (val, oldVal) {
			this.currentPageAlias = val.meta.alias
		}
	},
	mounted () {
		this.$app.vue.$refs.eventTagsWrapper = this.$refs.eventTagsWrapper
	}

}
</script>

<style lang="scss" scoped>
.event-wrapper {
	border: 1px solid $primary;
	overflow: hidden;
	@include transition;
	border-radius: .8rem;

	::v-deep .event-image {
		position: relative;
		height: 175px;
		@include media-breakpoint-up(lg) {
			height: auto;
		}

		.image-wrapper {
			height: inherit;
		}

		.base-image {
			display: block;
			width: 100%;
			object-fit: cover;
			border-radius: 0.8rem 0.8rem 0 0;
			height: 100%;
		}

		.event-date {
			position: absolute;
			top: 0;
			right: 1rem;
			background-color: $white;
			padding: .5rem 2rem;
			font-size: 1.4rem;
			letter-spacing: 0.06em;
			line-height: 2rem;
			color: $secondary;

			strong {
				color: $primary;
			}

			&::first-letter {
				text-transform: uppercase;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		&:hover {
			border: 1px solid $white;
			transform: translateY(-10px);
		}
	}
}

.event-content {
	padding: 2rem;
	background: $white;
	height: 100%;
	display: flex;
	flex-direction: column;

	::v-deep .event-link {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 2rem;
		margin-bottom: 1rem;
		@include media-breakpoint-up(xl) {
			&:hover {
				.arrow_right.svg-icon {
					transform: translateX(5px);
				}
			}
		}
	}

	::v-deep &__title {
		margin-bottom: 2rem !important;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;

		@include media-breakpoint-up(sm) {
			-webkit-line-clamp: 1;
		}
	}

	::v-deep .arrow_right.svg-icon {
		width: 1rem;
		height: 1rem;
		margin-left: 1.5rem;
		@include transition;
	}

	.event-data {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		@include media-breakpoint-up(xl) {
			flex-direction: row;
		}

		.date {
			opacity: .2;
			font-size: 1.2rem;
			font-weight: 600;
			line-height: 2rem;
			letter-spacing: 0.06em;
			color: black;
			display: none;
			@include media-breakpoint-up(md) {
				display: block;
			}
		}
	}

	::v-deep .event-desc {
		word-break: break-word;
		margin-bottom: 0;
		display: block;

		@include media-breakpoint-up(sm) {
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 2rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-bottom: 0;
			min-height: 11.2rem;
		}
	}

	::v-deep .event-tags {
		margin: 2rem 0 1rem 0;
		display: flex;
		gap: 1rem 0;
		flex-wrap: wrap;
		min-height: 2.6rem;
		@include media-breakpoint-up(lg) {
			margin: 2rem 0 4rem 0;
		}
		@include media-breakpoint-up(xxl) {
			min-height: 2.6rem;
		}
	}
}
</style>
