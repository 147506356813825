import EventsPageComponent from './EventsPage.vue'
import { Page } from '~/cms/front/core/pages/Page'
import Calendar from '~/events/front/core/calendar/Calendar'

class EventsPage extends Page {
	static alias = 'events'

	component = EventsPageComponent
	calendar
	tags
	events = []
	pastEvents = []
	remainingEventsFetchHandler
	choosedTags = {
		upcoming: [],
		remaining: [],
		past: []
	}

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('events')
	}

	async initializeCalendar () {
		this.calendar = await Calendar.create(this.app)
	}

	async fetchEventTags () {
		const EventTag = this.app.getEntity('event-tag')
		const tags = await EventTag.fetch()
		this.tags = tags
	}

	async fetchEvents () {
		const Event = this.app.getEntity('event')
		const { events, getRemainingEventsHandler } = await Event.fetch({ app: this.app })
		this.events = events
		this.remainingEventsFetchHandler = getRemainingEventsHandler
	}

	async fetchRemainingEvents () {
		const Event = this.app.getEntity('event')
		const { remaining, getRemainingEventsHandler } = await Event.fetchRemainingEvents(this.remainingEventsFetchHandler)
		this.events.remaining.push(...remaining)
		this.remainingEventsFetchHandler = getRemainingEventsHandler
	}

	async fetchPastEvents () {
		const Event = this.app.getEntity('event')
		const events = await Event.fetchPastEvents({ app: this.app, tags: this.choosedTags.past })
		this.pastEvents = events
	}

	async fetchEventsByTags (eventTypes) {
		const queryObject = {
			responseSettings: {
				eventGroups: eventTypes,
				query: {
					upcoming: {
						take: 100,
						skip: 0,
						where: {
							tags: this.choosedTags.upcoming
						}
					},
					remaining: {
						take: 100,
						skip: 0,
						where: {
							tags: this.choosedTags.remaining
						}
					}
				}
			}
		}

		const Event = this.app.getEntity('event')
		const { events, getRemainingEventsHandler } = await Event.fetch({ app: this.app, responseSettings: queryObject })
		if (events.upcoming) {
			this.events.upcoming = events.upcoming
		}

		if (events.remaining) {
			this.events.remaining = events.remaining
		}
		this.remainingEventsFetchHandler = getRemainingEventsHandler
	}

	toggleChoosedTags (eventTypes, tag) {
		eventTypes.forEach(type => {
			const isTagInList = this.choosedTags[type].includes(tag)
			if (isTagInList) {
				this.choosedTags[type] = this.choosedTags[type].filter(choosedTag => choosedTag !== tag)
			} else this.choosedTags[type].push(tag)
		})
	}

	async afterRedirect () {
		await this.fetchEvents()
		await this.fetchEventTags()
		await this.initializeCalendar()
	}
}

export { EventsPage }
