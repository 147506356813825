const animationsTransition = {
	slideLeft: {
		beforeEnter: ['transform: translateX(-200px); opacity: 0; transition: all 0s linear'],
		enter: ['transform: translateX(0px); opacity: 1; transition: all .6s ease']
	},
	slideRight: {
		beforeEnter: ['transform: translateX(200px); opacity: 0; transition: all 0s linear'],
		enter: ['transform: translateX(0px); opacity: 1; transition: all .6s ease']
	},
	opacity: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease']
	},
	opacity0: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:0']
	},
	opacity1: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.05s']
	},
	opacity2: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.1']
	},
	opacity3: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.15s']
	},
	opacity4: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.2s']
	},
	opacity5: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay: .25s']
	},
	opacity6: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.3s']
	},
	opacity7: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.35s']
	},
	opacity8: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.4s']
	},
	opacity9: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.45s']
	},
	opacity10: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.5s']
	},
	opacity11: {
		beforeEnter: ['opacity: 0; transition: all 0s linear'],
		enter: ['opacity: 1; transition: all .6s ease; transition-delay:.55s']
	}
}

export const animatedScrollObserver = () => (el, binding) => {
	if (window.innerWidth >= 1200) {
		binding.value = animationsTransition[binding.value]
		const options = {
			rootMargin: '0px',
			threshold: 0.5
		}
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.style = binding.value.enter
						observer.unobserve(entry.target)
					}
				})
			},
			options
		)
		el.style = binding.value.beforeEnter
		observer.observe(el)
	} else {
		return false
	}
}
