<template>
	<base-article-feature :features="value.features"/>
</template>

<script>
import BaseArticleFeature from '../../components/atoms/BaseArticleFeature/BaseArticleFeature.vue'

export default {
	components: { BaseArticleFeature },
	computed: {},
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>

</style>
