<template>
    <base-link :url="activity.activity_link" class="activity-wrapper" :class="{last: isLast}">
        <div class="activity-content">
            <base-font variant="paragraph" weight="bold" class="activity-title">{{activity.activity_title}}</base-font>
            <base-font variant="paragraph" marginReset>{{activity.activity_date}}</base-font>
        </div>
        <cms-image
            class="activity-image"
			:src="activity.activity_image"
			:base="{width: 200, height:200, position: 'right'}"
			:sm="{width: 250, height: 250}"
			:md="{width: 200, height: 200, position: 'right'}"
            :xl="{width: 200, height: 200, position: 'center'}"
        />
    </base-link>
</template>

<script>

export default {
	components: {
	},
	props: {
		activity: Object,
		isLast: {
			type: Boolean,
			default: false
		}
	}

}
</script>
<style lang="scss" scoped>
.activity-wrapper {
	width: 100%;
    display: flex;
    border: 1px solid $gray-100;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;
    outline: 1px solid transparent;
    @include transition;
    // @include media-breakpoint-up(xl) {
    //     &:hover {
    //         border: 1px solid $primary;
    //         outline: 1px solid $primary;
    //         transform: translateY(-5px);
    //     }
    // }
    @include media-breakpoint-up(md) {
        flex-direction: column;
    }
    @include media-breakpoint-up(xl) {
        flex-direction: row;
    }
    &.last {
        margin-bottom: 0;
    }
    ::v-deep .activity-image .base-image {
        max-width: 100%;
        display: flex;
		object-fit: cover;
		object-position: center;
		cursor: initial;

		@include media-breakpoint-up(sm) {
			height: 100%;
			width: 100%;
		}
    }
    ::v-deep .activity-image {
        width: 50%;
        @include media-breakpoint-up(md) {
            width:100%;
        }
        @include media-breakpoint-up(xl) {
			width: 200px;
        }
    }
    .activity-content {
        padding: 1.5rem;
        width: 70%;
		cursor: initial;
        @include media-breakpoint-up(md) {
            width:100%;
        }
        @include media-breakpoint-up(xl) {
            width: 50%;
        }
        ::v-deep .activity-title {
            margin-bottom: 2rem;
			line-height: 2.2rem;
        }
    }
}
</style>
