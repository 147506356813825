class WebsiteHeadManager {
	_preloadTags = []

	get preloadTags () {
		return this._preloadTags.join('\n')
	}

	addImagePreload (picture) {
		const getMediaQuery = (source, previousSource) => {
			if (source.minWidth && previousSource) {
				return `(min-width: ${source.minWidth}.1px) and (max-width: ${previousSource.minWidth}px)`
			} else if (!source.minWidth) {
				return `(max-width: ${previousSource.minWidth})`
			}
			return `(min-width: ${source.minWidth}.1px)`
		}

		for (let i = 0; i < picture.length; i++) {
			const source = picture[i]
			const previousSource = picture[i - 1]

			const tag = `<link rel="preload" as="image" href="${source.src}" media="${getMediaQuery(source, previousSource)}">`
			this._preloadTags.push(tag)
		}
	}
}

export { WebsiteHeadManager }
