<template>
	<section class="section-wrapper" ref="section-remaining">
		<base-container>
			<base-font variant="heading" tag="h2" color="black" class="event__title" >{{ value.section_title }}</base-font>
			<grid-template cols="3" :container="false" v-if="getRemainingEvents.length">
				<event-card	 :event="event" v-for="(event, key) in getRemainingEvents" :key="key" ref="remainingEvents" :eventWatcher="eventWatcher"/>
			</grid-template>
			<base-font variant="h2" v-else>Niestety, ale nie ma pozostałych wydarzeń</base-font>
			<div class="loader" v-if="isLoading">
				<svg class="svg-loader" xmlns="http://www.w3.org/2000/svg" width="46.354" height="44.247" viewBox="0 0 46.354 44.247">
					<g id="loading" transform="translate(0 -11.636)">
						<path id="Path_261" data-name="Path 261" d="M10.535,221.091H2.107a2.107,2.107,0,1,0,0,4.214h8.428a2.107,2.107,0,1,0,0-4.214Z" transform="translate(0 -190.492)" fill="#0C57A6"></path>
						<path id="Path_262" data-name="Path 262" d="M382.9,221.091H374.47a2.107,2.107,0,1,0,0,4.214H382.9a2.107,2.107,0,0,0,0-4.214Z" transform="translate(-338.651 -190.492)" fill="#0C57A6" opacity="0.4"></path>
						<path id="Path_263" data-name="Path 263" d="M74.74,320.446l-5.96,5.96a2.107,2.107,0,0,0,2.98,2.98l5.96-5.96a2.107,2.107,0,0,0-2.98-2.98Z" transform="translate(-61.992 -280.29)" fill="#0C57A6" opacity="0.9"></path>
							<g id="Group_1563" data-name="Group 1563" transform="translate(21.07 11.636)">
								<path id="Path_264" data-name="Path 264" d="M234.834,11.636a2.107,2.107,0,0,0-2.107,2.107v4.214a2.107,2.107,0,0,0,4.214,0V13.743A2.107,2.107,0,0,0,234.834,11.636Z" transform="translate(-232.727 -11.636)" fill="#0C57A6" opacity="0.1"></path>
								<path id="Path_265" data-name="Path 265" d="M338.04,57.144l-5.96,5.96a2.107,2.107,0,1,0,2.98,2.98l5.96-5.96a2.107,2.107,0,0,0-2.98-2.98Z" transform="translate(-322.524 -52.463)" fill="#0C57A6" opacity="0.2"></path>
							</g>
						<path id="Path_266" data-name="Path 266" d="M234.834,360.727a2.107,2.107,0,0,0-2.107,2.107v8.428a2.107,2.107,0,0,0,4.214,0v-8.428A2.107,2.107,0,0,0,234.834,360.727Z" transform="translate(-211.657 -317.486)" fill="#0C57A6" opacity="0.8"></path>
						<path id="Path_267" data-name="Path 267" d="M335.06,320.445a2.107,2.107,0,1,0-2.98,2.98l5.96,5.96a2.107,2.107,0,0,0,2.98-2.98Z" transform="translate(-301.454 -280.29)" fill="#0C57A6" opacity="0.6"></path>
					</g>
				</svg>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import EventCard from '../../components/EventCard.vue'
export default {
	components: {
		BaseContainer,
		GridTemplate,
		EventCard
	},
	data () {
		return {
			isLoading: false
		}
	},
	computed: {
		getRemainingEvents () {
			return this.$app.page.events && this.$app.page.events.remaining ? this.$app.page.events.remaining : []
		}
	},
	methods: {
		async fetchRemainingEvents () {
			await this.$app.page.fetchRemainingEvents()
		},
		eventWatcher () {
			const observer = new IntersectionObserver((entries, observer) => {
				entries.forEach(async (entry) => {
					if (entry.isIntersecting) {
						this.isLoading = true
						try {
							if (this.$app.page.remainingEventsFetchHandler) {
								await this.fetchRemainingEvents()
								this.eventWatcher()
							} else {
								observer.unobserve(entry.target)
							}
						} catch (err) {
							console.log(err)
						} finally {
							this.isLoading = false
						}
					}
				})
			}, {
				threshold: 0.5
			})
			observer.observe(this.$refs.remainingEvents[this.$refs.remainingEvents.length - 1].$el)
		}
	},
	async mounted () {
		setTimeout(() => {
			if (this.$refs.remainingEvents && this.$refs.remainingEvents.length) {
				setTimeout(() => this.eventWatcher(), 100)
			}
		}, 500)
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
::v-deep .section-wrapper .event-wrapper {
	border: .1rem solid $gray-700;

	@include media-breakpoint-up(xl) {
            &:hover {
                border: .1rem solid $gray-700;
            }
    }
}

.loader {
	text-align: center;
	margin-top: 4rem;

	path {
		fill: $primary;
	}

	@keyframes rotate {
		from {
			transform: rotate(0);
		}

		to {
			transform: rotate(360deg);
		}
	}

	svg {
		animation: rotate infinite 1s linear;
	}
}

::v-deep .event__title {
        margin-bottom: 4rem;
		color: $secondary;
        @include media-breakpoint-up(xl) {
            margin-bottom: 6rem;
		}
}
</style>
