const pageMixin = {
	computed: {
		page () {
			return this.$app.page
		}
	},
	metaInfo () {
		return this.page.seo.getMeta()
	}
}

export {
	pageMixin
}
