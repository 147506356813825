import { DefaultLayout } from './core/layouts/DefaultLayout'
import { rextServiceExtend } from './core/services/RextService'
import registerGlobalComponents from './components/_globals'
import { BlankLayout } from './core/layouts/BlankLayout'
import { SimplifiedLayout } from './core/layouts/SimplifiedLayout'
const FaqPage = async () => (await import('./core/pages/FaqPage')).FaqPage
const ContactPage = async () => (await import('./core/pages/ContactPage')).ContactPage
const LadyEveningsPage = async () => (await import('./core/pages/LadyEveningsPage')).LadyEveningsPage
const HireRoomsPage = async () => (await import('./core/pages/HireRoomsPage')).HireRoomsPage
const StatutePage = async () => (await import('./core/pages/StatutePage')).StatutePage
const InstructorsPage = async () => (await import('./core/pages/InstructorsPage')).InstructorsPage
const DefaultPage = async () => (await import('./core/pages/DefaultPage')).DefaultPage
const NotFoundPage = async () => (await import('./core/pages/NotFoundPage')).NotFoundPage
const PricingPage = async () => (await import('./core/pages/PricingPage')).PricingPage
const SoloDancePage = async () => (await import('./core/pages/SoloDancePage')).SoloDancePage
const PoleDancePage = async () => (await import('./core/pages/PoleDancePage')).PoleDancePage
const PairsPage = async () => (await import('./core/pages/PairsPage')).PairsPage
const ForNewPeople = async () => (await import('./core/pages/ForNewPeople')).ForNewPeople
const ReportAbsencePage = async () => (await import('./core/pages/ReportAbsencePage')).ReportAbsencePage
const PhotoSection = () => import('~/website/front/sections/PhotoSection/')
const TextContent = () => import('~/website/front/sections/TextContent/')
const GallerySection = () => import('~/website/front/sections/GallerySection/')
const Subtitle = () => import('~/website/front/sections/Subtitle/')
const Buttons = () => import('~/website/front/sections/Buttons/')
const BaseSlider = () => import('~/website/front/sections/BaseSlider/')
const BaseHeading = () => import('~/website/front/sections/BaseHeading/')
const BaseFiles = () => import('~/website/front/sections/BaseFiles/')
const BaseFatures = () => import('~/website/front/sections/BaseFeatures/')
const BaseNumbers = () => import('~/website/front/sections/BaseNumbers/')
const BlockquoteSection = () => import('~/website/front/sections/BlockquoteSection/')
const AboutSection = () => import('~/website/front/sections/AboutSection/')
const WhyUsSection = () => import('~/website/front/sections/WhyUsSection/')
const BaseTiles = () => import('~/website/front/sections/BaseTiles/')
const ServiceSection = () => import('~/website/front/sections/ServiceSection/')
const PricingSection = () => import('~/website/front/sections/PricingSection/')
const TableSection = () => import('~/website/front/sections/TableSection/')
const InstructorsSection = () => import('~/website/front/sections/InstructorsSection/')
const OfferSection = () => import('~/website/front/sections/OfferSection/')
const PhotoSectionAnimation = () => import('~/website/front/sections/PhotoSectionAnimation/')
const BackgroundSection = () => import('~/website/front/sections/BackgroundSection/')
const DanceSection = () => import('~/website/front/sections/DanceSection/')
const ImageSection = () => import('~/website/front/sections/ImageSection/')
const OpinionsSection = () => import('~/website/front/sections/OpinionsSection/')
const EventSection = () => import('~/website/front/sections/EventSection/')
const StepsSection = () => import('~/website/front/sections/StepsSection/')
const StatuteSection = () => import('~/website/front/sections/StatuteSection/')
const SoloDanceSection = () => import('~/website/front/sections/SoloDanceSection/')
const SingleTableSection = () => import('~/website/front/sections/SingleTableSection/')
const CalendarSection = () => import('~/website/front/sections/CalendarSection/')
const Image = () => import('~/website/front/sections/Image/')
const Video = () => import('~/website/front/sections/Video/')
const FaqSection = () => import('~/website/front/sections/FaqSection/')
const BenefitsSection = () => import('~/website/front/sections/BenefitsSection/')
const ForNewPeopleSection = () => import('~/website/front/sections/ForNewPeopleSection/')
const TileSection = () => import('~/website/front/sections/TileSection/')
const DanceTypeSection = () => import('~/website/front/sections/DanceTypeSection/')
const ArticleFeaturesSection = () => import('~/website/front/sections/ArticleFeaturesSection/')
const ArticlePhotoSection = () => import('~/website/front/sections/ArticlePhotoSection/')
const TechnicalActivitiesSection = () => import('~/website/front/sections/TechnicalActivitiesSection/')
const TechnicalActivitiesTileSection = () => import('~/website/front/sections/TechnicalActivitiesTileSection/')
const TabSection = () => import('~/website/front/sections/TabSection/')
const BannerSection = () => import('~/website/front/sections/BannerSection/')
class WebsiteModule {
	sections = []

	constructor (app) {
		this.app = app
		this.app.sections = {}
		this.app.addSection = (alias, component) => {
			this.app.sections[alias] = component
		}
	}

	afterRouterCreated (app) {
		app.router.afterEach(async (to, from) => {
			if (process.client && from.meta.alias) window.scrollTo(0, 0)
		})
	}

	readyToAddPages (app) {
		app.addPage('not-found', NotFoundPage)
		app.addPage('default', DefaultPage)
		app.addPage('questions', FaqPage)
		app.addPage('contact', ContactPage)
		app.addPage('lady-evenings', LadyEveningsPage)
		app.addPage('hire-rooms', HireRoomsPage)
		app.addPage('statute', StatutePage)
		app.addPage('instructors', InstructorsPage)
		app.addPage('pricing', PricingPage)
		app.addPage('solo', SoloDancePage)
		app.addPage('pole-dance', PoleDancePage)
		app.addPage('pairs', PairsPage)
		app.addPage('for-new-people', ForNewPeople)
		app.addPage('report-absence', ReportAbsencePage)
	}

	readyToAddLayouts (app) {
		app.addLayout(DefaultLayout)
		app.addLayout(BlankLayout)
		app.addLayout(SimplifiedLayout)
	}

	beforeInitReactive (app) {
		registerGlobalComponents(app)
		app.addSection('slider', BaseSlider)
		app.addSection('subtitle', Subtitle)
		app.addSection('buttons', Buttons)
		app.addSection('text_content', TextContent)
		app.addSection('photo_section', PhotoSection)
		app.addSection('gallery_section', GallerySection)
		app.addSection('heading', BaseHeading)
		app.addSection('files', BaseFiles)
		app.addSection('features', BaseFatures)
		app.addSection('numbers', BaseNumbers)
		app.addSection('blockquote_section', BlockquoteSection)
		app.addSection('about_section', AboutSection)
		app.addSection('why_us', WhyUsSection)
		app.addSection('tiles', BaseTiles)
		app.addSection('singleHeading', BaseHeading)
		app.addSection('service_section', ServiceSection)
		app.addSection('pricing', PricingSection)
		app.addSection('instructors_section', InstructorsSection)
		app.addSection('offer_section', OfferSection)
		app.addSection('photo_section_animation', PhotoSectionAnimation)
		app.addSection('backround_section', BackgroundSection)
		app.addSection('dance_section', DanceSection)
		app.addSection('image_section', ImageSection)
		app.addSection('opinions', OpinionsSection)
		app.addSection('event_section', EventSection)
		app.addSection('steps', StepsSection)
		app.addSection('statute', StatuteSection)
		app.addSection('table_section', TableSection)
		app.addSection('solo', SoloDanceSection)
		app.addSection('single_table_section', SingleTableSection)
		app.addSection('calendar', CalendarSection)
		app.addSection('image', Image)
		app.addSection('video', Video)
		app.addSection('faq', FaqSection)
		app.addSection('benefits_section', BenefitsSection)
		app.addSection('for-new-people', ForNewPeopleSection)
		app.addSection('tile', TileSection)
		app.addSection('dance_type_section', DanceTypeSection)
		app.addSection('article_features_section', ArticleFeaturesSection)
		app.addSection('article_photo', ArticlePhotoSection)
		app.addSection('technical_section', TechnicalActivitiesSection)
		app.addSection('technical_tile_section', TechnicalActivitiesTileSection)
		app.addSection('tab_section', TabSection)
		app.addSection('banner_section', BannerSection)
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}
}
export { WebsiteModule }
