import { Input } from './Input'
import InputEmailComponent from '~/website/front/components/molecules/fileds/FieldEmail'

class InputEmail extends Input {
	component = InputEmailComponent
	type = 'email'
	label = 'Podaj email*'
}

export { InputEmail }
