import { Page } from '~/cms/front/core/pages/Page'
import component from './ContactPage.vue'

class ContactPage extends Page {
	static alias = 'contact'

	get header () {
		if (!this.value.title) return false
		return {
			title: this.value.title
		}
	}

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('contact')
	}

	component = component
}

export { ContactPage }
