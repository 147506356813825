const requireComponents = require.context('./atoms')

function registerGlobalComponents (app) {
	const filtredComponentsKeys = requireComponents.keys().filter((key) => key.includes('.vue'))

	filtredComponentsKeys.forEach((filePath) => {
		const componentConfig = requireComponents(filePath)
		if (!componentConfig.default.globalComponent) return null

		const componentName = filePath
			.slice(filePath.lastIndexOf('/') + 1)
			.replace('.vue', '')
		app._Vue.component(componentName, componentConfig.default || componentConfig)
	})
}

export default registerGlobalComponents
