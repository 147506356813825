<template>
	<li class="nav-item" :class="{'active': isActive, 'nav-item-dropdown': this.hasChildren, 'homepage' : item.icon}" :id="'menu-item-' + item.name.toLowerCase().replaceAll(' ', '-')">
		<template v-if="hasChildren">
			<item-with-children :item="item" />
		</template>

		<template v-else>
			<base-link :to="item.to" class="nav-link" :class="{'homepage': item.icon}">
				<div class="nav-icon-wrapper" v-if="item.icon">
					<base-svg class="nav-item-icon" :name="item.icon"/>
				</div>
				<base-font
					variant="menu"
					class="nav-position-name"
				>
					{{item.name}}
				</base-font>
			</base-link>
		</template>
	</li>
</template>
<script>
import ItemWithChildren from './ItemWithChildren'
export default {
	components: {
		ItemWithChildren
	},
	computed: {
		isActive () {
			return this.item.to.includes(this.$route.meta.alias) || this.isChildrenActive
		},
		isChildrenActive () {
			return this.item.children.find(child => child.link.includes(this.$route.meta.alias))
		},
		hasChildren () {
			return !!this.item.children?.length
		},
		isBlogCategory () {
			return this.$app.page.route.meta.alias.includes(this.item.to)
		}
	},
	props: {
		item: {
			type: Object
		}
	}
}
</script>
<style lang="scss" scoped>
#menu-item-grafik{
	@include media-breakpoint-up(xl){
		display: none;
	}
}
.nav {
	.graphic-button {
		@include media-breakpoint-up(xl) {
			margin: 0;
		}
	}

    &-item:not(.nav-item-dropdown) {
        padding: 1.5rem 3.5rem;
        background: $secondary;
        font-weight: 500;
        border-top: 1px solid $gray-600;
        border-bottom: 1px solid $gray-600;
        @include media-breakpoint-up(xl) {
            background: none;
            border: none;
            padding: 0;
        }
    }
    &-item {
        position: relative;
        cursor: pointer;
        height: 100%;
		margin-bottom: 0;
        &.active {
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top:0;
                height: 53px;
                width: 6px;
                background: $primary;
                z-index: $level1
            }
            @include media-breakpoint-up(xl) {
                &::before {
                    height: 2px;
                    width: 51px;
                    background: $white;
                    bottom: 5px;
                    top: initial;
                    transform: translateX(-50%);
                    left: 50%;
					opacity: 1;
                }
            }
        }

		@include media-breakpoint-up(xl) {
				&::before {
					position: absolute;
					content: '';
                    height: 2px;
                    width: 51px;
                    background: $white;
                    bottom: 5px;
                    transform: translateX(-50%);
                    left: 50%;
					opacity: 0;
					@include transition;
            }

			&:hover::before {
				opacity: 1;
			}
		}

		&:hover {
			.nav-position-name {
				color: $primary;
			}
		}
    }
    ::v-deep &-link {
        text-decoration: none;
		display: block;
    }
    &-item-icon {
        display: none;
        @include media-breakpoint-up(xl) {
            display: block;
            width: 12px;
            height: 12px;
        }
    }
    .homepage {
       ::v-deep .nav-position-name {
           @include media-breakpoint-up(xl) {
               display: none;
           }
        }
    }
    &-item.homepage {
        @media (min-width: 1200px) and (max-width: 1599px) {
            // display: none;
        }
    }
    ::v-deep &-link.link {
        @include media-breakpoint-up(xl) {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
}
</style>
