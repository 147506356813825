<template>
<div class="features-wrapper" v-if="articlePhoto">
	<div class="features">
			<grid-template cols="6" className="grid-wrapper" :container="false">
				<image-wrapper class="img-wrapper">
					<cms-image :src="articlePhoto.section_image"
						lazy
						:base="{width: 400}"
						:md="{width: 690}"
						:lg="{width: 400}"
						:xl="{width: 500, height: 300, position: 'top'}"
					/>
				</image-wrapper>
				<div class="features__content">
					<base-font variant="h3" color="black" class="features__title" v-if="articlePhoto.section_title">{{ articlePhoto.section_title }}</base-font>
					<cms-editor-text variant="paragraph" color="black" class="features__description" :prioritizedValue="articlePhoto.features_description" />
				</div>
			</grid-template>
		</div>
</div>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'

export default {
	components: { GridTemplate, BaseFont, ImageWrapper },
	props: {
		articlePhoto: {
			type: Object
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .features-wrapper {
	display: grid;
	gap: 3rem 0;
	margin-bottom: 3rem;
}
::v-deep .features {
	.grid-wrapper {
		gap: 2rem 2rem;
		grid-template-columns: auto;

		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(2, auto);
			align-items: center;
			gap: 0 3rem;
		}
		@include media-breakpoint-up(xl) {
			gap: 3rem 6rem;
		}

		.img-wrapper {
			margin: 0;
			max-width: initial;

			img {
				max-width: 100%;

				@include media-breakpoint-up(sm) {
					max-width: initial;
				}
			}
		}
	}

 &__content {
	display: flex;
	flex-direction: column;

		@include media-breakpoint-up(xl) {
			justify-content: center;
		}
	}

	&__description {
		a {
			@include link_styles();
		}
	}

	&__description {
		p {
			margin-bottom: 0;
		}
	}
}
</style>
