<template>
	<div class="blockquote-wrapper">
		<base-font variant="section-italic" class="italic-text">{{ title }}</base-font>
		<base-font variant="paragraph" class="description">{{ description }}</base-font>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'

export default {
	components: { BaseFont },
	props: {
		title: {
			type: String
		},
		description: {
			type: String
		}
	}
}
</script>

<style lang="scss">
.blockquote-wrapper {
	padding: 1.5rem 0 1.5rem 2rem;
	position: relative;
	margin-bottom: 3rem;

	@include media-breakpoint-up(lg) {
		padding: 1.7rem 0 1.7rem 2rem;
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: .2rem;
		background-color: $primary;
	}

	.italic-text {
		margin-bottom: 1rem;
		color: $black;
	}

	.description {
		font-size: 1.4rem;
		font-weight: 300;
		letter-spacing: 0.04em;
		font-style: italic;
		margin-bottom: 0;
		color: $black;
	}
}
</style>
