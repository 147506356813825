<template>
	<div class="gallery-modal-wrapper" @click="handleModalWrapperClick">
        <div class="gallery-modal">
            <div class="close">
                <slot/>
            </div>
            <figure class="img-wrapper" ref="modalMainImage" @click="handleClick">
                <cms-image
                    class="slide-realizations-img"
                    :src="activeImageSrc"
                    :base="{ height: 650}"
                    :lg="{height: 600}"
                    :xxl="{width: 1200, height: 800}"
					v-if="activeImageSrc"
                />
            </figure>
            <div class="thumbnails" ref="modalGallery" @click="handleModalGalleryClick">
                <figure
                    class="thumbnail"
                    :class="{active: i === index}"
                    v-for="(image, i) in images"
                    :key="image.gallery_image.path"
                    @click="goTo(i)"
                >
                    <cms-image
                        class="slide-realizations-img"
                        :src="image.gallery_image"
                        :base="{height: 45, width: 45}"
						v-if="image.gallery_image"
                    />
                </figure>
            </div>
        </div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			index: this.activeImageIndex
		}
	},
	props: {
		images: Array,
		activeImageIndex: Number,
		handleModalWrapperClick: Function
	},
	computed: {
		activeImageSrc () {
			return this.images[this.index].gallery_image
		}
	},
	methods: {
		nextImage () {
			this.goTo(this.index + 1)
		},
		prevImage () {
			this.goTo(this.index - 1)
		},
		goTo (index) {
			this.index = (index + this.images.length) % this.images.length
		},
		handleClick (e) {
			e.stopPropagation()
		},
		handleModalGalleryClick (e) {
			e.stopPropagation()
		}
	},
	mounted () {
		this.$app.vue.$refs.modalMainImage = this.$refs.modalMainImage
		this.$app.vue.$refs.modalGallery = this.$refs.modalGallery
	}
}
</script>

<style lang="scss" scoped>
.gallery-modal-wrapper {
    position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: $level9;
	background-color: rgba($black, 0.35);
	backdrop-filter: blur(4px);
    .gallery-modal {
        display: flex;
        flex-direction: column;
        width: 90vw;
        @include media-breakpoint-up(xl) {
            width: initial;
        }
        @include media-breakpoint-landscape {
            max-height: 100vh;
        }
        .thumbnails {
            display: flex;
            justify-content: center;
            gap: 1rem;
            padding: 1rem 0;
            .thumbnail {
                cursor: pointer;
                display: flex;
                outline: 2px solid transparent;
                &.active {
                    outline: 2px solid $primary;
                }
                ::v-deep .base-image {
                    max-width: 100%;
                    height: 100%;
                }
            }
        }
        .img-wrapper{
            .image-wrapper {
                display: flex;
                justify-content: center;
            }
            ::v-deep .slide-realizations-img .base-image {
                max-width: 100%;
                display: flex;
                @include media-breakpoint-landscape {
                    max-height: 75vh;
                }
            }
        }
        .close {
            position: absolute;
            right:25px;
            top:25px;
            transition: transform .3s ease;
            @include media-breakpoint-up(xl) {
                &:hover {
                    transform: rotate(75deg);
                }
            }
        }
    }
}

</style>
