<template>
 <div class="card">
    <div class="gif-wrapper">
        <img :src="url" class="gif-item" :alt="card.cards_title " :title="card.cards_title.slice(1,card.cards_title.length) + ' we Wrocławskiej Szkole Tańca'"/>
	</div>
	<base-font variant="h3" color="primary" class="card__title">{{ card.cards_title }}</base-font>
	<base-font variant="paragraph" color="black" :html="removeParagraph(card.cards_description)" class="description"/>
 </div>
</template>

<script>
import { removeParagraph } from 'utils/removeParagraph.js'
import { getFileUrl } from 'utils/getFileUrl'
export default {
	methods: { removeParagraph },
	props: {
		card: {
			type: Object
		},
		isLast: Boolean,
		gif: String
	},
	computed: {
		url () {
			return getFileUrl(this.$app, this.card.cards_gif.path)
		}
	}
}
</script>

<style lang="scss" scoped>
.image-wrapper {
	position: relative;

	@mixin image_elements {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		@include transition;
	}

	&::after {
		content: "";
		@include image_elements();
		border-radius: .5rem;
		background-color: rgba(0,0,0,.3);
	}
	::v-deep .base-image {
		border-radius: .5rem;
		max-width: 100%;
		display: flex;
	}

	.icon-wrapper {
		@include image_elements();
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: $level1;

		.svg-icon {
			max-height: 3.6rem
		}
	}
}

.dancing-elements {
	.card {
		@include transition;
		::v-deep .card__title {
			margin: 2rem 0;
		}

		&:nth-last-of-type(1) {
			@include media-breakpoint-up(xl) {
				// grid-column: 3/5;
			}
		}

		@include media-breakpoint-up(xl) {
			&:hover {
				transform: translateY(-1rem);

				.image-wrapper::after {
					opacity: 1;
				}

				.icon-wrapper {
					opacity: 1;
				}
                .gif-wrapper::after {
                    opacity: 1;
                }
                .gif-wrapper ::v-deep .gif-icon {
                    opacity: 1;
                }
			}
		}
        .gif-wrapper {
            position: relative;
			width: fit-content;
			margin: 0 auto;
            &::after{
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: all 0.3s linear;
                border-radius: 0.5rem;
                background-color: rgba(0, 0, 0, 0.3);
            }

            .gif-item {
                border-radius: .5rem;
                display: block;
				object-fit: cover;
				margin: 0 auto;
            }
            ::v-deep .gif-icon {
                opacity: 0;
                position: absolute;
                max-height: 3.6rem;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
                z-index: $level1;
            }

        }
	}
}

::v-deep .description {
	margin-bottom: 0;
}
</style>
