import { config } from '../../config/config'

class RecaptchaService {
	static alias = 'recaptcha'

	constructor (app) {
		this.app = app
		this.config = config.services.recaptcha
	}

	registerRecaptcha () {
		if (window.grecaptcha) return true
		return new Promise(resolve => {
			const script = document.createElement('script')
			document.body.appendChild(script)
			script.onload = resolve
			script.src = `https://www.google.com/recaptcha/api.js?render=${this.config.apiKey}`
		})
	}

	ready () {
		return new Promise(resolve => {
			window.grecaptcha.ready(resolve)
		})
	}

	async getToken () {
		await this.registerRecaptcha()
		await this.ready()
		const token = await window.grecaptcha.execute(this.config.apiKey, { action: 'submit' })
		return token
	}
}

export { RecaptchaService }
