export const addStrong = (value) => {
	return value.split('<<').join('<span class="strong">').split('>>').join('</span>')
}

export const addStrongEditor = (value) => {
	return value.split('&lt;&lt;').join('<span class="strong">').split('&gt;&gt;').join('</span>')
}

export const deleteStrongItems = (value) => {
	return value.replaceAll('<<', '').replaceAll('>>', '')
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}
