class EventDate {
	date
	isSelected

	constructor (date, events) {
		this.date = date
		this.events = events || []
		this.isSelected = false
	}
}

export default EventDate
