<template>
	<div class="breadcrumb" v-background="backgroundImage"/>
</template>

<script>

export default {
	props: {
		alias: Number,
		title: String,
		description: String
	},
	computed: {
		loadBreadcrumb () {
			return this.$app
		},
		backgroundImage () {
			const path = this.$app.layout.value.breadrcumb_image.path

			return {
				src: path,
				breakpoints: {
					base: { width: 1920, height: 64 },
					lg: { width: 1920, height: 64 }
				}
			}
		}
	}

}
</script>

<style lang="scss" scoped>

@mixin breadcrumb_background {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.breadcrumb {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	min-height: 5rem;

	&::before {
		@include breadcrumb_background();
		background: #131313;
		opacity: 0.86;
	}
	&::after {
		@include breadcrumb_background();
		background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
		opacity: 0.61;
	}
	@include media-breakpoint-up(xl) {
		min-height: 6.4rem;
	}
}
</style>
