<template>
 <div class="tiles-wrapper">
	<div class="tiles-wrapper__background" v-background="tilesBackgroundImage"/>
	<grid-template cols="4" className="custom-wrapper">
		<base-link :url="isExternalLink(item.tile_link) ? item.tile_link: null"  :to="isExternalLink(item.tile_link) ? null : item.tile_link" class="tile" v-for="( item, index) in tiles" :key="index" v-background="tileBackgroundImage(item)" :title="item.tile_link_title">
			<h3 class="tile__title">
				<base-svg :name="item.tile_icon"/>
				{{item.tile_title}}
			</h3>
		</base-link>
	</grid-template>
 </div>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseSvg from '../BaseSvg/BaseSvg.vue'

export default {
	components: { GridTemplate, BaseSvg },
	props: {
		tiles: {
			type: Array
		},
		tiles_background: {
			type: Object
		}
	},
	computed: {
		tilesBackgroundImage () {
			const path = this.$props.tiles_background.path

			return {
				src: path,
				breakpoints: {
					base: { width: 360, height: 221 },
					lg: { width: 1920, height: 82 }
				}
			}
		}
	},
	methods: {
		tileBackgroundImage (item) {
			return {
				src: item.tile_background.path,
				breakpoints: {
					base: { width: 360, height: 221 },
					lg: { width: 450, height: 123 }
				}
			}
		},
		isExternalLink (value) {
			if (value.includes('/')) return true
			return false
		}
	}
}
</script>

<style lang="scss">
.tiles-wrapper {
	position: relative;
	background-color: $gray-500;

	&__background {
		width: 100%;
		height: 90%;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		z-index: $level0;

		@include media-breakpoint-up(xl) {
			height: 70%;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $primary;
			opacity: .8;

		}
	}

	.grid-wrapper {
		grid-template-columns: auto;
		justify-content: center;

		@include media-breakpoint-up(md) {
			justify-content: initial;
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.tile {
	padding: 1.7rem 2.4rem;
	background-color: $white;
	z-index: $level1;
	position: relative;
	border-radius: $border-radius;
	background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
	@include transition;
	min-width: 30rem;
	@media (prefers-color-scheme: dark) {
         background-color: white;
    }

	@include media-breakpoint-up(md) {
		min-width: initial;
		display: flex !important;
		justify-content: center;
		align-items: center;
	}

	@include media-breakpoint-up(xl) {
		padding: 3.8rem 2.4rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: initial;

		&:hover {
			transform: translateY(-1rem);

			.tile__title {
				color: $primary;
			}

			.svg-icon path:not(.no-hover) {
				fill: $primary;
			}
		}
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.05);
		background-color: rgba(255, 255, 255, .93);
		border-radius: .5rem;
		border: .1rem solid $gray-100;
	}

	&__title {
		color: $secondary;
		font-size: 2.2rem;
		font-weight: 500;
		letter-spacing: 0.08em;
		line-height: 3.3rem;
		font-family: $secondary-font;
		text-transform: uppercase;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0 2rem;
		position: relative;
		z-index: $level1;
		@include transition;

		@include media-breakpoint-up(xl) {
			font-size: 3rem;
			line-height: 4.6rem;
		}
	}

	&:nth-of-type(1) .svg-icon {
		max-width: 2.44rem;
		min-width: 2.44rem;

		@include media-breakpoint-up(xl) {
			max-width: 4.76rem;
		}
	}

	&:nth-of-type(2) .svg-icon {
		max-width: 5.76rem;
		min-width: 5.76rem;

		@include media-breakpoint-up(xl) {
			max-width: 7.3rem;
		}

		.no-hover {
			pointer-events: none;
		}
	}

	&:nth-of-type(3) .svg-icon {
		max-width: 2.14rem;
		min-width: 2.14rem;

		@include media-breakpoint-up(xl) {
			max-width: 4.2rem;
		}
	}
}
</style>
