<template>
	<div class="wrapper about-wrapper">
		<breadrcumb />
		<cms-multi-section value="content"/>
	</div>
</template>

<script>
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
export default {
	components: { Breadrcumb, CmsMultiSection },
	props: {
		alias: Number,
		title: String,
		description: String
	}

}
</script>

<style lang="scss" scoped>
#app .about-wrapper ::v-deep .section-wrapper {
	padding: 6rem 0;

	@include media-breakpoint-up(xl) {
		padding: 12rem 0;
	}

	.instructor-data {

		text-align: center;
        @include media-breakpoint-up(md) {
            padding: 1.6rem 1rem;
        }
		@include media-breakpoint-up(xl) {
		padding: 1.6rem 2rem;
	}
	}

	.instructors-grid {
		&::after {
			display: none;
		}
		.instructor-wrapper {
			display: block;
		}
	}
}
</style>
