const AppVue = {
	waitForVueCallbacks: [],
	waitForVue () {
		if (this.vue) return true
		return new Promise(resolve => this.waitForVueCallbacks.push(resolve))
	},
	setVue (vue) {
		this.vue = vue
		this.waitForVueCallbacks.map(cb => cb())
	},
	setRouter (router) {
		this.router = router
		this.runLifecycle('afterRouterCreated')
	}
}

export {
	AppVue
}
