<template>
	<section class="section-wrapper">
		<base-container>
			<grid-template cols="2" class="image-section">
				<div class="image-section__content">
					<base-heading tag="h2" :heading="`${prefix}.image_section_title`" class="image-section__content-title"/>
					<base-font variant="paragraph" color="black" :html="removeParagraph(value.image_section_description)" />
				</div>
				<cms-image :value="`${prefix}.image_section_photo`"
					lazy
					:base="{width: 400}"
					:md="{width: 690}"
					:lg="{width: 400}"
					:xl="{width: 643}"
				/>
			</grid-template>
		</base-container>
	</section>
</template>

<script>
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import { removeParagraph } from 'utils/removeParagraph.js'
export default {
	methods: { removeParagraph },
	components: { BaseHeading, GridTemplate },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.section-wrapper {
	background-color: $white;
	position: relative;
	padding-bottom: 0;

	@include media-breakpoint-up(xl) {
		padding: 0;

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 3.7rem;
			background: rgba(215, 24, 28, 0.8);
		}
	}

	.image-section {
		::v-deep .image-wrapper {
			margin-bottom: 0;

			.base-image {
				display: block;
				width: 100%;
			}
		}
		&__content {
			display: flex;
			flex-direction: column;
			justify-content: center;

			&-title {
				margin-bottom: 4rem;

				@include media-breakpoint-up(xl) {
					margin-bottom: 6rem;
				}
			}
		}

		::v-deep .grid-wrapper {
			gap: 0;
			grid-template-columns: auto;

			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(2, 1fr);
				gap: 0 5rem;
			}

			@include media-breakpoint-up(xxl) {
				gap: 0 23rem;
			}
		}
	}
}
</style>
