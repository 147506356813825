<template>
	<section class="section-wrapper">
        <cms-multi-section :value="`${prefix}.section_content`" />
	</section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'

export default {
	components: {
		CmsMultiSection
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>

.section-wrapper {
	padding-top: 0;

	::v-deep .wrapper:nth-of-type(1) .h2{
		margin-top: 0;
	}

	::v-deep .btn {
		margin-top: 2rem;
	}
}
::v-deep .subtitle {
	font-family: $secondary-font;
	padding: 1.3rem 2rem;
	line-height: 4.3rem;
	text-transform: uppercase;
	font-size: 2.5rem;
	letter-spacing: 2.4px;
	background-color: $primary;
	color: $white;
	border-radius: .4rem;
	font-weight: 500;
	width: fit-content;
	margin-top: 3rem !important;

	@include media-breakpoint-up(xl) {
		padding: 1.3rem 4rem;
		font-size: 3rem;
	}

	&:first-of-type {
		margin-top: 0;
	}
}

::v-deep .h2 {
	margin-top: 6rem;

	@include media-breakpoint-up(xl) {
		margin-top: 8rem;
	}
}

::v-deep ol {
	margin-bottom: 2rem;
}
</style>
