import { Layout } from '~/cms/front/core/layouts/Layout'
import component from './DefaultLayout.vue'

class DefaultLayout extends Layout {
	static alias = 'default'
	component = component

	get showContactSection () {
		return this.app.page.route.meta.alias !== 'contact'
	}

	get configuration () {
		return this.app.vue.$configuration
	}
}

export { DefaultLayout }
