<template>
	<span class="event-tags__text" :class="{'active': isActive}"  @click="handleTagClick" ref="tag">#{{ tag }}</span>
</template>

<script>
export default {
	props: {
		tag: String,
		eventWatcher: Function || null
	},
	data: () => ({
		eventTypes: ['upcoming', 'remaining', 'past'],
		isActive: false
	}),
	methods: {
		async handleTagClick () {
			this.$app.page.toggleChoosedTags(this.eventTypes, this.tag)
			this.isActive = !this.isActive

			await this.$app.page.fetchEventsByTags(['upcoming', 'remaining'])
		}
	}
}
</script>

<style lang="scss" scoped>
	.event-tags__text {
		font-size: 1.4rem;
		letter-spacing: 0.04em;
		line-height: 2.6rem;
		color: $secondary;
		opacity: 0.5;
		text-transform: uppercase;
		margin-right: .5rem;
		cursor: pointer;
		@include transition;
		will-change: color, opacity;
		height: fit-content;
		padding: 1rem 0;

		&.active {
			opacity: 1;
			background-color: $primary;
			color: $white;
			padding: 0 1rem;
		}

		&:hover {
			@include media-breakpoint-up(xl) {
				opacity: 1;
				background-color: $primary;
				color: $white;
				padding: 0 1rem;
			}
		}

		&:last-of-type {
			margin-right: 0;
		}

        &.homepage {
            cursor: default;
        }
	}
</style>
