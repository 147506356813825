import { Layout } from '~/cms/front/core/layouts/Layout'
import component from './SimplifiedLayout.vue'

class SimplifiedLayout extends Layout {
	static alias = 'simplified'
	component = component

	get configuration () {
		return this.app.vue.$configuration
	}
}

export { SimplifiedLayout }
