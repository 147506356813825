<template>
 <div class="benefit">
	<cms-image :src="benefit.benefits_image"
		:base="{width: 210, height: 205}"
		:sm="{width: 210, height: 150}"
		:md="{width: 210, height: 250}"
	/>
	<div class="benefit__content">
		<base-font variant="h2" color="black" class="benefit__content-title">{{ benefit.benefits_title }}</base-font>
		<base-font variant="paragraph" color="black" :html="removeParagraph(benefit.benefits_description)" class="benefit__content-description"/>
	</div>
 </div>
</template>

<script>
import { removeParagraph } from 'utils/removeParagraph.js'
export default {
	methods: { removeParagraph },
	props: {
		benefit: {
			type: Object
		}
	}
}
</script>

<style lang="scss">
.dancing-benefits .benefit {
	background-color: $white;
	display: flex;
	align-items: center;
	border-radius: .5rem;

	.image-wrapper {
		margin-bottom: 0;
		height: 100%;
		min-width: 15rem;
		max-width: 15rem;
		position: relative;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: .2rem;
			background-color: $primary;
		}

		@include media-breakpoint-up(sm) {
			min-width: 21rem;
			max-width: 21rem;
		}

		.base-image {
			border-radius: 0.5rem 0 0 0.5rem;
			display: block;
			max-width: 21rem;
			height: auto;
		}
	}

	&__content {
		border-radius: 0 0.5rem 0.5rem 0;
		padding: 2rem 1rem 2rem 2rem;
		background-color: $white;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 1;
		min-height: 100%;

		@include media-breakpoint-up(xl) {
			padding-right: 2rem;
		}

		@include media-breakpoint-up(xl) {
			padding: 2rem 3rem 2rem 3rem;
		}

		@include media-breakpoint-up(xxl) {
			padding: 2rem 3rem 2rem 6rem;
		}

		&-title {
			margin-bottom: 1rem;
			text-transform: uppercase;

			@include media-breakpoint-up(xl) {
				margin-bottom: 3.5rem;
			}
		}

		&-description {
			margin-bottom: 0;
		}
	}
}
</style>
