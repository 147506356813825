<template>
	<div class="wrapper">
		<figure class="bg-video-wrapper">
			<img :src="imageUrl" alt="">
		</figure>
		<div class="error-wrapper">
			<p class="error">404</p>
			<base-font color="white" size="md"  class="error-info">Wybrana strona nie istnieje</base-font>
			<base-button url="/" type="primary" target="_self">Powrót na stronę główną</base-button>

		</div>
	</div>
</template>

<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import { getFileUrl } from 'utils/getFileUrl'

export default {
	mixins: [
		pageMixin
	],
	computed: {
		imageUrl () {
			const imagePath = 'notfound/404.jpg'
			return getFileUrl(this.$app, imagePath)
		}
	}
}
</script>

<style lang="scss" scoped>
.wrapper {
	padding: 0 2rem;
	margin: 0;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba($black, 0.6);
		z-index: -1;
	}
	.bg-video-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		z-index: -2;
		background: $black;

		::v-deep img{
			display: block;
			width: 100%;
			object-fit: cover;
			height: 100%;
		}
	}
	.error-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		grid-row: 2;
		animation: fadeToTop 1s ease-in alternate;
		@include media-breakpoint-up(lg) {
			grid-column: 2;
		}

		.error {
			color: $white;
			font-size: 15rem;
			line-height: 15rem;
			margin-block: 2rem;
			font-weight: bold;
			text-shadow: 4px 4px darken($color: $primary, $amount: 15%);
			@include media-breakpoint-landscape() {
				font-size: 10rem;
				line-height: 10rem;
			}
			@include media-breakpoint-up(lg) {
				font-size: 25rem;
				line-height: 25rem;
			}
		}
		::v-deep .error-info {
			margin-top: 0rem;
			margin-bottom: 3rem;
			font-weight: 500;
			text-transform: uppercase;
			text-align: center;
			@include media-breakpoint-landscape() {
				margin-bottom: 1rem;
			}
			@include media-breakpoint-up(lg) {
				margin-top: 1rem;
				margin-bottom: 4rem;
			}

		}
	}
	& .back-link {
		max-width: 280px;
		margin: 0 auto 0;
		display: block;
		padding: 1.5rem 3rem;
		border-radius: 5px;
		display: inline-block;
		background-color: $white;
		text-transform: uppercase;
		text-decoration: none;
		color: $primary;
		transition: .3s ease;
		&:hover {
			background-color: rgba($white, 0.7);
		}
	}
}
</style>
