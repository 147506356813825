class CookiesBack {
	constructor (context) {
		this.context = context
	}

	get (name) {
		return this.context.req.cookies[name]
	}

	remove (name) {}

	set (name, value) {
		this.context.res.cookie(name, value)
	}
}

export { CookiesBack }
