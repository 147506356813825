<template>
		<section class="section-wrapper about-section">
			<base-container>
				<base-heading tag="h1" :html="addStrong" :subheading="`${prefix}.section_subtitle`"/>
				<grid-template cols="2" :className="value.reverse ? 'reverse' : ''" container>
					<div class="text-content">
						<cms-multi-section class="section-elements" :value="`${prefix}.section_content`" />
					</div>
					<image-wrapper class="img-wrapper">
						<cms-image :value="`${prefix}.image`"
							lazy
							:base="{width: 400}"
							:md="{width: 690}"
							:lg="{width: 400}"
							:xl="{width: 530}"
						/>
					</image-wrapper>
				</grid-template>
			</base-container>
		</section>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'

export default {
	components: {
		CmsMultiSection,
		ImageWrapper,
		GridTemplate,
		BaseHeading
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		addStrong () {
			return this.value.section_title.split('<<').join('<span class="strong">').split('>>').join('</span>')
		}
	}
}
</script>
<style lang="scss" scoped>
::v-deep .img-wrapper {

	.base-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: $img-radius;
		max-width: 53rem;
	}

	@include media-breakpoint-up(xl) {
		transform: translateY(-80px);
	}
}

::v-deep .grid-wrapper {
	grid-template-columns: auto;
	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, auto);
		gap: 5rem;
	}
	@include media-breakpoint-up(lg) {
		gap: 9.3rem;
	}

	@include media-breakpoint-up(xxl) {
		gap: 15rem;
	}
}

</style>
