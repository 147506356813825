export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async sendContactFormMessage ({ name, email, phoneNumber, content }) {
			const http = await this.getHttpWithRecaptcha()
			const { status } = await http.post('/contact-form-message', {
				name,
				email,
				phoneNumber,
				content
			})
			if (status !== 201) return { error: true }
			return status
		}
	}
}
