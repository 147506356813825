<template>

<grid-template cols="6" :container="false" className="grid-custom">
	<div class="pricing-tile" v-for="( item , index) in tiles" :key="index">

		<div class="pricing-tile__content">
			<base-font variant="number-subtitle" color="primary" class="pricing-tile__content-subtitle" v-if="item.pricing_hours">{{ item.pricing_hours }}</base-font>
			<span v-html="addStrongEditor(item.pricing_title)" class="pricing-tile__content-title"/>
			<base-font variant="number-subtitle" color="black" class="pricing-tile__content-subtitle" :html="item.pricing_subtitle" />
			<base-font variant="paragraph" color="black" class="pricing-tile__content-text" :html="item.text" v-if="item.text"/>
		</div>

	</div>
</grid-template>

</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import { addStrongEditor } from 'utils/addStrong.js'

export default {
	components: { GridTemplate, BaseFont },
	methods: { addStrongEditor },
	props: {
		tiles: {
			type: Array
		}
	}
}
</script>

<style lang="scss">
#app .grid-custom {
	grid-template-columns: auto;
	grid-gap: 1rem;

	@include media-breakpoint-up(sm) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include media-breakpoint-up(lg) {
		grid-gap: 3rem;
	}
}

.pricing-tile {
	border-radius: .8rem;
	background-color: $white;
	padding: 3rem 2rem 3.1rem 2rem;
	width: 100%;
    height: 100%;
	display: flex;
    justify-content: center;

		@include media-breakpoint-up(lg) {
			padding: 5.6rem 2rem 7.6rem 2rem;
		}
	&__content {
		&-title {
			font-family: $secondary-font;
			line-height: 3rem;
			letter-spacing: 0.06em;
			font-weight: 300;
			font-size: 2rem;
			text-align: center;

			@include media-breakpoint-up(lg) {
					font-size: 4rem;
					line-height: 6rem;
			}

			.strong {
				color: $primary;
			}

			strong {
				line-height: 6rem;
				font-weight: 600;
				font-size: 3.7rem;

				@include media-breakpoint-up(sm) {
					font-size: 4rem;
				}

				@include media-breakpoint-up(lg) {
					font-size: 10rem;
					line-height: 15rem;
				}
			}

		}

		&-subtitle {
			font-size: 1.4rem;
			letter-spacing: 0.06em;
			line-height: 2rem;
			text-align: center;
			font-weight: bold;
			text-transform: uppercase;
			margin-top: 1rem;

			@include media-breakpoint-up(lg) {
					font-size: 1.8rem;
					line-height: 2.4rem;
					margin-top: 0;
			}
		}

		&-text {
			margin-top: 2rem;
			text-align: center;
		}
	}
}
</style>
