import { rextServiceExtend } from './core/services/RextService'
import { Authorization } from './core/authorization/Authorization'
import { AuthViews } from '~/authorization/front/core/pages/Auth/Auth'
const RemindPasswordPage = async () => (await import('./core/pages/RemindPassword/RemindPassword.js')).RemindPasswordPage
const CrossingPage = async () => (await import('./core/pages/CrossingPage/CrossingPage.js')).CrossingPage
const ChangePasswordPage = async () => (await import('./core/pages/ChangePassword/ChangePassword.js')).ChangePasswordPage
const AuthPage = async () => (await import('./core/pages/Auth/Auth.js')).AuthPage

class AuthorizationModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	async afterServicesCreated (app) {
		await app.waitForVue()
		app.authorization = new Authorization(app)
		await app.authorization.init()
	}

	readyToAddPages (app) {
		app.addPage('remind-password', RemindPasswordPage)
		app.addPage('change-password', ChangePasswordPage)
		app.addPage('auth', AuthPage)
		app.addPage('crossing', CrossingPage)
	}

	afterRouterCreated (app) {
		app.router.beforeEach(async (to, from, next) => {
			const isVisible = app.page.visible(app)
			if (isVisible) {
				next()
			} else if (!app.authorization.isAuthorizated) {
				app.vue.redirection.to('auth', { params: { view: AuthViews.signIn } })
				next()
			} else if (!isVisible) {
				app.vue.redirection.to('user-profile') // TODO: Poprawić
				next()
			} else next()
		})
	}
}

export { AuthorizationModule }
