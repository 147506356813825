import { Input } from './Input'
import InputRadioComponent from '~/website/front/components/molecules/fileds/FieldRadio'

const ERROR_MSG = 'Musisz wybrać jedną z opcji'

class RadioGroup {
	inputs = {}
	error = null
	value = null

	constructor (type) {
		this.name = type
		this.attribute = type
	}

	addInput (input) {
		this.inputs[input.name] = input
	}

	touchAndValidate (checked) {
		if (checked || this.value) {
			this.error = null
			return true
		}
		this.error = ERROR_MSG
		return false
	}
}

class InputRadio extends Input {
	component = InputRadioComponent
	checked = false
	value = null

	constructor ({ radioName, parent, value, checked = false, ...data }) {
		super(data)
		this.radioName = radioName
		this.parent = parent
		if (checked) this.parent.value = value
		this.value = value
	}
}

export { InputRadio, RadioGroup }
