import { Page } from '~/cms/front/core/pages/Page'
import component from './Auth.vue'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'
class AuthentificationStrategy {
	constructor (app, page) {
		this.app = app
		this.page = page
	}

	error (error) {
		this.page.view.markAsError(error)
	}
}

export const AuthViews = {
	signIn: 'logowanie',
	signUp: 'rejestracja'
}

class CheckoutAuthentificationStrategy extends AuthentificationStrategy {
	async success () {
		const draftOrder = await this.app.cart.createDraftOrder()
		return this.app.vue.redirection.to('checkout', { params: { id: draftOrder.id } })
	}
}

class DefaultAuthentificationStrategy extends AuthentificationStrategy {
	async success () {
		if (this.page.query.return) return this.app.vue.$router.replace(this.page.query.return)
		return this.app.vue.redirection.to('user-profile', {
			params: {
				view: null
			}
		})
	}
}

class AuthPage extends Page {
	static alias = 'auth'

	static checkRoute (to) {
		return Object.values(AuthViews).includes(to.params.view)
	}

	component = component
	ready = false
	view
	strategy

	constructor () {
		super(...arguments)
		const Strategy = {
			checkout: CheckoutAuthentificationStrategy,
			default: DefaultAuthentificationStrategy
		}[this.mode] || DefaultAuthentificationStrategy

		this.strategy = new Strategy(this.app, this)
		if (this.viewAlias === AuthViews.signIn) this.view = new SignIn(this.app, this.strategy)
		if (this.viewAlias === AuthViews.signUp) this.view = new SignUp(this.app, this.strategy)
	}

	get showCheckoutProgressBar () {
		return this.mode === 'checkout'
	}

	get query () {
		return {
			return: super.query.return,
			checkout: super.query.checkout !== undefined
		}
	}

	get mode () {
		if (this.query.checkout) return 'checkout'
		return 'default'
	}

	get viewAlias () {
		const views = AuthViews
		return Object.values(views).includes(this.route.params.view) ? this.route.params.view : AuthViews.signIn
	}

	async afterRedirect () {
		if (this.app.authorization.isAuthorizated) return this.strategy.success()
		if (!process.client) return false

		await this.app.translator.prefetch('auth')
		await this.view.createForm()
		this.ready = true
	}
}

export { AuthPage }
