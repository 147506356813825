import { getPath } from 'utils/getPath'

class RedirectionBack {
	constructor (context) {
		this.context = context
	}

	to (alias, { params, query } = {}, statusCode = 302) {
		const path = getPath({
			app: this.context.app.$app,
			routes: this.context.$routes,
			to: alias,
			params,
			query,
			returnIfNotFound: false
		})
		if (!path) return false

		this.context.app.$redirect({ path }, statusCode)
	}
}

export { RedirectionBack }
