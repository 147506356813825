import { Page } from '~/cms/front/core/pages/Page'
import component from './LadyEveningsPage.vue'

class LadyEveningsPage extends Page {
	static alias = 'lady-evenings'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('lady-evenings')
	}

	component = component
}

export { LadyEveningsPage }
