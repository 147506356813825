<template>
	<div class="default-layout-wrapper">
		<base-menu :menuItems="$app.layout.value.menu.items"/>
		<main class="content">
            <base-socials classList="desktop_socials"/>
            <base-button type="secondary" class="account-btn" :url="getButtonsHref('account')" ref="accountBtn" title="Twoje Konto we Wrocławskiej Szkole Tańca">
            <base-svg name="user" />
            Twoje Konto
            </base-button>
			<div class="buttons">
				<base-button :url="getButtonsHref('timetable')" type="primary">grafik i zapisy</base-button>
				<base-button :url="getButtonsHref('account')" type="secondary" icon="account_icon"><span>konto</span></base-button>
			</div>
			<slot />
		</main>
		<!-- <base-cookie/> -->
		<base-map v-if="isPageACartPage" :pins="$app.layout.value.mapData"/>
		<base-footer/>
		<base-copyright class="default-layout-footer" link="https://www.rekinysukcesu.pl/"/>
	</div>
</template>

<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
const BaseMenu = async () => (await import(/* webpackPreload: true */ '~/website/front/components/organisms/BaseMenu'))
// const BaseCookie = async () => (await import(/* webpackPreload: true */ '~/website/front/components/organisms/BaseCookie'))
const BaseFooter = async () => (await import(/* webpackPreload: true */ '~/website/front/components/sections/BaseFooter'))
const BaseMap = async () => (await import(/* webpackPreload: true */ '~/website/front/components/molecules/BaseMap'))
const BaseCopyright = async () => (await import(/* webpackPreload: true */ '~/website/front/components/sections/BaseCopyright'))
const BaseSocials = async () => (await import(/* webpackPreload: true */ '~/website/front/components/molecules/socials/BaseSocials/BaseSocials.vue'))
export default {
	components: {
		BaseMenu,
		BaseCopyright,
		BaseMap,
		BaseFooter,
		// BaseCookie,
		BaseSocials
	},
	computed: {
		isPageACartPage () {
			return this.$app.page.alias === 'homepage' || this.$app.page.alias === 'contact'
		}
	},
	methods: {
		getButtonsHref (path) {
			return this.$app.layout.value[path]
		}
	},
	mounted () {
		this.$root.$refs.accountBtn = this.$refs.accountBtn
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>
	::v-deep .buttons {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1500;
		display: flex;
		gap: 0 1.2rem;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding-bottom: 2rem;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 42px;
			background-color: $gray-100;
			z-index: -1;

			@media (orientation: landscape) and (max-width: 813px) {
				display: none;
			}

			@include media-breakpoint-up(xl) {
				display: none;
			}
		}

		@media (orientation: landscape) and (max-width: 813px) {
			display: none;
		}

		.btn {
			padding: 1.5rem 2rem;
			line-height: initial;
			font-size: 1.3rem;

			@include media-breakpoint-up(sm) {
				min-width: 17.6rem;
				padding: 1.5rem 2.95rem;
			}

			@include media-breakpoint-up(xl) {
				display: none;
			}

			&-secondary {
				padding: 1.2rem 2.95rem;
			}
		}

		.btn-primary {
			max-width: 17.6rem;
			min-width: 17.6rem;
		}

		.btn-secondary {
			max-width: 13.2rem;
			min-width: 13.2rem;
		}

		.content-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	::v-deep .btn-icon {
		min-width: 1.6rem;
		margin-right: 1rem;
	}

.default-layout-wrapper {
	padding-top: $menuHeightMobile;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 56px;
		background-color: rgba(0, 0, 0, .86);
		position: absolute;
		top: 0;
		left: 0;
		@include media-breakpoint-up(xl) {
			height: 90px;
		}

	}
	@include media-breakpoint-up(xl) {
		padding-top: $menuHeightDesktop;
	}
    .content {
        ::v-deep .account-btn {
            display: none;
            @include media-breakpoint-up(xl) {
                display: flex;
                position: fixed;
                justify-content: center;
                align-items: center;
                top: 100px;
                right: 10px;
                z-index: 1500;
                padding: 1rem 2rem;
                font-size: 1.3rem;
                .content-wrapper {
                    display: flex;
                    align-items: center;
                }
                .svg-icon {
                    width: 16px;
                    height: 20px;
                    margin-right: 1rem;
                }
            }
            @include media-breakpoint-up(xxl) {
                padding: 1.2rem 3rem;
            }
        }
    }
}
.default-layout-footer {
	margin-top: auto;
}
</style>
