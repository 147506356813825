<template>
	<div>
		<lazy-hydrate
			when-idle
			v-for="(element, key) in elements"
			:key="$app.page.alias + key"
		>
			<component
				:is="sections[element.alias]"
				:prefix="`${value}.${key}.value`"
				:value="element.value"
			/>
		</lazy-hydrate>
	</div>
</template>

<script>
import { getValue } from 'utils/objects'
import LazyHydrate from 'vue-lazy-hydration'

export default {
	props: {
		value: String,
		layout: Boolean
	},
	components: {
		LazyHydrate
	},
	computed: {
		elements () {
			let value
			if (this.layout) value = this.$app.layout.value
			else value = this.$app.page.value

			return getValue(this.value, value, 'none')
		},
		sections () {
			return this.$app.sections
		}
	}
}
</script>
