<script>
import BaseButton from '~/cms/front/components/BaseButton'

export default {
	extends: BaseButton,
	name: 'BaseButton',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.btn {
	text-transform: uppercase;
	letter-spacing: 0.12em;
	font-weight: 500;
	font-family: $primary-font;
	display: inline-block;
	border-radius: 3rem;
	color: $white;
	text-decoration: none;
	line-height: 1.9rem;
	padding: 1.5rem 2rem;
	font-size: 1.3rem;
	text-align: center;
	@include transition;

	@include media-breakpoint-up(md) {
		padding: 1.7rem 4rem;
	}

	@include media-breakpoint-up(lg) {
		font-size: 1.6rem;
	}

	&.margin-reset {
		margin: 0;
	}
	&-disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
		cursor: not-allowed;
	}
	&-primary {
		border: none;
		background-color: $primary;
		will-change: background-color;
		cursor: pointer;

		@include media-breakpoint-up(lg) {
			&:hover{
				background-color: #C11215;
			}
		}
	}
    &-menu.btn {
		background-color: $primary;
		will-change: background-color;

		@include media-breakpoint-up(lg) {
			&:hover{
				background-color: #C11215;
			}
		}
        font-size: 1.4rem;
		line-height: 1.9rem;
		letter-spacing: 0.02em;
		text-transform: uppercase;
        text-decoration: none;
        padding: 1.5rem 3rem;
		@include media-breakpoint-up(xl) {
			font-size: 1.3rem;
            line-height: 1.6rem;
            letter-spacing: 0.12em;
		}
	}

	&-secondary{
		border: 1px solid $primary;
		background-color: $white;
		color: $secondary;
		outline: solid 1px transparent;
		will-change: outline,color;
		@include transition;

		@include media-breakpoint-up(xl) {
			&:hover{
				color: $primary;
				outline-color: $primary;
			}
		}
	}
}

.safari  .btn-secondary {
	outline: none;
	will-change: border-color;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		border: 1px solid transparent;
		border-radius: 3rem;
	}
	&:hover::after {
		border-color: $primary;
	}
}
</style>
