<template>
    <div class="specification-table-wrapper" :class="title ? title.replace(/\s/g, '').toLowerCase() : null">
		<table class="specification-table" v-if="item.length">
            <thead>
                <tr class="table-head" >
                    <th v-for="(item, id) in columns" :key="id">
                        <div class="table-head-wrapper" v-if="item">
                            <span class="table-head-text" v-html="changeToSup(item)"></span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-row" v-for="(row, id) in rows" :key="id">
					<table-cell v-for="(cellValue, index) in row" :key="index" :cellValue="cellValue" />
                </tr>
            </tbody>
        </table>
		<div class="swipe-info" ref="swipe_info" v-if="active">
			<svg width="24" height="24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
				<path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"></path>
			</svg>
			<p>Przesuń palcem</p>
		</div>
    </div>
</template>
<script>
import TableCell from '~/website/front/components/molecules/TableCell/TableCell'

export default {
	components: {
		TableCell
	},
	data () {
		return {
			active: true
		}
	},
	props: {
		item: Array,
		prefix: String,
		title: String
	},
	computed: {
		columns () {
			return this.item[0]
		},
		rows () {
			return this.item.slice(1, this.item.length)
		}
	},
	methods: {
		changeToSup (string) {
			return string.replace('m2', 'm<sup>2</sup>')
		},
		handleTouchStart () {
			this.active = false
		},
		isTableSwipe () {
			const swipeInfoElement = this.$refs.swipe_info
			const tableElement = this.$refs.swipe_info.previousSibling
			if (swipeInfoElement.clientWidth === tableElement.clientWidth) {
				swipeInfoElement.style.display = 'none'
			}
		}
	},
	mounted () {
		this.$refs.swipe_info.addEventListener('touchstart', this.handleTouchStart, false)
		this.isTableSwipe()
	}
}
</script>
<style lang="scss" scoped>
.specification-table-wrapper {
    overflow: auto;
	margin-bottom: 5rem;
	position: relative;
    border-radius: 5px;
	.swipe-info {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem 0;

		@include media-breakpoint-up(xl) {
            display: none;
        }

		p {
			color: $white;
			text-transform: uppercase;
		}
	}

	.table-row {
		margin-top: 1rem;
		background-color: $white;
	}

	&.wynajmyindywidualne {
		.table-row {
			background-color: $white;
			margin-top: 1rem;

			&:last-of-type {
				.full {
					white-space: nowrap;
				}
			}
		}

		.table-head-text {
			min-width: 20rem;
			min-width: 36rem;
		}
	}

	&.wynajmykomercyjne {
		.table-row {
			background-color: $white;
			margin-top: 1rem;
		}
		.table-head-wrapper .table-head-text{
			max-width: 23rem;
			min-width: 14rem;
		}
	}

	&.naszesale {
		.table-row {
			background-color: $white;
			margin-top: 1rem;
		}

		.specification-table {
			.table-head th:first-of-type{
			background-color: $secondary;
			min-width: 10rem;
			max-width: 10rem;
		}

		.table-row .table-col:first-of-type {
			min-width: 10rem;
			max-width: 10rem;
		}
		}
	}

	&:nth-of-type(3) {
		.table-head-text {
			max-width: 15rem;
			min-width: 15rem;
		}

		.table-row {
			background-color: $white;
			margin-top: 1rem;
		}
	}
	&:nth-of-type(2) {
		.table-head-text {
			max-width: 23rem;
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}
.specification-table {
    width: 100%;
    border-collapse: collapse;

	.table-head th:first-of-type{
		background-color: $secondary;
		min-width: 22rem;

		@include media-breakpoint-up(xl) {
           min-width: 28rem;
        }
	}

	.table-row .table-col:first-of-type {
		min-width: 22rem;

		@include media-breakpoint-up(xl) {
           min-width: 28rem;
        }
	}
    .table-head {
        background: $primary;
		display: flex;

		th {
			flex-basis: 50%;
		}
        &-wrapper {
            width: 100%;
			height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem 1.5rem;
            @include media-breakpoint-up(xl) {
                padding: 2.5rem 0 2.5rem 0;
            }

        }
        &-text {
            text-transform: uppercase;
            font-size: 1.3rem;
            line-height: 1.6rem;
            letter-spacing: 0.12em;
            color: $white;
			font-weight: 600;
        }

    }
    .table-row {
		display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: .2s ease-in;

        .table-col {
            padding: 2rem 2rem;
			width: 100%;
			height: 100%;
			text-align: center;
			max-width: initial;
			display: block;
			@include p_styles();
			margin-bottom: 0;
			position: relative;

			::v-deep strong {
				font-size: 2rem;
			@include media-breakpoint-up(xl) {
                font-size: 3.2rem;
            }
			}

			::v-deep .base-image {
				max-width: 90%;

				@include media-breakpoint-up(xl) {
                max-width: 100%;
            }
			}

			&::after {
				// content: "";
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: .1rem;
				background-color: #f1f1f1;
				height: 100%;

				&:last-of-type {
					display: none;
				}
			}
        }
    }
}
:deep(s) {
	text-decoration: line-through;
}
</style>
