import { Page } from '~/cms/front/core/pages/Page'
import component from './CrossingPage.vue'

class CrossingPage extends Page {
	static alias = 'crossing'
	component = component

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('crossing-page')
	}

	authRedirect () {
		this.app.vue.redirection.to('auth')
	}
}

export { CrossingPage }
