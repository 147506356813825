<template>
	<base-container>
		<section class="section-wrapper">
			<cms-multi-section :value="`${prefix}.section_content`"/>
			<base-advantage :advantages="value.why_us"/>
			<mobile-buttons :value="`${prefix}.section_content`"/>
		</section>
	</base-container>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import BaseAdvantage from '~/website/front/components/atoms/BaseAdvantage/BaseAdvantage.vue'
import MobileButtons from '~/blog/front/components/MobileButtons/MobileButtons.vue'
export default {
	components: { CmsMultiSection, BaseContainer, BaseAdvantage, MobileButtons },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
	::v-deep(.buttons-wrapper) {
		margin-top: 3rem;
		gap: 0;
	}

	::v-deep .grid-wrapper {
		@include media-breakpoint-up(lg) {
			gap: 8rem 3rem;
			padding-top: 4rem;
		}
	}
</style>
