import { Layout } from '~/cms/front/core/layouts/Layout'
import component from './BlankLayout.vue'

class BlankLayout extends Layout {
	static alias = 'blank'
	component = component

	get configuration () {
		return this.app.vue.$configuration
	}
}

export { BlankLayout }
