<template>
		<base-container>
			<section class="section-wrapper">
				<cms-multi-section :value="`${prefix}.section_content`"/>
				<base-tab :value="value.elements" :hasParagraph="false"/>
			</section>
		</base-container>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import BaseTab from '~/website/front/components/molecules/BaseTab/BaseTab.vue'
export default {
	components: { CmsMultiSection, BaseTab },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>::v-deep .tab-buttons {
	grid-template-columns: auto;

	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(2, 1fr);
	}
}

::v-deep .list-indent.indent-3 {

	margin-left: 40px;
}

::v-deep ol, ::v-deep ul {
	h3 {
		margin: 4rem 0 2rem 0 !important;

		@include media-breakpoint-up(xl) {
			margin-top: 2rem;
		}
	}
}

::v-deep ol {
	list-style-position: inside;
	padding-left: 0;
	& > ol {
		padding-left: 0;
	}

	& > li:last-of-type {
		margin-bottom: 0;
	}

	& > li {
	padding: 0 0 0 16px;
    text-indent: -1em;
	}
}

::v-deep .tab-content {
	padding-bottom: 0;

	.ql-editor {
		margin-bottom: 0;
	}
}

</style>
