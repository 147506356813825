<template>
	<nav class="nav" v-if="tags && tags.length">
		<base-container>
			<event-nav-tag v-for="(tag, index) in tags" :key="index" :tag="tag"/>
		</base-container>
	</nav>
</template>
<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import EventNavTag from '../../components/EventNavTag.vue'
export default {
	components: {
		BaseContainer,
		EventNavTag
	},
	computed: {
		tags () {
			return this.$app.page.tags
		}
	}
}
</script>
<style lang="scss" scoped>
.nav {
	background-color: $secondary;
	padding: 2rem 0;

	.event-tags__text {
		color: $white;
		padding: 1rem;
	}
}
</style>
