<template>
    <input
        :placeholder="input.placeholder"
        :id="input.name"
        :type="type"
        :disabled="input.disable"
        v-model="input.value"
        :class="{
            invalid: input.isInvalidAndTouched
        }"
        @blur="blurHandler"
    >
</template>

<script>
export default {
	props: {
		input: Object,
		type: String
	},
	methods: {
		blurHandler () {
			if (this.input.markAsTouched) this.input.markAsTouched()
		}
	}
}
</script>
