<template>
	<section class="article-photo-top-section" :class="setPaddingClasses">
		<div class="section-inner" v-if="checkIsPositionY('top')">
			<div class="iframe-wrapper"
				v-html="value.iframe"
				v-if="value.iframe"
				:class="setJustifyClasses"
			/>

			<cms-text class="section-title" v-if="value.section_title" :value="`${prefix}.section_title`" :props="{variant: 'h2', tag: value.header_tag }" />
			<cms-editor-text :prioritizedValue="value.section_description" :props="{variant: 'paragraph'}" v-if="value.section_description" class="paragraph"/>
		</div>

		<div class="section-inner" v-else-if="checkIsPositionY('middle')">
			<cms-text class="section-title" :value="`${prefix}.section_title`" :props="{variant: 'h2', tag: value.header_tag }" v-if="value.section_title"/>
			<div class="iframe-wrapper"
				v-html="value.iframe"
				v-if="value.iframe"
				:class="setJustifyClasses"
			/>
			<cms-editor-text :prioritizedValue="value.section_description" :props="{variant: 'paragraph'}" v-if="value.section_description" class="paragraph"/>
		</div>

		<div class="section-inner" v-else-if="checkIsPositionY('bottom')">
			<cms-text class="section-title" :value="`${prefix}.section_title`" :props="{variant: 'h2', tag: value.header_tag }" v-if="value.section_title"/>
			<cms-editor-text :prioritizedValue="value.section_description" :props="{variant: 'paragraph'}" v-if="value.section_description" class="paragraph"/>
			<div class="iframe-wrapper"
				v-html="value.iframe"
				v-if="value.iframe"
				:class="setJustifyClasses"
			/>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		checkIsPositionY (position) {
			if (!this.value.image_position_y) return false
			return this.value.image_position_y === position
		}
	},
	computed: {
		setJustifyClasses () {
			if (!this.value.image_position_x) return ''

			const position = {}
			position[`justify-${this.value.image_position_x}`] = true
			return position
		},

		setPaddingClasses () {
			if (!this.value.padding) return ''

			const classes = {}
			this.value.padding.forEach(item => { classes[`padding-${item}`] = true })
			return classes
		}
	}
}
</script>
<style lang="scss" scoped>
.article-photo-top-section {

	&.padding-bottom {
		padding-bottom: 4rem;

		@include media-breakpoint-up(xl) {
			padding-bottom: 6rem;
		}
	}

	&.padding-top {
		padding-top: 4rem;

		@include media-breakpoint-up(xl) {
			padding-top: 6rem;
		}
	}

	.section-inner {
		display: flex;
		flex-direction: column;
		gap: 3rem;

		.section-title {
			margin: 0;
		}

		.iframe-wrapper {
			display: flex;
			align-items: center;

			&.justify-center {
				justify-content: center;
			}

			&.justify-left{
				justify-content: flex-start;
			}

			&.justify-right {
				justify-content: flex-end;
			}

			:deep(iframe) {
				border-radius: 1.3rem;
				max-width: 100%;
				display: block;
			}
		}
	}
}
</style>
