import { Page } from '~/cms/front/core/pages/Page'
import component from './DefaultPage.vue'

class DefaultPage extends Page {
	static alias = 'default'

	get header () {
		if (!this.value.title) return false
		return {
			title: this.value.title
		}
	}

	component = component
}

export { DefaultPage }
