import { Page } from '~/cms/front/core/pages/Page'
import component from './InstructorsPage.vue'

class InstructorsPage extends Page {
	static alias = 'instructors'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('instructors')
	}

	component = component
}

export { InstructorsPage }
