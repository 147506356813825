<template>
<section class="section-wrapper section-wrapper--dark" ref="numbersWrapper">
		<div class="numbers-wrapper">
			<grid-template cols="3">
				<div class="number" v-for="( number , index) in numbers" :key="index">

						<div class="number__content">
							<base-font variant="number-title" color="black" class="number__content-title">
							<number :from="0" translate="no" :to="number.numbers_title.replace(/\s/g, '')"  ref="number" easing="Power4.easeIn" :duration="1.5" animationPaused/>
							</base-font>

							<base-font variant="number-subtitle" color="primary" class="number__content-subtitle" :html="number.numbers_subtitle" />
						</div>

				</div>
			</grid-template>
			<base-font variant="section-italic" color="black" class="number-italic" v-if="numbers_text">{{ numbers_text }}</base-font>
		</div>
	</section>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'

export default {
	components: { GridTemplate, BaseFont },
	props: {
		numbers: {
			type: Array
		},
		numbers_text: {
			type: String
		}
	},
	data () {
		return {
			interval: '',
			counter: 0
		}
	},
	methods: {
		numberWatcher () {
			const observer = new IntersectionObserver((entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.$refs.number.forEach(number => number.play())
						observer.unobserve(entry.target)
					}
				})
			}, {
				rootMargin: '0px',
				threshold: 0.5
			})
			observer.observe(this.$refs.numbersWrapper)
		}
	},
	mounted () {
		this.numberWatcher()
	}
}
</script>

<style lang="scss" scoped>
	.numbers-wrapper .container {
		gap: 0;

		.grid-wrapper{
			gap: 9rem 0;
			grid-template-columns: auto;

			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(3, 1fr);
			}

			@media (orientation: landscape) and (max-width: 813px) {
                grid-template-columns: auto;
				gap: 3rem 0;
			}
		}
	}

	.section-wrapper {
		padding: 4rem 0 4rem 0;

		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(3, 1fr);
			padding: 7.4rem 0 6rem 0;
		}
	}

	::v-deep .number {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: 0;
			width: 70%;
			height: .1rem;
			background-color: #d4d4d4;
			transform: translateY(1rem) translateX(-45%);

			@media (orientation: landscape) and (max-width: 813px) {
				display: none;
			}

			@include media-breakpoint-up(xl) {
				left: initial;
				bottom: 0;
				right: 0;
				bottom: 0;
				width: .1rem;
				height: 100%;
				transform: translateX(1rem) translateY(1rem);
				height: 100%;
			}
		}

		&:last-of-type::after {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}

			@media (orientation: landscape) and (max-width: 813px) {
				display: none;
			}

			@include media-breakpoint-up(xl) {
				display: none;
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&-title {
				@include transition;
			}

			&-subtitle {
				@include transition;
				@include media-breakpoint-up(md) {
				text-align: center;
			}
			}
		}

		&-italic {
			text-align: center;
			margin-top: 5.5rem;
			padding: 0 2rem;

			@include media-breakpoint-up(lg) {
				margin-top: 7.4rem;
			}
		}

	@include media-breakpoint-up(xl) {
		&:hover &__content {
			&-title {
				transform: translateY(-1rem);
				color: $primary;
			}

			&-subtitle {
				transform: translateY(-1rem);
				color: $primary;
			}
		}
	}

	&:last-of-type {
		@media (orientation: landscape) and (max-width: 813px) {
            grid-column: 1/3;
		}
	}

	}
</style>
