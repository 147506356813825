<template>
	<div class="wrapper">
		<cms-multi-section :value="`${prefix}.section_content`" />
		<base-tab :value="value.content" :hasParagraph="false" class="test"/>
	</div>
</template>
<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import BaseTab from '~/website/front/components/molecules/BaseTab/BaseTab.vue'
export default {
	components: { CmsMultiSection, BaseTab },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
::v-deep .tab-content {
	padding-bottom: 0;
	p {
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.ql-editor {
		margin-bottom: 0;
	}
}
::v-deep .tab-buttons__button:nth-of-type(1) {
	padding-top: 0;

	@include media-breakpoint-up(sm) {
        padding: 2.4rem 2rem;
    }
}

.wrapper:nth-of-type(2) .test {
	margin-bottom: 3rem;

	@include media-breakpoint-up(md) {
        margin-bottom: 6rem;
    }
}
</style>
