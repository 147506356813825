<template>
	<img
		class="base-image"
		:loading="loading"
		:src="url"
		:alt="alt"
		:title="title"
		@load="$emit('imageLoaded')"
	/>
</template>

<script>
export default {
	props: {
		loading: {
			type: String,
			default: 'eager'
		},
		url: {
			type: String
		},
		alt: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		}
	}
}
</script>
