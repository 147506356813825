<template>
<div class="wrapper">
	<base-link class="news" to="article" :params="{alias: article.alias}" :title="article.title">

			<cms-image
				v-if="article.thumbnail"
				lazy
				:src="article.thumbnail"
				:base="{width: 250, height: 255}"
				:sm="{width: 250, height: 255}"
				:lg="{width: 250, height: 255}"
				:xl="{width: 250, height: 255}"
			/>

		<client-only>
			<div class="news__content">
				<span class="news__content-date">{{article.datePublished | date}}</span>
				<base-font variant="h3" color="black" class="news__content-title" :tag="homepage ? 'h3' : 'h2'">{{article.title}}</base-font>

				<base-font color="black" class="news__content-description" :html="article.introduction" />
			</div>
		</client-only>
	</base-link>
</div>
</template>
<script>
import ClientOnly from 'vue-client-only'

export default {
	components: {
		ClientOnly
	},
	props: {
		article: [Object, Number],
		prefix: String,
		imageSize: {
			type: String,
			default: 'sm'
		}
	},
	computed: {
		langs () {
			return this.$app.translator.get('articles')
		},
		homepage () {
			const routeAlias = this.$app.page.route.meta.alias
			return routeAlias === 'homepage'
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}
}
</script>
<style lang="scss" scoped>

	::v-deep .wrapper {
		height: 100%;

		@include media-breakpoint-up(xl) {
			min-height: 255px;
		}
	}

	::v-deep .grid-wrapper {
		grid-template-columns: auto;

		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	::v-deep .news {
		display: flex;
		background-color: $white;
		border-radius: .5rem;
		text-decoration: none;
		box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.05);
		border: 1px solid $gray-700;
		height: 100%;

		@include media-breakpoint-up(xl) {
            &:hover {
                border: 1px solid $primary;
                transform: translateY(-10px);
            }
        }
		.image-wrapper {
			margin: 0;
			max-width: 30rem;
			.base-image {
				border-radius: .5rem 0 0 .5rem;
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				min-width: 10rem;
				max-width: 10rem;
				max-height: 10rem;

				@include media-breakpoint-up(sm) {
					max-height: initial;
					max-width: initial;
				}
				@include media-breakpoint-up(md) {
					min-width: 15rem;
				}

				@include media-breakpoint-up(lg) {
					gap: 0 3rem;
					min-width: 21rem;
				}
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			padding: 1rem 2rem;

			@include media-breakpoint-up(lg) {
				padding: 2rem 3rem;
			}

			&-date {
				font-size: 1.2rem;
				color: rgba(27,27,27,0.4);
				letter-spacing: 0.04em;
				line-height: 2.8rem;
				font-weight: 400;

				@include media-breakpoint-up(lg) {
					font-size: 1.4rem;
					line-height: 1.8rem;
				}
			}

			&-title {
				position: relative;
				margin-bottom: 0;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;

				@include media-breakpoint-up(lg) {
					margin-bottom: 3rem;
				}

				@include media-breakpoint-up(xxl) {
					overflow: hidden;
				}

				&::after {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 9rem;
					height: .2rem;
					background-color: $primary;
					transform: translateY(1.5rem);
					display: none;

					@include media-breakpoint-up(lg) {
						display: block;
					}
				}
			}

			&-description {
			display: none;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;

				@include media-breakpoint-up(sm) {
					display: -webkit-box;
				}

				@include media-breakpoint-up(md) {
					display: -webkit-box;
                    margin-bottom: 0;
				}
			}
		}
	}
</style>
