<template>
 <div class="tile">
	<base-font variant="h3" color="black" v-if="tile.tile_title" :html="tile.tile_title" class="title"/>
	<cms-editor-text  v-if="tile.tile_item" :prioritizedValue="tile.tile_item" class="description" :props="{variant: 'paragraph',color:'black'}"/>
 </div>
</template>

<script>
import CmsEditorText from '~/cms/front/components/CmsEditorText/CmsEditorText.vue'
export default {
	components: { CmsEditorText },
	props: {
		tile: {
			tile: Object
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .title {
	z-index: 1;
	text-align: center;

	@include media-breakpoint-up(md) {
		max-width: 230px;
	}
	@include media-breakpoint-up(lg) {
		max-width: 270px;
	}
	@include media-breakpoint-up(xl) {
		max-width: initial;
	}

	&::after {
		content: none;
	}

	a {
		@include link_styles();
		font-size: inherit !important;
	}
}

::v-deep .description {
	text-align: center;
	margin-bottom: 0;

	@include media-breakpoint-up(md) {
		min-height: 6.6rem;
	}

	a {
		@include link_styles();
	}
}
.tile {
	flex-direction: column;
	&::after {
		content: none;
	}
}

</style>
