import Cookies from 'js-cookie'
import { ReactiveClass } from '@f/core/ReactiveClass'

class WebsiteLanguageManager extends ReactiveClass {
	static create (app) {
		const instance = new WebsiteLanguageManager(app)
		return instance
	}

	constructor (app) {
		super()
		this.app = app
	}

	initialLanguage

	get defaultCmsLanguage () {
		return this.app.settings.general.languages.defaultCmsLanguage
	}

	get languageSelect () {
		return document.querySelector('.goog-te-combo') || null
	}

	setInitialLanguage () {
		try {
			this.initialLanguage = Cookies.get('googtrans').split('/')[2]
		} catch (e) {
			this.initialLanguage = this.app.language
		}
	}

	async initTranslator () {
		if (window.google && window.google.translate) return

		this.app.on('language:change', languageAlias => {
			this.changeLanguage(languageAlias)
		})

		return new Promise(resolve => {
			window.googleTranslateElementInit = () => new window.google.translate.TranslateElement({ pageLanguage: 'pl' }, 'app')

			const script = document.createElement('script')
			script.onload = resolve
			script.defer = true
			script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
			document.body.appendChild(script)
		})
	}

	changeLanguage (languageAlias) {
		console.log(7)
		if (languageAlias === this.defaultCmsLanguage) {
			this.showOriginalContent()
			return
		}

		const event = new Event('change')
		this.languageSelect.value = languageAlias
		this.languageSelect.dispatchEvent(event)
	}

	showOriginalContent () {
		const iframe = document.getElementById(':1.container')
		if (!iframe) return

		const innerDoc = iframe.contentDocument || iframe.contentWindow.document
		const restoreEl = innerDoc.getElementsByTagName('button')

		for (let i = 0; i < restoreEl.length; i++) {
			if (restoreEl[i].id.indexOf('restore') >= 0) {
				if (restoreEl[i]?.click) restoreEl[i].click()
				const closeEl = innerDoc.getElementsByClassName('goog-close-link')
				if (closeEl[0]?.click) closeEl[0].click()
				return
			}
		}
	}
}

export { WebsiteLanguageManager }
