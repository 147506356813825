<template>
	<div class="navbar-wrapper" ref="menu">
        <div class="overlay" ref="menu_overlay"></div>
        <menu-logo-wrapper
            :activeMobileNav="activeMobileNav"
            @menuButtonClick="toggleMobileBtnClick"
        />
		<base-container>
			<div class="navbar-inner-wrapper">
				<div
					class="nav-wrapper"
					:class="{active: activeMobileNav}"
				>
					<div class="navbar-top">
						<div class="top-wrapper">
							<base-socials />
							<button @click.stop="toggleMobileBtnClick" class="navbar-btn-close">
								<base-svg name="close"/>
							</button>
						</div>
						<base-svg name="logo_color" class="mobile-logo"/>
					</div>
					<nav class="nav">
                    <ul class="nav-list">
							<menu-item
								v-for="item in menuItems"
								:key="item.name"
								:item="item"
							/>
							<custom-button />
							<base-translations/>
						</ul>
					</nav>
				</div>
			</div>
		</base-container>
	</div>
</template>
<script>
import MenuLogoWrapper from './components/MenuLogoWrapper'
import MenuItem from './components/MenuItem'
import BaseSocials from '../../molecules/socials/BaseSocials/BaseSocials.vue'
import BaseTranslations from '../../molecules/BaseTranslations/BaseTranslations.vue'
import CustomButton from '../../atoms/CustomButton/CustomButton.vue'
export default {
	data: () => ({
		scrollPosition: 0,
		activeMobileNav: false,
		isHomepage: false
	}),
	components: {
		MenuLogoWrapper,
		MenuItem,
		BaseSocials,
		BaseTranslations,
		CustomButton
	},
	computed: {
		phoneNumber () {
			return this.$app.settings.general.contact.phoneNumber
		}
	},
	props: {
		menuItems: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	methods: {
		toggleMobileBtnClick () {
			this.activeMobileNav = !this.activeMobileNav
		},
		closeMobileMenu () {
			this.activeMobileNav = false
		}
	},
	watch: {
		$route () {
			this.closeMobileMenu()
		}
	},
	mounted () {
		this.$root.$refs.menu = this.$refs.menu
		this.$root.$refs.menu_overlay = this.$refs.menu_overlay
	}
}
</script>
<style lang="scss" scoped>
.navbar-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $level2;
	width: 100%;
    @include transition;

    .overlay {
        display: none;
        @include media-breakpoint-up(xl) {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            pointer-events: none;
            opacity: 0;
            background-color: rgba(0, 0, 0, .3);
            transition: all 0.3s linear;
        }
    }
    &.modal-open {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
	.navbar-inner-wrapper {
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;
        @include media-breakpoint-up(xl) {
            position: initial;
        }
	}
	.navbar-top {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem 3rem 4.5rem 2rem;
		background: $gray-500;
		border-bottom: 11px solid $primary;

		.top-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(50% + 30px);
			margin-bottom: 35px;
			margin-left: auto;
		}
		.mobile-logo {
			max-width: 232px;
			height: auto;
		}
		@include media-breakpoint-up(xl) {
			display: none;
		}
		.navbar-btn-close {
			display: inline-block;
			margin: 0;
			cursor: pointer;
			border: 0;
			outline: none;
			background-color: transparent;
			& svg {
				width: 20px;
				path {
					fill: $black;
				}
			}
		}
	}
}
.nav-wrapper {
	width: 100%;
	transform: translateX(100%);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	transition: transform .4s ease-in;
	background-color: $white;
	z-index: $level2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
    overflow-y: scroll;

	@include media-breakpoint-up(xl) {
		transition: .2s ease-in;
		transform: none;
		position: static;
		background: none;
		flex-direction: row-reverse;
        overflow: initial;
        height: 90px;
		pointer-events: none;
	}

	&.active {
		transform: translateX(0);
	}
}
.nav {
	height: calc(100vh - 200px);
	background-color: $secondary;
	@include media-breakpoint-up(xl) {
		overflow-y: inherit;
		display: flex;
		height: auto;
		width: 100%;
		padding-bottom: 0;
		background-color: initial;
	}
    @media (min-width: 1200px) and (max-width: 1299px) {
        margin-left: 13rem;
    }
    @media (min-width: 1300px) {
        margin-left: 6rem;
    }
    @include media-breakpoint-up(xxl) {
        margin-left: 15rem;
    }
	&-list {
		list-style: none;
		padding: 0;
		margin: 0;
		@include media-breakpoint-up(xl) {
			display: flex;
			width: 100%;
			align-items: center;
            justify-content: flex-end;
            gap: 1.2rem;
			pointer-events: all;
		}
        @media (min-width: 1300px) {
            gap: 2rem;
        }
        @include media-breakpoint-up(xxl) {
            gap: 2.5rem;
            justify-content: center;
        }
        @media (min-width: 1920px) {
            justify-content: flex-end;
            gap: 3.6rem;
        }
	}
}
</style>
