<template>
	<div class="container">
		<breadrcumb />
		<section class="section-wrapper">
			<base-container>
			<div class="heading-wrapper">
					<div class="wrapper">
						<cms-text :additionalHtml="addStrong(value.mainTitle)" :props="{variant: 'heading', tag: 'h1', color: null }" class="h2"/>
						<cms-text :additionalHtml="value.mainSubtitle" :props="{variant: 'subheading', tag: 'span', color: null }"/>
					</div>
			</div>
				<div class="wrapper">
					<grid-template cols="6" className="grid-wrapper" :container="false">
					<skeleton-loader v-for="(article, i) in articles" :key="i" :isVisible="page.loading">
						<blog-article :article="article" />
					</skeleton-loader>
					</grid-template>
					</div>
				<div class="product-list-navigation">
					<base-pagination :pages="page.numberOfPages" :currentPage="page.currentPage" @page-change="changePage"/>
				</div>
			</base-container>
		</section>
	</div>
</template>
<script>
import BasePagination from '~/website/front/components/organisms/BasePagination'
import BlogArticle from '../article/BlogArticle.vue'
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'
import { addStrong } from 'utils/addStrong.js'

export default {
	data: () => ({
		activeDropdown: false
	}),
	computed: {
		articles () {
			return this.sortArticles(this.$app.page.articles)
		},
		value () {
			return this.$app.page.value
		},
		page () {
			return this.$app.page
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	methods: {
		addStrong,
		toggleDropdown () {
			this.activeDropdown = !this.activeDropdown
			const dropdownNode = this.$refs.dropdown.$el
			if (this.activeDropdown) dropdownNode.style.height = dropdownNode.scrollHeight + 'px'
			else dropdownNode.style.height = '0px'
		},
		changePage (page) {
			this.page.changePage(page)
		},
		sortArticles (articles) {
			return articles.sort((a, b) => {
				return new Date(a.datePublished).getTime - new Date(b.datePublished).getTime
			})
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	},
	components: {
		BasePagination,
		BlogArticle,
		SkeletonLoader,
		GridTemplate,
		Breadrcumb
	}
}
</script>
<style lang="scss" scoped>
.heading-wrapper{
		margin-bottom: 4rem;
		@include media-breakpoint-up(xl) {
			margin-bottom: 6rem;
		}

		.wrapper {
			display: block;
		}
}
::v-deep .grid-wrapper {
		grid-template-columns: auto;

		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(2, 1fr);
		}
}

.wrapper {
	gap: 0;
}

::v-deep .h1 {
	margin-bottom: 4rem;

	@include media-breakpoint-up(xl) {
		margin-bottom: 6rem;
	}
}
.blog-wrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column-reverse;
	gap: 3rem;
	width: 100%;
	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}

	.blog-aside {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 3rem;
		@include media-breakpoint-up(lg) {
			position: sticky;
			top: 13rem;
			width: auto;
		}
		.categories-btn {
			padding-block: 1rem;
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
		.blog-popular, ::v-deep .category-header {
			display: none;
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}

		::v-deep .category-header {
			margin-bottom: 2rem;
			margin-top: 0;
		}
	}
}
</style>
