class Event {
	static async fetch ({ app, responseSettings }) {
		const service = app.getService('rext')
		const { data, getRemainingEventsHandler } = await service.getEvents(responseSettings)

		if (data.error) return []
		return {
			events: this.create(data),
			getRemainingEventsHandler
		}
	}

	static async fetchOne (alias) {
		const service = this.app.getService('rext')
		const data = await service.getEvent(alias)

		if (data.error) return null

		return this.createAndReturnInstance(data)
	}

	static async fetchRemainingEvents (serviceHandler) {
		const { data, error, getRemainingEventsHandler } = await serviceHandler()
		if (error) return []

		return {
			remaining: data.remaining.map(data => this.createAndReturnInstance(data)),
			getRemainingEventsHandler
		}
	}

	static async fetchPastEvents ({ app, tags }) {
		const service = app.getService('rext')

		const data = await service.getPastEvents(tags)
		if (data.error) return []

		return data.map(event => this.createAndReturnInstance(event))
	}

	static create (eventsData) {
		const response = {}
		if (eventsData.highlighted) response.highlighted = this.createAndReturnInstance(eventsData.highlighted)
		if (eventsData.upcoming) response.upcoming = eventsData.upcoming.map(data => this.createAndReturnInstance(data))
		if (eventsData.remaining) response.remaining = eventsData.remaining.map(data => this.createAndReturnInstance(data))
		return response
	}

	static createAndReturnInstance (data = {}) {
		const instance = new Event()
		Object.entries(data).forEach(([property, value]) => {
			if (property in instance) instance[property] = value
		})

		return instance
	}

	id
	name
	alias
	thumbnail
	description
	linkToEvent
	createdAt
	highlighted
	page
	meta
	tags
	data
}

export { Event }
