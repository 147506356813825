<template>
	<div class="article-photo-section" :class="setPaddingClasses">
		<cms-text :value="`${prefix}.section_title`" :props="{variant: 'h2', tag: value.header_tag }" v-if="value.section_title"/>
		<grid-template :cols="value.image ? '2' : '1'" :className="value.section_reverse ? 'reverse' : ''" :container="false">
			<div class="text-content" :class="{noimage: !value.image}" v-if="value.section_description" slot-scope="" >
				<cms-editor-text :prioritizedValue="value.section_description" :props="{variant: 'paragraph'}" v-if="value.section_description" class="paragraph"/>
			</div>
			<image-wrapper class="img-wrapper" wider v-if="value.image" :class="{nodescription: !value.section_description}">
				<cms-image :value="`${prefix}.image`"
					lazy
					:base="{width: 400}"
					:lg="{width: 700, height: 400}"
					:xl="{width: 700, height: 400}"
				/>
			</image-wrapper>
		</grid-template>
		<cms-multi-section class="section-elements" :class="{noimage: !value.image}" :value="`${prefix}.section_content`"/>
	</div>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
export default {
	components: {
		CmsMultiSection,
		ImageWrapper,
		GridTemplate
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		setPaddingClasses () {
			if (!this.value.padding) return ''

			const classes = {}
			this.value.padding.forEach(item => { classes[`padding-${item}`] = true })
			return classes
		}
	}
}
</script>
<style lang="scss" scoped>

::v-deep .grid-wrapper {
	grid-template-columns: auto;

	@include media-breakpoint-up(xxl) {
		grid-template-columns: repeat(2, 1fr);
		gap: 0 4rem;
	}
}
.article-photo-section {
	display: flex;
	flex-direction: column;

	&.padding-bottom {
		padding-bottom: 4rem;

		@include media-breakpoint-up(xl) {
			padding-bottom: 6rem;
		}
	}

	&.padding-top {
		padding-top: 4rem;

		@include media-breakpoint-up(xl) {
			padding-top: 6rem;
		}
	}

	::v-deep .text-content {
		.paragraph {
			margin-bottom: 0;

			p {
				word-break: break-word;
			}
		}

		&.noimage {
			grid-column: 1/3;

			ul,ol {
				margin-top: 1rem;
			}
		}
	}

	::v-deep .img-wrapper {
		img {
			border-radius: 1.3rem;
			width: initial;
			max-width: 100%;
			display: block;
			margin: 0 auto;
			@include media-breakpoint-up(xl) {
				max-width: initial;
				margin: initial
			}
		}

		&.nodescription {
			grid-column: 1/3;
			display: flex;
			justify-content: center;
		}
	}  a {
		word-break: break-word;
		@include link_styles()
	}

	::v-deep .content-wrapper {
		gap: 0 !important;
		p:last-of-type {
			margin-bottom: 0;
		}

		.text {
			margin-top: 2rem;
		}
	}

	.wrapper {
		margin-bottom: 0;
	}
}
.just-photo {
	padding: 2rem 0;

	h1,h2,h3 {
		margin: 0;
	}
}

.section-elements {
	& ::v-deep :first-child {
		margin-top: 0;
	}

	::v-deep &.noimage {
		.text {
			margin-top: 0;
		}
	}

}

::v-deep .features {
	.grid-wrapper {
		align-items: center;
		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(2, auto);
		}

		@include media-breakpoint-up(xl) {
			gap: 5rem;
		}
	}
	.img-wrapper {
		max-width: 20rem;
		margin: 2rem auto 0 auto;

		img {
			max-width: 100%;
		}
	}

	.features__content {
		justify-content: center;
	}

	.features__description {
		margin-bottom: 0;
	}
}

::v-deep .buttons-wrapper {
	margin: 3rem auto 0rem auto;

	@include media-breakpoint-up(xl) {
		margin: 5rem auto 0rem auto;
	}

	.btn-primary {
		color: $white;
	}
}
</style>
