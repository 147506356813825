<template>
  <div class="wrapper">
	<base-file :files="value.files"/>
  </div>
</template>

<script>

import BaseFile from '~/website/front/components/atoms/BaseFile/BaseFile'

export default {
	components: { BaseFile },
	computed: {},
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>

</style>
