<template>
  <base-container>
		<div class="section-wrapper">
			<cms-multi-section value="content" />
		</div>
  </base-container>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'

export default {
	components: { BaseContainer, CmsMultiSection },
	props: {
		alias: Number,
		title: String,
		description: String
	}

}
</script>

<style lang="scss" scoped>
::v-deep .buttons-wrapper {
	margin-bottom: 2rem;
	justify-content: center !important;
    align-items: center;
	width: 100% !important;
	display: flex !important;
}

::v-deep .content-wrapper {
	justify-content: center;
    text-align: center;
}
</style>
