import { Page } from '~/cms/front/core/pages/Page'
import { InputPassword } from '@f/core/forms/inputs'
import { Form } from '@f/core/forms/Form'
import component from './ChangePassword.vue'
import { getPasswordRules } from 'utils/inputs/passwordRules'
import { Rule } from '@f/core/forms/inputs/InputPassword'

class ChangePasswordPage extends Page {
	static alias = 'change-password'
	component = component

	status = ''
	form = false
	processing = false

	get translations () {
		return this.app.translator.get('forms.changePassword')
	}

	async createForm () {
		await this.app.translator.prefetch('errors')
		await this.app.translator.prefetch('forms.changePassword')

		this.form = new Form()
		this.form.addInput(InputPassword.create({
			translations: () => this.app.translator.get('forms.changePassword.inputNewPassword'),
			attribute: 'newPassword',
			name: 'newPassword',
			rules: getPasswordRules(Rule, this.app)
		}))
	}

	async changePassword () {
		if (!await this.form.validate()) return false
		try {
			this.processing = true

			const { newPassword } = this.form.getValues()
			const { error } = await this.app.getService('rext').changePassword({
				token: this.params.token,
				password: newPassword
			})

			this.processing = false
			if (error) {
				this.status = {
					message: this.status = this.translations.status.PROCESSING_ERROR,
					code: 'error'
				}
			} else {
				this.status = {
					message: this.status = this.translations.status.SUCCESS,
					code: 'correct'
				}
				this.signInRedirect()
			}
		} catch (err) {
			console.error(err)
			this.status = {
				message: this.translations.status.SERVER_ERROR,
				code: 'error'
			}
		}
	}

	signInRedirect () {
		this.app.vue.redirection.to('crossing')
	}
}

export { ChangePasswordPage }
