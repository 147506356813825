<template>
    <section class="section-wrapper">
        <base-container>
            <div class="header-wrapper">
            <base-heading tag="h2" :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`" class="section-header"/>
            <base-button type="secondary" class="calendar-btn" to="events" :title="value.section_button_title">
                <base-svg :name="value.section_icon" class="calendar-icon"/>
                <span>{{value.section_button}}</span>
                </base-button>
            </div>
        </base-container>
        <grid-template cols="3" v-if="events">
            <event-card v-for="(event,index) in events" :event="event" :key="index" :tags="false"/>
        </grid-template>
    </section>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'
import EventCard from '~/events/front/components/EventCard.vue'
export default {
	components: {
		BaseHeading,
		GridTemplate,
		EventCard
	},
	async prefetch () {
		if (process.server) {
			await this.fetchEvents()
		}
	},
	data () {
		return {
			events: [],
			take: 3
		}
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		async fetchEvents () {
			const data = await this.$app.getEntity('event').fetch({
				app: this.$app,
				responseSettings: {
					responseSettings: {
						eventGroups: ['upcoming', 'remaining', 'highlighted'],
						query: {
							upcoming: {
								take: 100,
								skip: 0,
								where: {
									tags: []
								}
							},
							remaining: {
								take: 100,
								skip: 0,
								where: {
									tags: []
								}
							}
						}

					}
				}
			})

			const { highlighted, remaining, upcoming } = data.events
			const events = [highlighted, ...remaining, ...upcoming].filter(e => !!e).sort((a, b) => new Date(a.data.startDate) - new Date(b.data.startDate))
			const takeEvents = events.slice(0, this.take)
			this.events = takeEvents
		}
	},
	async mounted () {
		await this.fetchEvents()
	}
}
</script>

<style lang="scss" scoped>
::v-deep .grid-wrapper {
	grid-template-columns: auto;

	@include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
    }
	@include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}
.section-wrapper {
    padding-bottom: 0;
    .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;
        @include media-breakpoint-up(xl) {
            margin-bottom: 6rem;
        }
        ::v-deep .calendar-btn {
            .content-wrapper {
                display: flex;
            }
            padding: 1rem 2rem;
            @include media-breakpoint-up(xl) {
                padding: 1.7rem 4rem;
            }
            .svg-icon {
                width: 22px;
                height: 22px;
                @include media-breakpoint-up(xl) {
                    display: none;
                }

				path {
					fill: $black;
				}
            }
            span {
                display: none;
                @include media-breakpoint-up(xl) {
                    display: block;
                }
            }
        }
    }
    .event-wrapper {
        border: 1px solid $gray-700;
        border-radius: 8px;
        overflow: hidden;
        @include transition;
        .event-image {
            ::v-deep .base-image {
                display: flex;
            }
        }
        @include media-breakpoint-up(xl) {
            &:hover {
                border: 1px solid $primary;
                transform: translateY(-10px);
            }
        }
    }
    .event-content {
        padding: 2rem;
        background: $white;
        ::v-deep .event-link {
			display: flex;
            align-items: center;
            justify-content: center;
			margin: 2rem 0 1rem 0;
            @include media-breakpoint-up(xl) {
                &:hover {
                    .arrow_right.svg-icon  {
                        transform: translateX(5px);
                    }
                }
            }
        }
        ::v-deep .arrow_right.svg-icon {
            width: 1rem;
            height: 1rem;
            margin-left: 1.5rem;
            @include transition;
        }
        .event-data {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            @include media-breakpoint-up(xl) {
                flex-direction: row;
            }
            .date {
                opacity: .2;
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 2rem;
                letter-spacing: 0.06em;
                color: black;
                display: none;
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }
        ::v-deep .event-desc {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
                margin-bottom: 3rem;
            }
        }
    }
}
</style>
