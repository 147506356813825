
class EventTag {
	static async fetch () {
		const service = this.app.getService('rext')
		const data = await service.getEventsTags()

		if (data.error) return { error: true }

		return data
	}
}

export { EventTag }
