<template>
	<div class="wrapper">
		<breadrcumb />
		<cms-multi-section value="content"/>
	</div>
</template>

<script>
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
export default {
	components: { Breadrcumb, CmsMultiSection }

}
</script>

<style lang="scss" scoped>
.wrapper  {
    ::v-deep .pricing-tile {
        @include media-breakpoint-up(xl) {
            padding: 2rem;
        }
        @include media-breakpoint-up(xxl) {
            padding: 4rem 2rem;
        }
    }

	::v-deep .pricing-tile__content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
	}

	::v-deep .buttons-wrapper {

		@include media-breakpoint-up(xl) {
			margin-top: 0rem;
		}
	}

	::v-deep .pricing-tile__content-title {
        @include media-breakpoint-up(xl) {
            p {
                font-size: 3.5rem;
                line-height: 5rem;
            }
        }
        strong {
            display: block;

            @include media-breakpoint-up(md) {
                display: inline;
            }

            @include media-breakpoint-up(lg) {
                display: block;
                font-size: 6rem;
                line-height: 8rem;
            }

            @include media-breakpoint-up(lg) {
                display: inline;
            }

            @include media-breakpoint-up(xxl) {
                font-size: 7rem;
                line-height: 9rem;
            }
        }
	}

	::v-deep .photo-section{
		padding-bottom: 6rem;
	}
}
</style>
