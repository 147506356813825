import axios from 'axios'
import { getQuery } from 'utils/query'
import { cutQuery } from 'utils/strings'

const redirectPlugin = {
	async beforeCreate ({ redirect, url }) {
		if (process.client) return
		const query = getQuery({ take: 1000 })
		const { data } = await axios.get(`http://back:3000/redirection${query}`)
		const urlWithoutQuery = cutQuery(url)
		const route = data.find(route => urlWithoutQuery === route.from)
		if (route) {
			redirect({
				path: route.to
			}, route.statusCode)
		}
	}
}

export default redirectPlugin
