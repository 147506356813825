<template>
		<image-wrapper class="img-wrapper">
			<cms-image :src="value.img"
				lazy
				:base="{width: 318, height: 185}"
				:md="{width: 600, height: 300, position: 'center'}"
				:xl="{width: 600, height: 300}"
			/>
		</image-wrapper>
</template>
<script>
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'
export default {
	components: {
		ImageWrapper
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
::v-deep .img-wrapper {

	.base-image {
		display: block;
		margin: 0 auto;
	}
}
</style>
