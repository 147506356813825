<template>
    <div class="offer-wrapper">
        <base-link class="offer-image-wrapper" :to="offer.button_link || '#'" :title="offer.offer_title + ' we Wrocławskiej Szkole Tańca'">
            <base-font variant="offer-header" uppercase class="offer-header">{{offer.offer_title}}</base-font>
            <cms-image
            class="offer-image"
            :class="`item${elemIndex}`"
			:src="offer.offer_image"
			:base="base"
			:sm="sm"
            :md="md"
			:lg="lg"
			:xl="xl"
        />
        </base-link>
        <div class="offer-content">
            <base-font variant="paragraph" class="offer-description">{{offer.offer_text}}</base-font>
            <base-font variant="number-subtitle" color="primary" weight="bold" class="offer-divider" uppercase>Szybki zapis</base-font>
            <base-activity v-for="(item, index) in offer.offers_activities" :key="index" :activity="item" :isLast="index === offer.offers_activities.length -1 ? true : false" />
            <base-button type="primary" class="view-more" :to="offer.button_link" :title="offer.offer_title + ' we Wrocławskiej Szkole Tańca'">{{offer.button_text}}</base-button>
        </div>
    </div>
</template>

<script>
import BaseActivity from '../BaseActivity/BaseActivity.vue'

export default {
	components: {
		BaseActivity
	},
	props: {
		offer: Object,
		elemIndex: Number
	},
	computed: {
		base () {
			return this.elemIndex === 1 ? { width: 350, heigth: 250 } : this.elemIndex === 2 ? { width: 450, heigth: 300 } : { width: 150, height: 250 }
		},
		sm () {
			return this.elemIndex === 1 ? { width: 400, heigth: 350 } : this.elemIndex === 2 ? { width: 300, heigth: 200 } : { width: 150, height: 250 }
		},
		md () {
			return this.elemIndex === 1 ? { width: 400, heigth: 250 } : this.elemIndex === 2 ? { width: 500, heigth: 300 } : { width: 130, height: 250, position: 'bottom' }
		},
		lg () {
			return this.elemIndex === 1 ? { width: 400, heigth: 250 } : this.elemIndex === 2 ? { width: 200, heigth: 100 } : { width: 130, height: 250, position: 'bottom' }
		},
		xl () {
			return this.elemIndex === 1 ? { width: 400, heigth: 670, position: 'center' } : this.elemIndex === 2 ? { width: 300, heigth: 400 } : { width: 100, height: 300 }
		}
	}
}
</script>
<style lang="scss" scoped>
    .offer-content {
        background: $white;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media-breakpoint-up(xl) {
            padding: 2rem 3rem;
			border-radius: 0 0 5px 5px;
			min-height: 775px;
        }
        ::v-deep .offer-description {
            text-align: center;

			@include media-breakpoint-up(md) {
				min-height: 22rem;
			}
			@include media-breakpoint-up(lg) {
				min-height: 20rem;
			}

			@include media-breakpoint-up(xxl) {
				min-height: 14rem;
			}
        }
        ::v-deep .view-more {
            margin: 3rem 0 2rem 0;
            @media (min-width: 768px) and (max-width: 992px) {
                font-size: 1rem;
            }

			@include media-breakpoint-up(xl) {
				margin-top: auto;
				margin-bottom: 2rem;
				justify-self: flex-end;
			}
        }
        ::v-deep .offer-divider {
            position: relative;
            margin-bottom: 2rem;
            @include media-breakpoint-up(xl) {
                margin-bottom: 3rem;
                &::before, &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    height: 1px;
                    width: calc(100% - 2.5rem);
                    background: $gray-100;
                }
                &::before {
                    right: calc(100% + 3rem);
                }
                &::after {
                    left: calc(100% + 3rem);
                }
            }
        }
    }
    ::v-deep .offer-image .base-image {
        max-width: 100%;
        display: flex;
        position: relative;
    }
    ::v-deep .offer-image.item1 .base-image {
        @include media-breakpoint-up(sm) {
            margin: 0 auto;
        }
        @include media-breakpoint-up(xl) {
            transform: translateY(20px);
        }
    }
    ::v-deep .offer-image.item2 .base-image {
            margin: 0 auto;
            transform: translateY(-85px);
        @include media-breakpoint-up(md) {
            transform: translateY(-15px);
        }
        @include media-breakpoint-up(xl) {
            transform: translateY(-30px);
        }
    }
    ::v-deep .offer-image.item3 .base-image {
            margin: 0 auto;
            transform: translateY(-50px);
        @include media-breakpoint-up(md) {
            margin: 0 auto;
            transform: translateY(-75px);
        }
        @include media-breakpoint-up(xl) {
            transform: translateY(-30px);
            margin: 0 auto;
        }
    }
    .offer-wrapper {
        border-radius: 5px;
        box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        .offer-image-wrapper {
            background: $white;
            position: relative;
            max-height: 16rem;
            overflow: hidden;
            box-shadow: 0px 3px 35px rgba(0, 0, 0, 0.16);
			display: block;
            @include media-breakpoint-up(xl) {
                max-height: 22.5rem;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height:100%;
                width:100%;
                background: $primary;
                z-index: 1;
                opacity: .45;
                @include media-breakpoint-up(xl) {
                    content: none;
                }
            }
            ::v-deep .offer-header {
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: $level2;
                width: 100%;
				text-align: center;
                 @include media-breakpoint-up(xl) {
                    top:3rem;
                    left:50%;
                    transform: translate(-50%, -3rem);
                    margin: 3rem auto 0 auto;
                }
            }
        }
    }

</style>
