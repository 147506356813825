export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getSettings () {
			const { data, status } = await this.http.get('/setting')

			if (status !== 200) return { error: true }
			return data
		}

		async getSettingsByAlias (alias) {
			const { data, status } = await this.http.get(`/setting/${alias}`)

			if (status !== 200) return { error: true }
			return data
		}
	}
}
