<script>
export default {
	functional: true,
	props: {
		wider: Boolean
	},
	render (h, { props, data, children }) {
		data.class = Object.assign({}, data.class, {
			'content-wrapper': true,
			wider: props.wider
		})
		return h('figure', data, children)
	}
}

</script>
<style lang="scss" scoped>
.content-wrapper {

	& ::v-deep img {
		width: 100%;
	}
	@include media-breakpoint-up(lg) {
		&.wider {
			width: calc(100% + 15px);
		}
	}
	@include media-breakpoint-up(xl) {
		margin: 0;
	}
}
</style>
