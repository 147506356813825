<template>
        <section class="section-wrapper" :id="getName" ref="table">
				<base-font variant="h2" color="black" v-if="value.single_table_title">{{ value.single_table_title }}</base-font>
				<div class="description-wrapper">
				<cms-editor-text variant="paragraph" color="black" v-if="value.description" :prioritizedValue="removeParagraph(value.description)" />
				<div class="icons-wrapper" v-if="value.icons.length">
					<base-svg :name="item.icon" v-for="(item, index) in value.icons" :key="index"/>
				</div>
				</div>
				<base-font variant="h3" color="black" v-if="value.table_title">{{ value.table_title }}</base-font>
                <base-table :item="value.table" :title="value.single_table_title.replace('#','')"/>
				<div class="list" v-html="value.list" v-if="value.list"/>
        </section>
</template>
<script>
import BaseTable from '../../components/molecules/BaseTable/BaseTable.vue'
import { removeParagraph } from 'utils/removeParagraph.js'
import BaseSvg from '../../components/atoms/BaseSvg/BaseSvg.vue'

export default {
	components: {
		BaseTable,
		BaseSvg
	},
	methods: { removeParagraph },
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		getName () {
			return this.value.single_table_title.slice(2).split(' ').join('_').toLowerCase()
		}
	},
	mounted () {
		this.$root.$refs[this.getName] = this.$refs.table
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding-bottom: 0;
	padding-top: 6rem;
	position: relative;

	@include media-breakpoint-up(xl) {
        padding-top: 8rem;
    }

	&::after {
		content: "";
		position: absolute;
		top: 3rem;
		left: 0;
		width: 100%;
		height: .1rem;
		background-color: $gray-200;

		@include media-breakpoint-up(xl) {
			top: 4rem;
		}
	}

	.description-wrapper {
		display: flex;
		flex-direction: column;
		gap: 0rem 3rem;

		a {
			@include link_styles()
		}

		@include media-breakpoint-up(md) {
            flex-direction: row;
        }
		@include media-breakpoint-up(lg) {
            margin-bottom: 5rem;
        }

		.icons-wrapper {
			display: flex;
			justify-content: center;
			gap: 2rem 5rem;
			margin-bottom: 5rem;

			@include media-breakpoint-up(lg) {
            margin-bottom: 0rem;
        }
		}

		.svg-icon {
			max-width: 8rem;

			@include media-breakpoint-up(md) {
                min-width: 8rem;
            }

			@include media-breakpoint-up(xl) {
                min-width: 10rem;
            }
		}
	}
}
::v-deep .service-wrapper {
	padding-bottom: 0;
}
.list {
	margin-top: 3rem;

	::v-deep ul {
		margin-top: 2rem;
	}

	::v-deep ol {
		li:last-of-type {
			margin-bottom: 0;
		}
	}

	::v-deep p {
		@include p_styles()
	}

	::v-deep a {
		@include link_styles()
	}
}
</style>
