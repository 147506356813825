<template>
	<div class="calendar__article" v-if="value">
		<client-only>
			<base-link to="event" :params="{alias: value.alias}" >
				<div class="calendar__article-image">
					<cms-image
						v-if="value.thumbnail"
						lazy
						:src="value.thumbnail"
						:base="{width: 360, height: 200}"
						:sm="{width: 400, height: 200, position:'center'}"
						:md="{width: 450, height: 250, position:'center'}"
						:lg="{width: 650, height: 300, position:'center'}"
						:xl="{width: 330, height: 220, position:'center'}"
					/>
					<div class="calendar__article-day">
						<base-font color="black" class="event-date" :html="addStrong(formatDate)" />
					</div>
				</div>
			</base-link>
			<div class="calendar__article-content">
				<base-font variant="h2" class="event-content__title">{{ value.name }}</base-font>
				<base-font variant="paragraph" class="event-desc" :html="value.description" :cut="135" />
				<base-link type="event-black" to="event" :params="{alias: value.alias}" class="event-link">
						Dowiedz się więcej
						<base-svg name="arrow_right" class="arrow_right" />
				</base-link>
			</div>
		</client-only>
	</div>
</template>

<script>
import ClientOnly from 'vue-client-only'

export default {
	components: {
		ClientOnly
	},
	props: {
		value: Object
	},
	computed: {
		formatDate () {
			const newDate = this.getDate({ weekday: 'short', month: 'numeric', day: 'numeric' }, this.value.data.startDate)
			return newDate
		},
		getEndDate () {
			const getEndDate = this.getDate({ weekday: 'short' }, this.value.data.endDate)
			const getStartDate = this.getStartDate
			return getEndDate === getStartDate ? '' : getEndDate
		},
		getStartDate () {
			const getStartDate = this.getDate({ weekday: 'short' }, this.value.data.startDate)
			return getStartDate
		}
	},
	methods: {
		addStrong (value) {
			const { startDate, startDateSecondDay, endDateSecondDate, endDate } = this.value.data
			if ((Boolean(startDate) && Boolean(endDate)) && (!startDateSecondDay && !endDateSecondDate)) {
				const startDateDay = this.getDate({ weekday: 'short' }, this.value.data.startDate)
				const firstDayStartDate = this.getDate({ month: 'numeric', day: 'numeric' }, this.value.data.startDate, false)
				const dates = `<strong>${startDateDay}</strong>`
				return `<span>${dates} ${firstDayStartDate}</span>`
			} else {
				const firstDay = this.getDate({ weekday: 'short' }, startDate)
				const secondDay = this.getDate({ weekday: 'short' }, startDateSecondDay)
				const firstDayStartDate = this.getDate({ month: 'numeric', day: 'numeric' }, this.value.data.startDate, false)
				const secondDayStartDate = this.getDate({ month: 'numeric', day: 'numeric' }, this.value.data.startDateSecondDay, false)
				const dates = `<strong>${firstDay}</strong> - <strong>${secondDay}</strong>, ${firstDayStartDate} - ${secondDayStartDate}`
				return `<span>${dates}</span>`
			}
		},
		getDate (params, date, replace = true) {
			const newDate = replace ? new Date(date).toLocaleDateString('pl-Pl', params).replace('.', '') : new Date(date).toLocaleDateString('pl-Pl', params)
			return newDate
		}
	}
}
</script>

<style scoped lang="scss">
	.calendar__article {
		&-day {
			::v-deep .event-date {
				position: absolute;
				top: 1rem;
				right: 1rem;
				background-color: $white;
				padding: .5rem 2rem;
				font-size: 1.4rem;
				letter-spacing: 0.06em;
				line-height: 2rem;
				color: $secondary;

				@include media-breakpoint-up(lg) {
					top: 1rem;
					right: 1rem;
					transform: initial;
				}

				strong {
					color: $primary;
				}

				&::first-letter {
					text-transform: uppercase;
				}
			}
		}

		&-content {
			::v-deep .event-link {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 2rem 0 1rem 0;
				@include media-breakpoint-up(xl) {
					justify-content: flex-start;
					margin: 4rem 0 1rem 0;
					&:hover {
						.arrow_right.svg-icon  {
							transform: translateX(5px);
						}
					}
				}
			}

			::v-deep .arrow_right.svg-icon {
				width: 1rem;
				height: 1rem;
				margin-left: 1.5rem;
				@include transition;
			}

			::v-deep .event-content__title {
				margin: 2rem 0;
				text-align: left;

				@include media-breakpoint-up(xl) {
					margin-top: 0;
					line-height: 3.6rem;
				}
			}
			::v-deep .event-desc {
				text-align: left;
				max-width: 69rem;
				margin-bottom: 0;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				word-break: break-word;
				overflow: hidden;

				@include media-breakpoint-up(sm) {
					display: -webkit-box;
				}
			}
		}

		&-image {
			position: relative;
			width: 100%;
			object-fit: cover;
			border-radius: 13px;
			margin: 0 auto;
			display: block;
			@include media-breakpoint-up(sm) {
				width: 70%;
			}
			@include media-breakpoint-up(xl) {
				width: initial;
			}
			::v-deep .image-wrapper {
				@include media-breakpoint-up(xl) {
					height: 100%;
					min-width: 20rem;
				}
			}
			::v-deep .base-image {
				border-radius: .7rem;
				display: block;
				max-width: 100%;
				object-fit: cover;

				@include media-breakpoint-up(xl) {
					height: 100%;
					width: initial;
				}
			}
		}

	}

</style>
