<template>
	<button
		class="button-close"
		@click="emitClose"
	>
		<base-svg name="close" />
	</button>
</template>
<script>
export default {
	methods: {
		emitClose (event) {
			this.$emit('close', event)
		}
	},
	mounted () {
		this.keydownHandler = (e) => {
			if (e.key === 'Escape') this.emitClose(e)
		}
		window.addEventListener('keydown', this.keydownHandler)
	},
	destroyed () {
		window.removeEventListener('keydown', this.keydownHandler)
	}
}
</script>
<style lang="scss" scoped>
	.button-close{
		width: 30px;
		cursor: pointer;
		background: transparent;
		border: none;
		outline: none;

		::v-deep svg {
			width: 18px;
			height: 18px;
		}

		&:hover {
			stroke-width: 2px;
		}
	}
	.safari .button-close {
		width: 34px;
	}
</style>
