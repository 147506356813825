import { RedirectionBack } from './RedirectionBack'
import { RedirectionFront } from './RedirectionFront'

class Redirection {
	constructor (context) {
		if (context.isServer) {
			this.strategy = new RedirectionBack(context)
		} else {
			this.strategy = new RedirectionFront()
		}
	}

	to (name, data, statusCode) {
		return this.strategy.to(name, data, statusCode)
	}
}

export { Redirection }
