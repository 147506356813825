import { render, staticRenderFns } from "./ArticlePhotoTopSection.vue?vue&type=template&id=cf6430b6&scoped=true"
import script from "./ArticlePhotoTopSection.vue?vue&type=script&lang=js"
export * from "./ArticlePhotoTopSection.vue?vue&type=script&lang=js"
import style0 from "./ArticlePhotoTopSection.vue?vue&type=style&index=0&id=cf6430b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf6430b6",
  null
  
)

export default component.exports