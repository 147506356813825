import { Page } from '~/cms/front/core/pages/Page'
import component from './HireRoomsPage.vue'

class HireRoomsPage extends Page {
	static alias = 'hire-rooms'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('hire-rooms')
	}

	component = component
}

export { HireRoomsPage }
