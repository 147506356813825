<template>
    <section class="section-wrapper" v-background="background">
        <grid-template cols="3" className="offers-grid">
            <template #title>
                <base-heading tag="h2" :heading="`${prefix}.section_title`" white :subheading="`${prefix}.section_subtitle`" class="section-header"/>
            </template>
            <base-offer v-for="(item, index) in value.offers" :key="index" :offer="item" :elemIndex="index + 1" v-animate="`opacity${index}`"/>
        </grid-template>
    </section>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'
import BaseOffer from '../../components/molecules/BaseOffer/BaseOffer.vue'

export default {
	components: {
		GridTemplate,
		BaseHeading,
		BaseOffer
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		background () {
			const path = this.value.section_background.path

			return {
				src: path,
				breakpoints: {
					base: { width: 400, height: 650 },
					lg: { width: 1920, height: 1500 }
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	background-repeat: no-repeat;
	background-size: cover;
    ::v-deep .section-header {
        margin-bottom: 4rem;
        @include media-breakpoint-up(xl) {
            margin-bottom: 6rem;
        }
    }
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $primary;
        opacity: .8;
        z-index: -1;
    }
    ::v-deep .offers-grid {
        @include media-breakpoint-landscape {
            grid-template-columns: repeat(1, 1fr);
        }
        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(1, 1fr);
        }
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
</style>
