import { rextServiceExtend } from './services/RextService'
import { Translator } from './core/Translator'

class TranslationsModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	async beforeInitReactive (app) {
		app.translator = new Translator()
		app.translator.setApp(app)
	}
}
export { TranslationsModule }
