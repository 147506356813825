<template>
    <section class="section-wrapper service-wrapper">
        <grid-template cols="4">
            <template #title>
				<div>
					<base-heading :tag="value.section_tag" :subheading="`${prefix}.section_subtitle`" class="section-header" :html="addStrong" v-if="value.section_subtitle"/>
					<cms-editor-text variant="paragraph" color="black" :prioritizedValue="value.section_description" v-if="value.section_description"/>
				</div>
			</template>
            <base-service v-for="(item, index) in value.services" :key="index" :service="item"/>
        </grid-template>
    </section>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'
import BaseService from '../../components/molecules/BaseService/BaseService.vue'
export default {
	components: {
		GridTemplate,
		BaseHeading,
		BaseService
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		addStrong () {
			return this.value.section_title.split('<<').join('<span class="strong">').split('>>').join('</span>')
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    ::v-deep .section-header {
        margin-bottom: 4rem;
        @include media-breakpoint-up(xl) {
            margin-bottom: 6rem;
        }
    }
}
</style>
