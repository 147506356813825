export function getPasswordRules (Rule, app) {
	return [
		new Rule({
			text: 'errors.min_length',
			validation: value => value.length > 7
		}, app),
		new Rule({
			text: 'errors.uppercase_letter_or_number',
			validation: value => !!value.match(/(?=.*[1-9A-Z])/)
		}, app)
	]
}
