<template>
		<section class="section-wrapper photo-section" :class="value.background ? 'section-wrapper--white': null" :id="value.section_title.toLowerCase() === 'stretching' ? value.section_title.toLowerCase() : null">
				<grid-template :cols="value.image ? '2' : '1'" :className="value.reverse ? 'reverse' : ''">
                    <template #title>
                        <base-heading :tag="value.header_tag" :html="addStrong" :subheading="value.section_subtitle ? `${prefix}.section_subtitle` : null" class="section-header"/>
                    </template>
					<div class="text-content">
						<cms-multi-section class="section-elements" :value="`${prefix}.section_content`" />
					</div>
					<image-wrapper class="img-wrapper" v-if="value.image">
						<cms-image :value="`${prefix}.image`"
							lazy
							:base="{width: 400}"
							:md="{width: 690}"
							:lg="{width: 400}"
							:xl="isForNewPeople ? {width: 690, height: 300, position: 'top'} :  {width: 690}"
						/>
					</image-wrapper>
				</grid-template>
		</section>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'

export default {
	components: {
		CmsMultiSection,
		ImageWrapper,
		GridTemplate,
		BaseHeading
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		addStrong () {
			return this.value.section_title.split('<<').join('<span class="strong">').split('>>').join('</span>')
		}
	},
	methods: {
		isForNewPeople () {
			if (this.$router.history.current.path === '/nauka-tanca/dla-nowych-osob') return true
			else return null
		}
	},
	mounted () {
		this.isForNewPeople()
	}
}
</script>
<style lang="scss" scoped>
::v-deep .section-header {
    margin-bottom: 4rem;
    @include media-breakpoint-up(xl) {
        margin-bottom: 6rem;
    }
}
::v-deep .img-wrapper {

	.base-image {
		height: 100%;
		width: 70%;
		object-fit: cover;
		border-radius: $img-radius;
		margin: 0 auto;
		display: block;

		@include media-breakpoint-up(lg) {
			width: 100%;
			margin: initial;
			display: inline;
		}
	}
}

::v-deep .grid-wrapper {
	grid-template-columns: auto;
	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(2, 1fr);
	}

	&.cols-1 {
		grid-template-columns: auto;
		gap: 0;
	}
}

</style>
