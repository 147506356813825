import { CookiesBack } from './CookiesBack'
import { CookiesFront } from './CookiesFront'

class Cookies {
	constructor (context) {
		if (context.isServer) {
			this.strategy = new CookiesBack(context)
		} else {
			this.strategy = new CookiesFront()
		}
	}

	get (name) {
		return this.strategy.get(name)
	}

	remove (name) {
		return this.strategy.remove(name)
	}

	set (name, value, options) {
		return this.strategy.set(name, value, options)
	}
}

export { Cookies }
