import component from '~/blog/front/components/ArticleList/ArticleList.vue'
import { AbstractArticleListPage } from '../../AbstractArticleListPage/AbstractArticleListPage'
import { Article } from '~/blog/front/core/entities/Article'

class ArticleListPage extends AbstractArticleListPage {
	static alias = 'article-list'
	component = component

	loading = true

	async afterRedirect () {
		await super.afterRedirect()
		await this.fetchArticles()
	}

	async fetchArticles () {
		const fetchOptions = {
			app: this.app,
			take: this.showArticleInital,
			skip: this.currentPage * this.showArticleInital - this.showArticleInital,
			where: {
				language: this.app.language
			}
		}
		const { numberOfAllArticles } = await Article.fetch(fetchOptions)
		this.numberOfPages = Math.ceil(numberOfAllArticles / this.showArticleInital)
	}
}

export { ArticleListPage }
