export const paddingMixin = {
	computed: {

		padding () {
			const padding = {}

			checkAndSetPaddingValues(this.value.padding_top, 'top', padding)
			checkAndSetPaddingValues(this.value.padding_bottom, 'bottom', padding)

			return padding
		}
	}
}

const checkAndSetPaddingValues = (value, direction, padding) => {
	switch (value) {
	case 'padding-outer':
		padding[`padding-${direction}-outer`] = true
		break
	case 'padding-inner':
		padding[`padding-${direction}-inner`] = true
		break
	}
}
