/* eslint-disable import/no-anonymous-default-export */
import Vue from 'vue'
import App from './App.vue'
import { Application } from './core/application/Application'
import { useModules } from './modules'
import createRouter from './router'
import VueMeta from 'vue-meta'
import { backgroundDirective, backgroundMapDirective } from './directives/background'
import { animatedScrollObserver } from './directives/scrollanimation'
import { dateFilter } from './filters/date'
import VueNumber from 'vue-number-animation'

Vue.config.productionTip = false

export default () => {
	const app = new Application()

	app._Vue = Vue

	Vue.prototype.$app = app
	if (process.client) window.app = app

	useModules(app, Vue)

	app.init()

	const router = createRouter()
	const vue = new Vue({
		router,
		render: h => h(App)
	})

	app.setRouter(router)
	vue.then(vue => app.setVue(vue))

	Vue.use(VueMeta)
	Vue.use(VueNumber)
	Vue.directive('background', backgroundDirective())
	Vue.directive('map-background', backgroundMapDirective())
	Vue.directive('animate', animatedScrollObserver())

	Vue.filter('date', dateFilter)

	return vue
}
