import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getEvent (alias) {
			const { data, status } = await this.http.get(`/page/${alias}/event`)
			if (status !== 200) return { error: true }
			return data
		}

		async getAllEvents () {
			const query = getQuery({
				relations: ['page'],
				where: {
					page: {
						status: 'active'
					}
				},
				take: 9999
			})

			const { data, status } = await this.http.get(`/event${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getEvents (queryObject) {
			const query = getQuery(queryObject)

			const { data, status } = await this.http.get(`/upcoming-events${query}`)
			if (status !== 200) return { error: true }
			return {
				data,
				getRemainingEventsHandler: data.next ? await this.getRemainingEvents.bind(this, data.next) : undefined
			}
		}

		async getEventsLength ({ where }) {
			const query = getQuery({
				where
			})
			const { data, status } = await this.http.get(`/event/count${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getRemainingEvents (...params) {
			const { data, status } = await this.http.get(params[0])
			if (status !== 200) return { error: true }

			const returnValue = {
				data
			}
			if (data.next) returnValue.getRemainingEventsHandler = await this.getRemainingEvents.bind(this, data.next)
			return returnValue
		}

		async getPastEvents (tags) {
			const query = getQuery({
				where: {
					tags: tags
				}
			})

			const { data, status } = await this.http.get(`/past-events${query}`)

			if (status !== 200) return { error: true }

			return data
		}

		async getEventsTags () {
			const { data, status } = await this.http.get('/event-tag')

			if (status !== 200) return { error: true }

			return data
		}
	}
}
