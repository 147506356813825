<template>
		<div class="wrapper">
			<base-heading :tag="value.header_tag" :subheading="value.heading_subtitle ? `${prefix}.heading_subtitle` : null" :html="addStrong" />
			<cms-multi-section :value="`${prefix}.section_content`"/>
		</div>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'

export default {
	components: { BaseHeading, CmsMultiSection },
	computed: {
		addStrong () {
			return this.value.heading_title.split('<<').join('<span class="strong">').split('>>').join('</span>')
		}
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
	.wrapper{
		display: flex;
		flex-direction: column;
		margin-bottom: 4rem;

		@include media-breakpoint-up(md) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 2rem 0;
		}

		@include media-breakpoint-up(xl) {
			margin-bottom: 6rem;
		}

		@include media-breakpoint-up(xxl) {
			align-items: center;
		}
	}
</style>
