<template>
	<div class="wrapper">
		<div class="tab-buttons">
			<button v-for="(item, index) in value" :key="item.id" class="tab-buttons__button" @click="chooseTab(index)" ref="tab_button"
				:class="{active: index === activeTab}">
				{{item.item_title}}
			</button>
		</div>
		<div v-if="!hasParagraph" :id="addID">
			<cms-editor-text
				class="tab-content text"
				ref="tab_content"
				v-for="(item, index) in value"
				:key="item.id"
				:class="{active: index === activeTab}"
				:prioritizedValue="item.item_text"
				:props="{variant: 'paragraph', tag: 'div'}"
			/>
		</div>
		<div v-else>
			<cms-editor-text
				class="tab-content text"
				ref="tab_content"
				v-for="(item, index) in value"
				:key="item.id"
				:class="{active: index === activeTab}"
				:prioritizedValue="item.item_text"
				:props="{variant: 'paragraph', tag: 'div', color: 'black'}"
			/>
		</div>
	</div>

</template>
<script>
import { wait } from 'utils/mask/index'
export default {
	props: {
		value: Array,
		hasParagraph: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({
		activeTab: 0
	}),
	methods: {
		chooseTab (index) {
			this.activeTab = index
			wait(100).then((res) => {
				scrollTo({
					top: this.$refs.tab_content[index].$el.getBoundingClientRect().top + window.scrollY - (this.isMobile() ? 56 : 70),
					left: 0,
					behavior: 'smooth'
				})
			})
		},
		isMobile () {
			if (process.client) {
				return window.innerWidth <= 992
			} else {
				return false
			}
		},
		isActive (refs) {
			const id = this.$app.vue._route.hash.replace('#', '')
			const findIndex = refs.tab_button.findIndex(btn => btn.innerText.toLowerCase().includes(`${id}`))
			if (findIndex !== -1 && findIndex !== 0) {
				this.activeTab = findIndex
				wait(100).then((res) => {
					scrollTo({
						top: this.$refs.tab_content[findIndex].$el.getBoundingClientRect().top + window.scrollY - (this.isMobile() ? 56 : 150),
						left: 0,
						behavior: 'smooth'
					})
				})
			}
		}
	},
	computed: {
		addID () {
			const id = this.$app.vue._route.hash.replace('#', '')
			if (id) return id
			return null
		}
	},
	mounted () {
		this.isActive(this.$refs)
	}
}

</script>
<style lang="scss" scoped>
	.tab-buttons {
		display: grid;
		grid-template-columns: auto;
		gap: 0rem 3rem;

		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(3, 1fr);
		}

		&__button {
			font-family: $primary-font;
			background-color: transparent;
			border: none;
			cursor: pointer;
			flex-grow: 1;
			font-size: 1.8rem;
			padding: 2.4rem 0rem;
			line-height: 2.2rem;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			border-bottom: .2rem solid #eaeaea;
			@include transition;
			color: $black;

			@include media-breakpoint-up(sm) {
				padding: 2.4rem 2rem;
			}

			&.active {
				border-color: $primary;
			}

			&:hover {
				@include media-breakpoint-up(xl) {
					border-color: $primary;
				}
			}
		}
	}

	.tab-content {
		display: none;
		padding: 3rem 0rem;

		@include media-breakpoint-up(sm) {
			padding: 3rem 2rem;
		}

		p {
			@include p_styles()
		}

		::v-deep p {
			margin-bottom: 2rem;
			text-transform: none;

			a {
				@include link_styles()
			}
		}

		::v-deep a {
				@include link_styles()
		}

		::v-deep {
			h2 {
				@include h2_styles();
				margin-bottom: 3rem;

				& + h3 {
					margin-top: 0
				}
			}

			h3 {
				@include h3_styles();
				@include media-breakpoint-up(xl) {
					margin-top: 6rem;
				}
			}
		}

		& > h3 {
			margin-top: 0;
		}

		&.active {
			display: block;
		}
	}
	::v-deep .ql-editor > h3:nth-of-type(1){
		margin-top: 0;
	}

</style>
