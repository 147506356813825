<template>
	<div class="field-wrapper"
		:info="input.info"
	>
		<div class="field-label">
			<base-font
				variant="label"
				color="white"
				:for="input.name"
				@click.stop="$emit('click')"
			>{{input.label}}:</base-font>
		</div>
		<div class="field-inner-wrapper">
			<base-input
				class="input"
				:input="input"
				:type="input.isHidden ? 'password' : 'text'"
			/>
			<base-svg
				class="field-icon"
				name="visibility"
				@touchstart="input.show()"
				@touchend="input.hide()"
				@mousedown="input.show()"
				@mouseleave="input.hide()"
				@mouseup="input.hide()"
				:class="{
					valid: input.isValidAndTouched,
					clicked: !input.isHidden
				}"
			/>
		</div>
		<base-error :error="input.error" />
		<div
			class="password-validator"
			:class="{valid: input.isValidAndTouched, invalid: input.isInvalidAndTouched}"
			v-if="input.rules.length"
		>
			<base-rule
				v-for="(rule, key) in input.rules"
				:key="key"
				:option="rule"
			/>
		</div>
	</div>
</template>

<script>
import mixin from '../utils/input.mixin'
import BaseError from '@f/components/BaseError'
import BaseRule from '../../../atoms/BaseRule'

export default {
	mixins: [mixin],
	components: {
		BaseError,
		BaseRule
	}
}
</script>
<style lang="scss" scoped src="../utils/fields.scss" />
