<template>
	<div class="wrapper">
		<breadrcumb />
		<events-calendar />
		<cms-multi-section value="content" />
	</div>
</template>

<script>
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection'
import EventsCalendar from '~/events/front/components/EventsCalendar'

export default {
	components: {
		CmsMultiSection,
		Breadrcumb,
		EventsCalendar
	},
	computed: {
		translate () {
			return this.$app.translator.get('events')
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep .grid-wrapper {
	@include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
    }
	@include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
