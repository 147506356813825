<template>
	<div class="section-wrapper events-section">
		<base-container>
			<div class="heading-wrapper">
					<div class="wrapper">
						<cms-text :additionalHtml="addStrong('top <<event>>')" :props="{variant: 'heading', tag: 'h1', color: null }" class="h2"/>
						<cms-text additionalHtml="sprawdź co się szykuje!" :props="{variant: 'subheading', tag: 'span', color: null }"/>
					</div>
					<base-button @click="toggleIsCalendarActive" type="secondary" class="calendar-btn">
						<base-svg :name="isCalendarActive ? 'list' : 'calendar'" class="calendar-icon"/>
						<span v-if="isCalendarActive"> Powrót do widoku listy </span>
						<span v-else> Zobacz widok kalendarza</span>
					</base-button>

			</div>
		</base-container>
		<transition name="fade" mode="out-in">
			<div class="calendar__inner" v-if="isCalendarActive && calendar" key="1">
				<grid-template cols="2" :className="`grid`">
					<div class="calendar__calendar">
						<div class="calendar__date">
							<div @click="calendar.onArrowClick(-1)" class="calendar__arrow">
								<base-svg name="calendar_left_arrow"/>
							</div>
							<h3 class="calendar__date-text">
								{{ calendar.getWeekdayAndMonth(calendar.selectedDate).month }}
								{{ calendar.selectedDate.getFullYear() }}
							</h3>
							<div @click="calendar.onArrowClick(1)" class="calendar__arrow">
								<base-svg name="calendar_right_arrow"/>
							</div>
						</div>
						<div class="calendar__calendar-inner">
							<event-date :activeDate="activeDate" v-for="(date, i) in calendar.dates" :key="i + 'row' + date"
										:calendar="calendar" :date="date.date" @setActiveDate="setActiveDate"
										:eventsHas="date.events.length > 0"
										:disabled="calendar.selectedDate.getMonth() !== date.date.getMonth()"
							/>
						</div>
					</div>
					<div class="calendar__heading">
						<base-heading v-if="!activeDate" tag="h2" html="Wybierz datę" :subheading="null" class="section-header"/>
						<base-heading v-else-if="calendar.AreEventsInDay() && activeDate" tag="h2" html="brak eventów" :subheading="null" class="section-header"/>
						<div v-else class="calendar-events">
							<template v-for="(event, i) in calendar.eventsForSelectedDate">
								<div class="calendar__dates" :key="event + i + 'dates'" v-if="event.data">
									<event-date-card :key="i + event.createdAt + 'days'" :value="event" v-if="calendar.areDatesEqual(new Date(event.data.startDate), activeDate)"/>
								</div>
							</template>
						</div>
					</div>
				</grid-template>

			</div>
			<base-container v-else>
				<div class="calendar-events">
					<div class="calendar__dates">
						<event-date-preview :value="getHighlightedEvents" key="2"/>
					</div>
				</div>
			</base-container>
		</transition>
	</div>
</template>

<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading'
import EventDate from '~/events/front/components/EventDate'
import EventDateCard from '~/events/front/components/EventDateCard'
import CmsText from '~/cms/front/components/CmsText/CmsText.vue'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import { addStrong } from 'utils/addStrong.js'
import EventDatePreview from '~/events/front/components/EventDatePreview'

export default {
	components: { EventDatePreview, EventDateCard, GridTemplate, BaseHeading, EventDate, CmsText, BaseContainer },
	props: {
		prefix: String
	},
	computed: {
		events () {
			return this.page.events
		},
		calendar () {
			return this.page.calendar
		},
		getHighlightedEvents () {
			return this.$app.page.events.highlighted
		}

	},
	data () {
		return {
			value: this.$app.page.value,
			isCalendarActive: false,
			activeDate: null
		}
	},
	mixins: [
		pageMixin
	],
	methods: {
		addStrong,
		toggleIsCalendarActive () {
			this.isCalendarActive = !this.isCalendarActive
		},
		setActiveDate ($event) {
			this.calendar.setActiveDate($event)
			this.activeDate = $event
			if ($event.getMonth() < this.calendar.selectedDate.getMonth() && $event.getFullYear() > this.calendar.selectedDate.getFullYear()) {
				this.calendar.onArrowClick(1)
			} else if ($event.getMonth() > this.calendar.selectedDate.getMonth() && $event.getFullYear() < this.calendar.selectedDate.getFullYear()) {
				this.calendar.onArrowClick(-1)
			} else if ($event.getMonth() > this.calendar.selectedDate.getMonth()) {
				this.calendar.onArrowClick(1)
			} else if ($event.getMonth() < this.calendar.selectedDate.getMonth()) {
				this.calendar.onArrowClick(-1)
			}
		}
	}

}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  @include transition
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}
::v-deep .heading-wrapper{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 4rem;

		@include media-breakpoint-up(md) {
			display: flex;
			flex-direction: row;
			gap: 2rem 0;
		}

		@include media-breakpoint-up(xl) {
			margin-bottom: 6rem;
		}

		@include media-breakpoint-up(xxl) {
			align-items: center;
		}

		.calendar-icon {
			max-width: 2.2rem;
			min-width: 2.2rem;

			g > g path {
				fill: $black;
			}
		}

		.content-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			span {
				white-space: nowrap;
				display: none;

				@include media-breakpoint-up(xl) {
					display: inline;
				}
			}
		}

		::v-deep .calendar-btn {
			cursor: pointer;
			display: flex;
			align-items: center;
		}
}

::v-deep .calendar__calendar-inner {
	gap: 2rem 1rem;
	display: grid;
	grid-template-columns: repeat(7, 1fr);

	@include media-breakpoint-up(sm) {
		gap: 2.3rem 2rem;
	}
}

::v-deep .calendar__inner {
	margin-top: 20px;

	.grid-wrapper {
		display: block;
		gap: 0;

		@include media-breakpoint-up(sm) {
			display: grid;
			grid-template-columns: auto;
		}

		@include media-breakpoint-up(lg) {
			gap: 3rem;
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: 45rem auto;
		}
	}
}

::v-deep .calendar__date {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
	text-transform: uppercase;

	svg {
		cursor: pointer;
	}

	&-text {
		color: $primary;
		font-size: 1.9rem;
		letter-spacing: 0.1em;
		font-weight: 600;
		line-height: 2.3rem;
	}
}

::v-deep .calendar__arrow{
	width: 30px;
	height: 30px;
}

::v-deep .calendar__calendar {
	border: 1px solid $gray-700;
	background-color: $white;
	padding: 2rem;
	border-radius: .8rem;
	width: fit-content;
	height: fit-content;
	margin: 0 auto 3rem auto;

	@include media-breakpoint-up(sm) {
		padding: 4rem;
	}

	@include media-breakpoint-up(xl) {
		margin: 0;
		padding: 6rem 6.6rem;
	}

}
::v-deep .calendar__heading {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	.subheading {
		display: block;
		margin: 0 auto;
	}

	.section-header {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}

::v-deep .calendar-events {
	display: grid;
	grid-template-columns: auto;
	max-width: 100%;
	gap: 3rem;
}
::v-deep .calendar__article {
	@include media-breakpoint-up(xl) {
		display: grid;
		grid-template-columns: 330px auto;
		grid-template-rows: min-content;
		gap: 0 3rem;
	}
	@include media-breakpoint-up(xxl) {
		grid-template-columns: 330px auto;
		align-items: center;
	}
}

@mixin active_item_styles {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 3rem;
		height: 3rem;
		background-color: $primary;
		border-radius: 100%;
		z-index: 0;

		@include media-breakpoint-up(lg) {
			width: 4rem;
			height: 4rem;
		}
	}

	span {
		z-index: 1;
	}
}

::v-deep .calendar__item {
	justify-self: center;
	width: 29px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid transparent;
	cursor: pointer;
	@include transition;

	&.active {
		border-color: $primary;
	}

	&.exists {
		@include active_item_styles();

		&::after {
			background-color: transparent;
			border: 1px solid $primary;
		}
	}

	&.disabled {
		color: $gray-300;
	}

	&.current {
		color: $white;
		@include active_item_styles();
	}
}
</style>
