<template>
	<base-container>
		<div class="wrapper" :class="{'no-video': !value.solo_video, padding: value.padding === 'true'}">

			<div class="accordion-container">
				<basic-accordion v-if="value.elements.length">
					<accordion-item v-for="(item, i) in value.elements" :key="i" type="primary">
						<template slot="accordion-trigger">
							<base-font variant="accordion-title" weight="bold" color="white" class="accordion-button__title">{{ item.elements_title }}
							</base-font>
						</template>
						<template slot="accordion-content">
							<cms-editor-text variant="paragraph" color="black" class="accordion-content__description" :prioritizedValue="item.elements_description"/>
						</template>
					</accordion-item>
				</basic-accordion>
				<cms-editor-text variant="div" class="additional-text" :prioritizedValue="addStrongEditor(value.description)" v-if="value.description"/>
				<cms-multi-section :value="`${prefix}.section_content`"/>

			</div>
			<div class="video" v-html="value.solo_video" v-if="value.solo_video"/>

		</div>
	</base-container>

</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import AccordionItem from '~/website/front/components/molecules/accordion/AccordionItem/AccordionItem.vue'
import BasicAccordion from '~/website/front/components/molecules/accordion/BasicAccordion/BasicAccordion.vue'
import { addStrongEditor } from 'utils/addStrong.js'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'

export default {
	components: { BaseContainer, AccordionItem, BasicAccordion, CmsMultiSection },
	methods: { addStrongEditor },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.accordion-content__description {
	@include p_styles();

	.ql-editor {
		margin-bottom: 0;
	}
}

::v-deep .section-wrapper {
	padding-top: 0;
}

::v-deep .video {
	height: 300px;

	@include media-breakpoint-up(md) {
		width: 70%;
		margin: 0 auto;
	}

	@include media-breakpoint-up(lg) {
		height: 400px;
		width: initial;
		margin: initial;
	}

	iframe {
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 1.3rem;
	}
}

.wrapper {
	display: grid;
	grid-auto-columns: auto;
	gap: 2rem 3rem;

	&.padding {
		padding-bottom: 6rem;

		@include media-breakpoint-up(xl) {
			padding-bottom: 12rem;
		}
	}

	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include media-breakpoint-up(xl) {
		gap: 2rem 10rem;
	}

	::v-deep .buttons-wrapper {
		display: block;
		margin-top: 1rem;
	}

	::v-deep .additional-text {
		p {
			@include p_styles();

			em {
				font-size: smaller;
			}
		}

		a {
			@include link_styles()
		}

		.strong {
			color: $primary;
			font-weight: bold;
		}
	}

	.accordion-container {
		padding: 1rem 0;
	}
}

.no-video {
	display: block;

	.accordion-container {
		display: flex;
		flex-direction: column;
		gap: 2rem 3rem;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}

		.accordion {
			@include media-breakpoint-up(lg) {
				width: 100%;
			}
		}

		.buttons-wrapper {
			margin-top: 1rem;
		}
	}
}

.additional-text {
	padding-top: 2rem;

	@include media-breakpoint-up(xl) {
		padding-top: 0;
	}
}
</style>
