<template>
	<div class="skeleton-wrapper">
		<slot/>
		<span v-show="isVisible" class="skeleton-loader"></span>
	</div>
</template>
<script>
export default {
	props: {
		isVisible: Boolean
	}
}
</script>
<style lang="scss" scoped>
.skeleton-wrapper {
	position: relative;
}
.skeleton-loader {
	position: absolute;
	display: block;
	top: 0%;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 3px;
    background-color: $gray-400;
	animation: pulse-bg 1s infinite alternate;
}
@keyframes pulse-bg {
	0% { background-color: $gray-100; }
	50% { background-color: $gray-500; }
	100% { background-color: $gray-100; }
}
</style>
