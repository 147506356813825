<template>
	<section class="text-content" :class="setMarginClasses">
		<base-container v-if="value.isContainer">
			<div class="text-content-inner">
				<cms-text
					class="section-title"
					v-if="value.section_title"
					:value="`${prefix}.section_title`"
					:props="{variant: 'h2', tag: value.tag }"
					:style="{'text-align': setAlignment}"
				/>
				<cms-editor-text :prioritizedValue="value.section_description" :props="{variant: 'paragraph'}" v-if="value.section_description" class="paragraph"/>
			</div>
		</base-container>
		<div class="text-content-inner" v-else>
			<cms-text
				class="section-title"
				v-if="value.section_title"
				:value="`${prefix}.section_title`"
				:props="{variant: 'h2', tag: value.tag }"
				:style="{'text-align': setAlignment}"
			/>
			<cms-editor-text :prioritizedValue="value.section_description" :props="{variant: 'paragraph'}" v-if="value.section_description" class="paragraph"/>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		setAlignment () {
			if (this.value.alignment === 'right') {
				return 'flex-end'
			} else if (this.value.alignment === 'left') {
				return 'flex-start'
			} else if (this.value.alignment === 'center') {
				return 'center'
			} else {
				return 'center'
			}
		},

		setMarginClasses () {
			if (!this.value.padding) return ''

			const classes = {}
			this.value.padding.forEach(item => { classes[`margin-${item}`] = true })
			return classes
		}
	},
	mounted () {
		console.log(this.value)
	}
}
</script>

<style scoped lang="scss">
.text-content {

	&.margin-top {
		margin-top: 4rem;

		@include media-breakpoint-up(xl) {
			margin-top: 6rem;
		}
	}

	&.margin-bottom {
		margin-bottom: 4rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 6rem;
		}
	}

	&.margin-middle-top {
		margin-top: 3rem;
	}

	&.margin-middle-bottom {
		margin-bottom: 3rem;
	}

	.text-content-inner{

	}

	:deep(p) {
		@include p_styles();
	}

	:deep(a) {
		@include link_styles();
	}

	:deep(h3) {
		@include h3_styles();
	}
}
</style>
