<template>
	<basic-accordion v-if="value.tabs.length">
		<accordion-item v-for="(item, i) in value.tabs" :key="i" type="primary">
			<template slot="accordion-trigger">
				<base-font variant="accordion-title" weight="bold" color="white"
					class="accordion-button__title">{{item.item_title}}</base-font>
			</template>
			<template slot="accordion-content">
				<div variant="paragraph" color="black" class="accordion-content__description"
					v-html="item.item_text" />
				<div @click="toggle" class="screen">
					<cms-image
						lazy
						:src="item.gallery_image"
						:base="{width: 360, heigth: 95}"
						:lg="{width: 330, height: 200}"
						:xl="{width: 330, height: 200}"
						v-if="item.gallery_image"
					/>
				</div>
				<base-button :url="item.item_link" type="primary" v-if="item.item_link_title" class="btn">{{ item.item_link_title }}</base-button>
				<gallery-modal v-if="item.gallery_image && isModalOpen"  :images="[item]" :activeImageIndex="activeImageIndex" :handleModalWrapperClick="handleModalWrapperClick">
					<button-close @close="toggle" />
				</gallery-modal>
			</template>
		</accordion-item>
	</basic-accordion>
</template>

<script>

import AccordionItem from '~/website/front/components/molecules/accordion/AccordionItem/AccordionItem.vue'
import BasicAccordion from '~/website/front/components/molecules/accordion/BasicAccordion/BasicAccordion.vue'
import GalleryModal from '../../components/organisms/GalleryModal/GalleryModal.vue'
import ButtonClose from '~/website/front/components/molecules/buttons/ButtonClose/ButtonClose.vue'

export default {
	components: { AccordionItem, BasicAccordion, GalleryModal, ButtonClose },
	props: {
		prefix: String,
		value: Object
	},
	data: () => ({
		activeImageIndex: 0,
		isModalOpen: false
	}),
	methods: {
		toggle () {
			this.activeImageIndex = 0
			this.isModalOpen = !this.isModalOpen
			if (this.isModalOpen) {
				this.$root.$refs.menu.classList.add('modal-open')
				this.$root.$refs.accountBtn.$el.style.display = 'none'
			} else {
				this.$root.$refs.menu.classList.remove('modal-open')
				this.$root.$refs.accountBtn.$el.style.display = 'flex'
			}
		},
		handleModalWrapperClick () {
			this.$root.$refs.menu.classList.remove('modal-open')
			this.isModalOpen = false
		}
	}

}
</script>

<style lang="scss" scoped>
::v-deep .accordion {
	display: grid;
	grid-template-columns: auto;
	&-wrapper {
		max-width: initial !important;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

::v-deep .screen {
	.base-image {
		max-width: 100%;
		margin-top: 2rem;
		cursor: pointer;
	}
}
::v-deep .btn {
	margin-top: 2rem;
}
</style>
