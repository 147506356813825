<template>
    <base-link class="instructor-wrapper" :class="{disabled: !item.instructor_link}" :to="item.instructor_link" :params="{alias: 'instructors'}">
            <cms-image
            class="instructor-image"
			:src="item.instructor_image"
			:base="{width: 200, height: 200}"
			:sm="{width: 300, height: 300}"
            :md="{width: 200, height: 250, position: 'center'}"
            :lg="{width: 300, height: 250, position: 'center'}"
			:xl="{width: 330, height: 310}"
        />
        <div class="instructor-data">
            <base-font class="notranslate" tag="h2" variant="paragraph" size="instructor-header" uppercase weight="semibold" marginReset>{{item.instructor_name}}</base-font>
            <base-font tag="span" variant="instructor" class="subtitle">{{item.instructor_speciality}}</base-font>
        </div>
    </base-link>
</template>

<script>

export default {
	props: {
		item: Object
	}
}
</script>
<style lang="scss" scoped>
.instructor-wrapper {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
	text-decoration: none;
    ::v-deep .instructor-image .base-image {
        max-width: 100%;
        display: flex;
		border-radius: .8rem;
		object-fit: cover;
		object-position: top;
        @include media-breakpoint-up(md) {
            max-height: 200px;
        }
        @include media-breakpoint-up(xl) {
            max-height: initial;
        }
    }

	&.disabled {
		cursor: initial;
	}
    .instructor-data {
        width: 90%;
        background: $white;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 8px 8px 0 0;
        position: absolute;
        bottom: 0;
        transform: translate(-50%, 0);
        left: 50%;
        padding: 1rem 1rem;
        @include transition;
        @include media-breakpoint-up(xl) {
            padding: 1.6rem 0;
            bottom: -10px;
        }

		::v-deep .subtitle {
			font-weight: 400;
		}
    }
    &:hover {
        @include media-breakpoint-up(xl) {
            .instructor-data {
                transform: translate(-50%, -10px)
            }
        }
    }
}
</style>
