<template xmlns:style="http://www.w3.org/1999/xhtml">
	<div>
		<breadrcumb />
		<section class="section-wrapper faq">
			<base-container>
				<cms-multi-section value="content"/>
			</base-container>
		</section>
	</div>
</template>
<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'

export default {
	components: {
		CmsMultiSection,
		Breadrcumb
	}
}
</script>
