<template>
	<div class="info-box" :class="stateChecker">
		<base-svg :name="stateChecker" />
		<base-font class="info-text" marginReset tag="span" uppercase size="xs">
			<slot/>
		</base-font>
	</div>
</template>
<script>
export default {
	props: {
		status: String
	},
	computed: {
		stateChecker () {
			return this.status === 'error' ? 'error' : 'correct'
		}
	}
}
</script>
<style lang="scss" scoped>
.info-box {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0 0 2rem 0;

	::v-deep .info-text {
		padding: 1rem;
		font-weight: 500;
		font-size: 1.4rem;
        letter-spacing: 0.5px;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 2px;
		}
	}
	.svg-icon {
		margin: 0 2rem;
		width: 13px;
		height: 13px;
	}
}
.info-box.error {
	border: 2px solid $red-300;
	background: rgba($red-300, 0.3);

	::v-deep .info-text {
		color: $red-300;

		&::before {
			background: $red-300;
		}
	}
	.svg-icon {
		::v-deep path{
			stroke: $red-300;
		}
	}
}
.info-box.correct {
	border: 2px solid $green-300;
	background: rgba($green-300, 0.3);

	::v-deep .info-text {
		color: $green-300;

		&::before {
			background: $green-300;
		}
	}
	.svg-icon {
		fill: $green-300;
		::v-deep path {
			stroke: $green-300;
		}
	}
}
</style>
