<template>
  <grid-template cols="3" :container="false" className="custom-wrapper">
    <div class="advantage" v-for="(item, index) in advantages" :key="index">
      <div class="advantage__icon-wrapper">
        <base-svg
          :name="item.why_us_icon"
          class="advantage__icon"
          :class="item.why_us_icon"
        />
      </div>
	<client-only>
		<div class="advantage__content">
			<base-font
			variant="paragraph"
			color="primary"
			class="advantage__content-title"
			>{{ item.why_us_title }}</base-font
			>
			<base-font
			variant="paragraph"
			color="black"
			class="advantage__content-description"
			:html="removeParagraph(item.why_us_description)"
			/>
		</div>
	</client-only>
    </div>
  </grid-template>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import BaseSvg from '../BaseSvg/BaseSvg.vue'
import { removeParagraph } from 'utils/removeParagraph.js'
import ClientOnly from 'vue-client-only'

export default {
	methods: { removeParagraph },
	components: { GridTemplate, BaseFont, BaseSvg, ClientOnly },
	props: {
		advantages: {
			type: Array
		}
	}
}
</script>

<style lang="scss">

#app .custom-wrapper {
	gap: 4rem 2rem;

	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include media-breakpoint-up(xl) {
		gap: 10rem 3rem;
		grid-template-columns: repeat(3, 1fr);
  }
}

#app .advantage {
  display: flex;
  gap: 1rem 2rem;
  @include transition;

  @include media-breakpoint-up(lg) {
    flex-direction: column;
    gap: 5rem 0;
  }

  @include media-breakpoint-up(xl) {
     &:hover {
        transform: translateY(-10px);

		.svg-icon path{
			fill: $primary;
		}
    }
  }

  &__icon {
    max-width: 3rem;
    height: fit-content;

	@include media-breakpoint-up(lg) {
    display: block;
	margin: 0 auto;
  }

    &-wrapper {
    min-width: 5rem;
	margin-top: 0.3rem;

	@include media-breakpoint-up(lg) {
        min-height: 11rem;
		margin-top: 0rem;
      }
    }

    &.business_card {
      min-width: 4.5rem;
      height: 4.5rem;

      @include media-breakpoint-up(lg) {
        min-width: 8.2rem;
        height: 8.2rem;
      }
    }
    &.dancing {
      min-width: 3.1rem;
      height: 5.37rem;

      @include media-breakpoint-up(lg) {
        min-width: 5.2rem;
        height: 9rem;
      }
    }
    &.online {
      min-width: 4.5rem;
      height: 4rem;

      @include media-breakpoint-up(lg) {
        min-width: 9.7rem;
        height: 8.8rem;
      }
    }
    &.footprints {
      min-width: 3.5rem;
      height: 4.4rem;

      @include media-breakpoint-up(lg) {
        min-width: 8rem;
        height: 10.1rem;
      }
    }
    &.choice {
      min-width: 4.5rem;
      height: 4.48rem;

      @include media-breakpoint-up(lg) {
        min-width: 10.14rem;
        height: 10.13rem;
      }
    }
    &.modern_house {
      min-width: 4.5rem;
      height: 3.9rem;

      @include media-breakpoint-up(lg) {
        min-width: 9.6rem;
        height: 7.8rem;
      }
    }
  }

  &__content {
    &-title {
      text-transform: uppercase;
      font-weight: bold;
      line-height: 2rem;
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 2rem;
        text-align: center;
		min-height: 4.8rem;
      }
      @include media-breakpoint-up(xl) {
        min-height: initial;
      }
    }

    &-description {
    line-height: 2rem;
	margin-bottom: 0;
	padding-right: 2.1rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        line-height: 2.4rem;
		padding-right: 0;
      }

	@include media-breakpoint-up(xl) {
        text-align: center;
      }
	p {
		display: inline;

		@include media-breakpoint-up(xxl) {
			display: block;
		}
	}
    }
  }
}
</style>
