<template>
	<section class="slider" v-background="mainBackgroundImage">
		<base-slide
			v-for="(slide, i) in value.slider"
			:index="i"
			:prefix="prefix"
			:value="value.slider[i]"
			:key="i"
			:active="activeIndex"
			:slideTime="slideTime"
		/>
		<div class="slider__logo">
			<cms-image
			:src="value.slide_logo"
			lazy
			:base="{width: 235}"
			:md="{width: 400}"
			:lg="{width: 600}"
			:xl="{width: 550}"
			:xxl="{width: 740}"
			/>
		</div>
		<div class="slider-content">
			<div class="slider__features">
				<div class="slider__features-feature" v-for="(item, key) in value.slider_features" :key="key">
					<span class="slider__features-feature_text">{{ item.slider_features_title }}</span>
				</div>
			</div>
				<div class="slider__buttons">
					<base-link class="slider__buttons-button" v-for="(item, key) in value.slider_links" :key="key" @click="handleScrollDown(item.slider_links_path)" :title="item.slider_links_seo_title">
						<h3 class="slider__buttons-button_title" v-html="addStrong(item.slider_links_title)"/>
						<button :href="item.slider_links_path" class="slider__buttons-button_link">{{ item.slider_links_link }}
							<base-svg name="slider_arrow" class="icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="4" viewBox="0 0 10 4">
									<path id="Polygon_10" data-name="Polygon 10" d="M5,0l5,4H0Z" transform="translate(10 4) rotate(180)" fill="#fff"/>
								</svg>
							</base-svg>
						</button>
					</base-link>
				</div>
		</div>
	</section>
</template>
<script>
import BaseSlide from './components/BaseSlide'
import BaseLink from '~/cms/front/components/BaseLink/BaseLink.vue'
import { addStrong } from 'utils/addStrong.js'

export default {
	data () {
		return {
			activeIndex: 0,
			slideTime: 5000
		}
	},
	props: {
		prefix: String,
		value: Object
	},
	components: {
		BaseSlide,
		BaseLink
	},
	methods: {
		handleScrollDown (element) {
			const section = document.querySelector(element)
			const sectionParametr = section.getBoundingClientRect()
			const getDistance = sectionParametr.top + window.scrollY - (this.isMobile() ? 56 : 90)
			scrollTo({
				top: getDistance,
				left: 0,
				behavior: 'smooth'
			})
		},
		isMobile () {
			if (process.client) {
				return window.innerWidth <= 992
			} else {
				return false
			}
		},
		goTo (index) {
			this.activeIndex = index
			clearInterval(this.timer)
			this.startInterval()
		},
		startInterval () {
			this.timer = setInterval(this.next, this.slideTime)
		},
		next () {
			if (this.activeIndex === this.value.slider.length - 1) this.activeIndex = 0
			else this.activeIndex++
		},
		addStrong
	},
	computed: {
		mainBackgroundImage () {
			const path = this.value.slide_image.path

			return {
				src: path,
				breakpoints: {
					base: { width: 360, height: 650 },
					lg: { width: 1920, height: 920 }
				}
			}
		}
	},
	mounted () {
		this.startInterval()
	}
}
</script>
<style lang="scss" scoped>
.firefox {
	.slider__buttons-button {
		background-color: #2c2b2b;
		opacity: .95;
	}
}

.slider {
	position: relative;
	display: flex;
	flex-direction: column-reverse;
	min-height: calc(100vh - $menuHeightMobile);
	background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 70%;

	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		background-color: rgba(0, 0, 0, 86%);
	}

	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
		opacity: .1;
		position: absolute;
		top: 0;
		left: 0;
		z-index: $level0;
	}

	@include media-breakpoint-up(sm) {
		min-height: calc(65vh - $menuHeightMobile);
	}

	@media (max-width: 576px) and (min-height: 641px){
		min-height: calc(70vh - $menuHeightMobile);
	}

	@media (orientation: portrait) and (min-height: 880px){
		min-height: calc(60vh - $menuHeightMobile);
	}

	@include media-breakpoint-up(md) {
		min-height: calc(70vh - $menuHeightMobile);
	}

	@media (orientation: landscape) and (max-width: 813px) {
		min-height: calc(100vh - $menuHeightMobile);
	}

	@include media-breakpoint-up(lg) {
		overflow: initial;
	}

	@include media-breakpoint-up(xl) {
		min-height: calc(84vh - 90px);
	}

	@media (min-width: 1300px) and (max-height: 700px) and (min-height: 600px){
		min-height: calc(100vh - $menuHeightDesktop);
	}

	@media (min-width: 1400px) and (max-height: 900px) and (min-height: 820px){
		// min-height: calc(67vh - $menuHeightMobile);
	}

	@media (min-width: 1600px) and (min-height: 1000px){
		min-height: calc(75vh - $menuHeightMobile);
	}
    @media (min-width: 1600px) and (min-height: 800px) and (max-height: 992px) {
        min-height: calc(95vh - $menuHeightDesktop);
    }

	::v-deep &__logo {
		position: absolute;
		left: 50%;
		z-index: $level0;
		transform: translateX(-50%) translateY(0rem);
		top: 2rem;

		@media (orientation: landscape) and (max-width: 813px) {
			height: 100%;
		}

		.image-wrapper {

			@include media-breakpoint-up(lg) {
				// height: 100%;
			}

			@media (orientation: landscape) and (max-width: 813px) {
				height: 100%;
			}

		.base-image {
				@include media-breakpoint-up(lg) {
					display: block;
					height: 80%;
				}

				@include media-breakpoint-up(xxl) {
					display: block;
					height: 100%;
				}

				@media (orientation: landscape) and (max-width: 813px) {
					display: block;
					height: 80%;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			top: 8rem;
			transform: translateX(-50%) translateY(-5rem);
		}

		@include media-breakpoint-up(xxl) {
			top: 0rem;
		}
	}

	&-content {
		position: absolute;
		bottom: 10rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: $level2;
		width: fit-content;
		z-index: $level1;
		padding: 0 2rem;
		width: 100%;

		@media (orientation: landscape) and (max-width: 813px) {
			bottom: 2rem;
		}

		@media (orientation: portrait) and (min-height: 641px){
			bottom: 2rem;
		}

		@include media-breakpoint-up(sm) {
			max-width: 540px;
			padding: 0;
		}

		@include media-breakpoint-up(md) {
			max-width: 540px;
			bottom: -2rem;
		}

		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		@include media-breakpoint-up(lg) {
			width: 100%;
			bottom: 0rem;
			transform: translateX(-50%) translateY(3.5rem);
			max-width: 960px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 1140px;
		}

		@include media-breakpoint-up(xxl) {
			max-width: 141rem;
		}
	}

	&__features {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem 0;
		justify-content: center;
		padding: 1.75rem 0;
		border-top: .1rem solid rgba(255,255,255,.3);
		border-bottom: .1rem solid rgba(255,255,255,.3);
		margin-bottom: 2rem;

		@include media-breakpoint-up(sm) {
			flex-wrap: initial;
			gap: initial;
		}

		@media (orientation: landscape) and (max-width: 813px) {
			padding: 1rem 0;
			margin-bottom: 1rem;
		}

		@media (orientation: landscape) and (max-height: 700px) and (min-width: 996px) and (max-width: 1600px){
			display: none;
		}

		@include media-breakpoint-up(lg) {
			padding: 1.85rem 0;
			width: 60%;
			margin: 0 auto;
			margin-bottom: 3rem;
		}

		&-feature {

			&_text {
				color: $white;
				text-transform: uppercase;
				letter-spacing: 0.08em;
				font-size: 1.2rem;
				font-weight: 600;
				line-height: 1.4rem;

					@include media-breakpoint-up(lg) {
						font-size: 1.9rem;
						letter-spacing: 0.1em;
						line-height: 2.3rem;
					}

					&::after {
					content: '•';
					display: inline-block;
					margin: 0 .5rem;
				}

			}

			&:last-of-type &_text::after {
				display: none;
			}
		}
	}

	&__buttons {
		display: flex;
		justify-content: center;
		gap: 0 1rem;

		@include media-breakpoint-up(xl) {
			gap: 0 3rem
		}

		&-button {
			flex-grow: 1;
			border-radius: .5rem;
			padding: 1.8rem 2.1rem 2rem 2.1rem;
			color: $white;
			position: relative;
            cursor: pointer;

			@include media-breakpoint-up(lg) {
				padding: 2.6rem 2.1rem 4.6rem 2.1rem;
			}

			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				background-color: #2c2b2b;
				top: 0;
				left: 0;
				border-radius: .5rem;
				z-index: -1;

				@include media-breakpoint-up(lg) {
					backdrop-filter: blur(2rem);
					-webkit-backdrop-filter: blur(2rem);
					background-color: rgba(0,0,0,0.15);
				}
			}

			::v-deep &_title {
				text-transform: uppercase;
				letter-spacing: 0.08em;
				font-family: $secondary-font;
				line-height: 2.5rem;
				font-size: 2.2rem;
				letter-spacing: 0.08em;
				font-weight: 400;
				text-align: center;
				margin-bottom: 1.2rem;

				.strong {
					color: $primary;
					font-weight: 600;
				}

				@include media-breakpoint-up(lg) {
					font-size: 4.4rem;
					line-height: 6.5rem;
				}
			}

			&_link {
				font-size: 1rem;
				line-height: 1.2rem;
				font-weight: 500;
				letter-spacing: 0.12em;
				text-align: center;
				color: $white;
				display: block;
				background-color: transparent;
				border: none;
				text-transform: uppercase;
				margin: 0 auto;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				@include media-breakpoint-up(lg) {
					font-size: 1.3rem;
					line-height: 1.6rem;
				}

				.icon {
					max-width: 1rem;
					margin-top: .5rem;
				}
			}
		}
	}
}
</style>
