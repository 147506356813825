import { Page } from '~/cms/front/core/pages/Page'
import component from './Article.vue'
import { ArticleData } from '~/seo/front/core/structuredData/Article'

class ArticlePage extends Page {
	static alias = 'article'
	component = component

	constructor ({ app, route, from }) {
		super({ app, route, from })
		this.seo = new ArticleData(app, { page: this })
	}

	async fetch () {
		if (this.from.meta.alias || process.server) {
			await this.fetchData()
			await this.fetchRecommendedArticles()
		} else this.restore()
	}

	async fetchData () {
		const data = await this.app.getService('rext').getArticle(this.alias)
		if (data.error) return this.app.vue.$redirect({ path: '/404' })
		await this.setPageData(data.page)
		delete data.page
		this.language = data.language
		this.value = data
	}

	async fetchRecommendedArticles () {
		const data = await this.app.getService('rext').getRecommendedArticles(this.value.id)
		if (data.errors) return this.app.vue.$redirect({ path: '/404' })
		this.value.recommendedArticles = data
	}

	async afterRedirect () {
		if (process.client) await this.app.getService('rext').createArticleView(this.alias)
	}

	get header () {
		return {
			title: this.app.translator.get('articles').blog_list_title,
			background: this.value.thumbnail
		}
	}

	restore () {
		super.restore()
		this.language = window.__PAGE__.language
	}

	store () {
		const store = super.store()
		store.language = this.language
		return store
	}
}

export { ArticlePage }
