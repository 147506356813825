<template>
	<div :class="[`child child-${index + 1}`, isActive && 'active' , {expanded: expand}, {last: isLast}]" >
		<base-link class="dropdown-group-link" :to="element.link">
			<base-font class="dropdown-group-name" variant="menu-mobile">
				{{element.name}}
			</base-font>
		</base-link>
	</div>
</template>
<script>
export default {
	props: {
		element: Object,
		index: Number,
		expand: Boolean,
		isLast: Boolean
	},
	computed: {
		isActive () {
			return this.element.link.includes(this.$route.meta.alias)
		}
	}
}
</script>
<style lang="scss" scoped>
.child {
        font-weight: 500;
        position: relative;
        opacity: 0;
        transform: translateX(-15px);
        @include transition;

		@include media-breakpoint-up(xl) {
            width: 100%;
			text-align: center;
		}
        &.expanded {
            opacity: 1;
            transform: translateX(0);
        }
        &:not(.last) {
            @include media-breakpoint-up(xl) {
                    &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    bottom: -50%;
                    transform: translateX(-50%);
                    left: 50%;
                    height: 1px;
                    width: 90px;
                    background: $gray-100;

                }
            }
        }
    .dropdown-group {
        &-link {
            text-decoration: none;

            @include media-breakpoint-up(xl) {
                @include transition;
                ::v-deep .dropdown-group-name {
                    @include transition;
                }
                &:hover {
                    opacity: 1;
                    color: $primary !important;
                    ::v-deep .dropdown-group-name {
                        color: $primary !important;
                    }
               }
            }
        }
    }
    &.active .dropdown-group-link ::v-deep .dropdown-group-name{
        color: $primary;
    }

	::v-deep .dropdown-group-link{
		min-width: 100%;
		display: inline-block;
		padding: 0 15px;
		margin: 0 auto;
		transition: .3s all ease-in-out;

		span {
			position: relative;
			display: inline-block;

			&:after {
				content: "";
				position: absolute;
				bottom: -3px;
				left: 0;
				height: 2px;
				width: 98%;
				background-color: $primary;
				opacity: 0;
				transition: .3s all ease-in-out;
			}
		}

		&:hover span:after {
			opacity: 1;
		}
	}

    @include media-breakpoint-up(xl) {
        ::v-deep .dropdown-group-name {
            color: $secondary;
        }
    }
}
</style>
