<template>
	<div>
		<breadrcumb />
		<article class="article section-wrapper" ref="sectionContent">
			<base-container>
				<div class="article-wrapper">
					<div>
						<div class="article-body">
							<base-font  variant="heading">{{article.title}}</base-font>
							<base-font class="article-date" color="secondary">{{article.datePublished | date}}</base-font>
							<cms-multi-section class="article-text" value="content" />
						</div>
					</div>
					<div>
						<base-button class="back-button" to="article-list" type="primary" title="Wszystkie wpisy w Szkole Tańca Wrocław">{{langs.back_button}}</base-button>
					</div>
				</div>
			</base-container>
			<recommended-articles-wrapper />
			<article-readmore :height="articleHeight"/>
		</article>
	</div>
</template>

<script>
import ArticleReadmore from '~/blog/front/components/ArticleReadmore/ArticleReadmore.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import RecommendedArticlesWrapper from '~/blog/front/components/RecommendedArticles/RecommendedArticlesWrapper'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'
import { wait } from 'utils/mask/index'

export default {
	props: {
		prefix: String
	},
	components: {
		RecommendedArticlesWrapper,
		CmsMultiSection,
		ArticleReadmore,
		Breadrcumb
	},
	data: () => ({
		articleHeight: 0,
		progress: 0
	}),
	computed: {
		article () {
			return this.$app.page.value
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	},
	async mounted () {
		wait(100).then((res) => {
			this.articleHeight = this.$refs.sectionContent.getBoundingClientRect().height
		})
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>
.article{
	z-index: 1101;
	padding: 0;
	.back-button {
		margin: 5rem auto 0 auto;
		display: block;
		width: fit-content;
	}

	.article-wrapper{
		padding-bottom: 6rem;
		@include media-breakpoint-up(xl) {
			padding-bottom: 12rem;
		}
	}
}

::v-deep .section-wrapper {
	margin-top: 6rem;
	@include media-breakpoint-up(xl) {
		margin-top: 12rem;
	}
}

::v-deep .wrapper {
	margin-bottom: 2.5rem;

	&.content-wrapper {
		margin: 0;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}
::v-deep(.article-body) {
	::v-deep .blog-header {
		margin-bottom: 1rem;
	}
	.article-introduction {
		font-size: 1.6rem;
		color: $primary;
		text-align: justify;
		font-style: italic;
		margin-bottom: 2rem;

		@include media-breakpoint-up(xl) {
			font-size: 1.8rem;
			margin-bottom: 3rem;
		}
	}

	.article-date {
		width: 100%;
		position: relative;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 10rem;
			width: 100%;
			border-bottom: .1rem solid $secondary;
		}
	}
	::v-deep .blog-header {
		@include media-breakpoint-up(lg) {
			line-height: 6.1rem;
		}
	}
}
</style>
