<template>
		<section class="section-wrapper dance-section" v-if="value.cards">
			<base-container>
				<base-heading tag="h2" :heading="`${prefix}.dance_section_title`" class="dance-heading"/>
				<cms-editor-text variant="paragraph" color="black" :prioritizedValue="value.dance_section_description"/>
				<grid-template cols="4" className="dancing-elements" :container="false">
					<base-card v-for="(item, index) in value.cards" :key="index" :card="item" :isLast="index === value.cards.length - 1"/>
				</grid-template>
			</base-container>
		</section>
</template>

<script>
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'
import BaseCard from '~/website/front/components/atoms/BaseCard/BaseCard.vue'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import { removeParagraph } from 'utils/removeParagraph.js'

export default {
	components: { BaseHeading, BaseCard, GridTemplate },
	methods: { removeParagraph },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.dance-section {
	padding-bottom: 0;
}
.dance-heading {
	margin-bottom: 4rem;

	@include media-breakpoint-up(xl) {
        margin-bottom: 4.7rem;
    }
}

::v-deep .dancing-elements {
	@include media-breakpoint-up(lg) {
		padding-top: 3rem;
    }
}

::v-deep .dancing-benefits {
	grid-template-columns: auto;
	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(2, 1fr);
    }
}
</style>
