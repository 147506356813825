<template>
	<section class="article-blocks-section" :class="setPadding">
		<cms-text :value="`${prefix}.section_title`" :props="{variant: 'h2', tag: value.header_tag }" v-if="value.section_title"/>

		<cms-editor-text
			v-if="value.text_content_before"
			:prioritizedValue="value.text_content_before"
			:props="{variant: 'paragraph'}"
			class="paragraph paragraph-top"
		/>

		<div class="article-section-wrapper">
			<article-block-item
				v-for="(item, i) in value.items"
				:prefix="`${prefix}.items.${i}`"
				:key="item.title"
				:item="item"
			/>
		</div>

		<cms-editor-text
			v-if="value.text_content_after"
			:prioritizedValue="value.text_content_after"
			:props="{variant: 'paragraph'}"
			class="paragraph paragraph-bottom"
		/>
	</section>
</template>

<script>
import ArticleBlockItem from '~/blog/front/sections/ArticleBlocksSection/components/ArticleBlockItem'
export default {
	components: { ArticleBlockItem },
	props: {
		value: Object,
		prefix: String
	},

	computed: {
		setPadding () {
			const obj = {}
			this.value.padding.forEach(item => {
				obj[item] = true
			})
			return obj
		}
	}
}
</script>

<style lang="scss" scoped>
.article-blocks-section {
	&.top {
		padding-top: 4rem;
	}
	&.bottom {
		padding-bottom: 4rem;
	}
	.article-section-wrapper {
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}
}
</style>
