<template>
  <section class="section-wrapper">
		<base-container>
			<cms-multi-section :value="`${prefix}.heading`"/>
		</base-container>
		<div class="opinions-wrapper" ref="opinionsWrapper">
			<div class="opinion-wrapper" ref="opinionWrapper">
				<opinion v-for="(opinion, index) in addLastElement" :opinionItem="opinion" :key="index"/>
			</div>
		</div>
		<div class="indicators">
			<button class="indicators__btn" :class="{active : counter === index}" v-for="(opinion , index) in countSlides" :key="index" @click="() => changeSlideByIndicator(index)"/>
		</div>
  </section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import Opinion from '../../components/molecules/Opinion/Opinion.vue'
export default {
	components: { CmsMultiSection, Opinion },
	data () {
		return {
			slidesPerView: 2,
			gap: 30,
			counter: 0,
			transform: 0,
			opinions: [...this.value.opinions],
			interval: '',
			xDown: 0,
			swiped: true,
			side: ''
		}
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		countSlides () {
			// SM NA 1
			// MD NA 2
			// LG NA 3
			return Math.ceil((this.isMobile() ? this.addLastElement.length : this.addLastElement.length - 1) / this.slidesPerView)
		},
		countSlidesTransform () {
			const singleElement = this.$refs.opinionWrapper.children[0].getBoundingClientRect()
			const value = (this.slidesPerView * singleElement.width + (this.slidesPerView * this.gap))
			return value
		},
		addLastElement () {
			const newArray = [...this.value.opinions]
			const lastElem = newArray[this.value.opinions.length - 1]
			if (!this.isMobile()) {
				newArray.unshift(lastElem)
			}
			return newArray
		}
	},
	mounted () {
		window.addEventListener('resize', this.centerSlides, { passive: true })
		// this.interval = setInterval(this.changeSlideByInterval, 5000)
		this.$refs.opinionsWrapper.addEventListener('touchstart', this.handleTouchStart, { passive: true })
		this.$refs.opinionsWrapper.addEventListener('touchmove', this.handleTouchMove, { passive: true })
		this.centerSlides()
	},
	methods: {
		getTouches (e) {
			return e.touches
		},
		handleTouchStart (e) {
			const firstTouch = this.getTouches(e)[0]
			this.xDown = firstTouch.clientX
		},
		handleTouchMove (e) {
			const xUp = e.touches[0].clientX

			var xDiff = this.xDown - xUp

			if (xDiff > 0) {
				if (this.swiped) {
					this.swiped = false
					this.changeSlideBySwipe('left')
				}
			} else {
				if (this.swiped) {
					this.swiped = false
					this.changeSlideBySwipe('right')
				}
			}
		},
		isMobile () {
			if (process.client) {
				return window.innerWidth <= 1600
			} else {
				return false
			}
		},
		changeTransform (value, index) {
			const element = this.$refs.opinionWrapper
			if (index === 0) {
				element.style.transform = this.isMobile() ? 'translateX(0)' : 'translateX(-255px)'
			} else element.style.transform = this.isMobile() ? `translateX(calc(-${value}% - ${index * 3}rem)` : `translateX(-${value}px)`
		},
		centerSlides () {
			const singleElement = this.$refs.opinionWrapper.children[0].getBoundingClientRect() || 0

			if (process.client) {
				if (window.innerWidth <= 768) {
					this.slidesPerView = 1
				} else if (window.innerWidth <= 992) {
					this.slidesPerView = 2
				} else if (window.innerWidth <= 1200) {
					this.slidesPerView = 3
				} else if (window.innerWidth <= 1400) {
					this.slidesPerView = 3
				} else {
					this.slidesPerView = 3
				}
			}
			if (this.isMobile()) {
				this.transform = singleElement.width
			} else {
				this.$refs.opinionWrapper.style.transform = `translateX(-${singleElement.width / 2}px)`
			}
		},
		changeSlide () {
			const singleElement = this.$refs.opinionWrapper.children[0].getBoundingClientRect()
			const value = this.isMobile() ? 100 * this.counter : (this.countSlidesTransform * this.counter) + (singleElement.width / 2 + this.gap)
			this.changeTransform(value, this.counter)
		},
		changeSlideBySwipe (side) {
			if (side === 'right') {
				this.counter <= 0 ? this.counter = Math.ceil((this.addLastElement.length) / this.slidesPerView) - 1 : this.counter--
			} else {
				this.counter >= Math.ceil((this.isMobile() ? this.addLastElement.length : this.addLastElement.length - 1) / this.slidesPerView) - 1 ? this.counter = 0 : this.counter++
			}
			this.changeSlide()

			setTimeout(() => {
				this.swiped = true
			}, 1000)
		},
		changeSlideByIndicator (index) {
			this.counter = index
			this.changeSlide()
			// clearInterval(this.interval)
			// this.interval = setInterval(this.changeSlideByInterval, 5000)
		},
		changeSlideByInterval () {
			this.counter >= Math.ceil((this.isMobile() ? this.addLastElement.length : this.addLastElement.length - 1) / this.slidesPerView) - 1 ? this.counter = 0 : this.counter++
			this.changeSlide()
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .grid-wrapper {
	display: flex;
}

.opinion-wrapper {
	display: flex;
	grid-gap: 0 3rem;
	transition: all 1s ease-in-out;
	position: relative;
	@include media-breakpoint-up(xxl) {
		padding: 0;
		transform: translateX(-255px);
	}
}

@mixin slider_fade {
	background: linear-gradient(rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0.69) 46.09%, rgba(246, 246, 246, 0.84) 59.16%, rgba(246, 246, 246, 0.9) 64.6%, rgba(246, 246, 246, 0.98) 82.8%, rgba(246, 246, 246, 1) 95.55%, #f6f6f6 100%);
	top: 0;
	height: 100%;
	width: 455px;
}
.opinions-wrapper {
	position: relative;
	padding: 0 2rem;
	&::before {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		content: "";
		width: 100%;
		height: 90%;
		background-color: #fff;
		border-top: .1rem solid $gray-700;
		border-bottom: .1rem solid $gray-700;
		z-index: -1;

		@include media-breakpoint-up(xxl) {
			left: 0;
			border: none;
			transform: rotate(90deg) translateY(100px);
			z-index: $level1;
			@include slider_fade()
		}
	}

	&::after {
		display: none;
		content: "";
		position: absolute;
		right: 0;
		transform: rotate(-90deg) translateY(100px);
		@include slider_fade();

		@include media-breakpoint-up(xxl) {
			display: block;
		}
	}
}

.indicators {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-top: 3rem;
		gap: 1rem .8rem;
		padding: 0 2rem;
		min-height: 1.3rem;

		@include media-breakpoint-up(lg) {
			padding: 0;
		}

		&__btn {
			border: none;
			background-color: transparent;
			display: inline-block;
			width: .9rem;
			height: .9rem;
			padding: 0;
			background-color: $black;
			opacity: .1;
			border-radius: 50px;
			cursor: pointer;
			@include transition;

			@include media-breakpoint-up(xl) {
				&:hover {
					background-color: $primary;
					opacity: 1;
				}
			}

			&.active {
				background-color: $primary;
				opacity: 1;
				width: 1.3rem;
				height: 1.3rem;
			}
		}
	}
</style>
