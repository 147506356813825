import { asyncForEach } from 'utils/async'

class Form {
	inputs = {}

	addInput (input) {
		this.inputs[input.name] = input
	}

	async validate () {
		const inputs = Object.entries(this.inputs).map(([key, input]) => input)
		let isFormValid = true
		await asyncForEach(inputs, async input => {
			const isInputValid = await input.touchAndValidate()
			if (!isInputValid) isFormValid = false
		})

		return isFormValid
	}

	async hideErrors () {
		const inputs = Object.entries(this.inputs).map(([key, input]) => input)
		await asyncForEach(inputs, async input => await input.hideError())
	}

	getValues () {
		return Object.entries(this.inputs).reduce(
			(values, [key, input]) => {
				values[input.attribute] = input.value
				return values
			}, {}
		)
	}
}

export { Form }
