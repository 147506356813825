<template>
	<div class="button-submit">
		<base-button @click="click" :disabled="disabled" type="secondary" class="button"><slot /></base-button>
		<base-svg   v-if="loading" name="autorenew" />
	</div>
</template>

<script>
import BaseSvg from '../../../atoms/BaseSvg/BaseSvg.vue'
export default {
	inheritAttrs: false,
	props: {
		loading: Boolean,
		disabled: Boolean
	},
	components: {
		BaseSvg
	},
	methods: {
		click () {
			if (!this.loading) this.$emit('click')
		}
	}
}
</script>

<style lang="scss" scoped>
.button-submit {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	@include media-breakpoint-up(lg) {
		margin-top: 2rem;
		justify-content: initial;
	}
	.button {
		cursor: pointer;
	}
	svg {
		animation: rotate infinite 1s linear;
		fill: $secondary;
		margin-left: 5px;
		width: 25px;
	}
	@keyframes rotate {
		from {transform: rotate(0);}
		to {transform: rotate(360deg);}
	}
}
</style>
