<template>
		<grid-template cols="2" :container="false">
			<client-only>
				<div :class="item.tile_icon_above ? 'technical-tile has-icon' : 'technical-tile'" v-for="(item, index) in value.tiles" :key="index">
					<base-svg  :name="item.tile_icon_above" v-if="item.tile_icon_above" class="svg-icon-above"/>
					<base-font variant="h3" color="black" class="title">{{ item.tile_title }}</base-font>
					<cms-editor-text
						color="black"
						v-if="item.tile_desc"
						class="description"
						:prioritizedValue="item.tile_desc"
						:props="{variant: 'paragraph', tag: 'div'}"
					/>
					<div class="icons" v-for="(icon, index) in item.icons" :key="index">
							<base-font variant="paragraph" color="black" v-if="icon.tile_icon_desc" class="description">{{ icon.tile_icon_desc }}</base-font>
							<base-svg class="icon" :name="icon.tile_icon_under" v-if="icon"/>
					</div>
				</div>
			</client-only>
		</grid-template>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import ClientOnly from 'vue-client-only'
export default {
	components: {
		GridTemplate,
		ClientOnly
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>

::v-deep .grid-wrapper {
	grid-template-columns: auto;
	gap: 2rem;

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, 1fr);
	}
}
.technical-tile {
	position: relative;
	::v-deep .description {
		margin-bottom: 0;
		a {
			@include link_styles();
		}
	}

	&.has-icon {
		@include media-breakpoint-up(md) {
			grid-column: 1/2;
		}
		&:nth-of-type(2) {
			@include media-breakpoint-up(md) {
				grid-column: 2/2;
				grid-row: 2;
			}
		}

		::v-deep .title {
			padding-left: 5rem;
		}
		.svg-icon {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.svg-icon-above {
		max-height: 3rem;
		display: block;
		margin: 0 auto 1rem auto;
	}

	.icons {
		display: flex;
		gap: 0 1rem;
		align-items: center;
		margin-top: 1rem;

		&:last-of-type {
			margin-bottom: 0;
		}

		&:nth-of-type(1) {
			margin-top: 2rem;
		}

		::v-deep .description {
			margin-bottom: 0;
		}
		::v-deep .icon {
			max-height: 2.8rem;
		}
	}
}
</style>
