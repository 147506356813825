<template>
  <div class="wrapper">
		<base-font variant="h2" color="black" v-if="value.steps_title">{{ value.steps_title }}</base-font>
		<grid-template cols="3" :container="false">
			<base-file v-for="(item, index) in value.steps" :key="index" :item="item" />
		</grid-template>
  </div>
</template>

<script>

import BaseFile from '~/website/front/components/atoms/BaseFile/BaseFile'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'

export default {
	components: { BaseFile, GridTemplate, BaseFont },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
::v-deep .file {
	text-align: center;
	p {
		margin-bottom: 0;
	}

	a {
		@include link_styles()
	}
}
</style>
