<template>
	<cms-text :value="`${prefix}.content`" :props="{variant: 'h3'}" class="subtitle"/>
</template>

<script>

export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
::v-deep .subtitle {
	margin-top: 2rem;
}
</style>
