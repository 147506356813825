class GTMService {
	productImpressions = []
	pushProductImpressionsTimeout = false
	constructor (app) {
		this.app = app
		this.observe()
	}

	_push (data) {
		window.dataLayer.push(data)
	}

	_pushEvent (event) {
		this._push({ event })
	}

	_pushEcommerce (data, event) {
		this._push({ ecommerce: null })
		const dataToPush = {
			ecommerce: {
				currencyCode: 'PLN',
				...data
			}
		}
		if (event) dataToPush.event = event
		this._push(dataToPush)
	}

	observe () {
		this.app.on('not_found_page', () => this.notFoundPage())
		this.app.on('contact_form:message:sent', () => this.contactFormMessageSent())
		this.app.on('newsletter:subscriber:new', () => this.newNewsletterSubscriber())
		this.app.on('product:impression', product => this.addProductImpression(product))
		this.app.on('product:clicked', product => this.productClicked(product))
		this.app.on('product:details', product => this.productDetails(product))
		this.app.on('cart:product:added', data => this.productAddedToCart(data))
		this.app.on('checkout', draftOrder => this.checkout(draftOrder))
		this.app.on('purchase', data => this.purchase(data))
		this.app.on('product:review:created', data => this.productReviewCreated(data))
	}

	notFoundPage () {
		this._pushEvent('not_found_page')
	}

	contactFormMessageSent () {
		this._pushEvent('contact_form_message_sent')
	}

	newNewsletterSubscriber () {
		this._pushEvent('new_newsletter_subscriber')
	}

	productReviewCreated () {
		this._pushEvent('product_review_created')
	}

	_prepareProduct (product) {
		return {
			id: product.id,
			name: product.name,
			price: product.price / 100
		}
	}

	_prepareProductWithQuantity (product, quantity) {
		const data = this._prepareProduct(product)
		data.quantity = quantity
		return data
	}

	pushProductImpressions () {
		this.pushProductImpressionsTimeout = false
		const impressions = this.productImpressions.map(product => this._prepareProduct(product))
		this._pushEcommerce({ impressions })
		this.productImpressions = []
	}

	productClicked (product) {
		this._pushEcommerce({
			click: {
				products: [
					this._prepareProduct(product)
				]
			}
		})
	}

	productDetails (product) {
		this._pushEcommerce({
			detail: {
				products: [
					this._prepareProduct(product)
				]
			}
		})
	}

	productAddedToCart ({ product, quantity }) {
		this._pushEcommerce({
			add: {
				products: [
					this._prepareProductWithQuantity(product, quantity)
				]
			}
		}, 'addToCart')
	}

	productRemovedFromCart (cartItem) {
		this._pushEcommerce({
			remove: {
				products: [
					this._prepareProductWithQuantity(cartItem.product, cartItem.quantity)
				]
			}
		}, 'removeFromCart')
	}

	checkout (draftOrder) {
		this._pushEcommerce({
			checkout: {
				products: draftOrder.cart.items.map(item => this._prepareProductWithQuantity(item.product, item.quantity))
			}
		}, 'checkout')
	}

	purchase (data) {
		this._pushEcommerce({
			purchase: {
				actionField: {
					id: data.id,
					revenue: data.amount / 100
				},
				products: data.items.map(item => this._prepareProductWithQuantity(item.product, item.quantity))
			}
		}, 'purchase')
	}

	addProductImpression (product) {
		if (this.pushProductImpressionsTimeout) clearTimeout(this.pushProductImpressionsTimeout)
		this.productImpressions.push(product)
		this.pushProductImpressionsTimeout = setTimeout(this.pushProductImpressions.bind(this), 10)
	}
}

export { GTMService }
