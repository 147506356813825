<template>
	<div
		class="block-section-item item-default"
		:class="{inverted: item.inverted}"
		v-if="isTemplateOfType('default')"
	>
		<cms-image :value="`${prefix}.image`"
			v-if="item.image"
			lazy
			:base="{width: 700, height: 400}"
		/>
		<div class="content-wrapper">
			<cms-text
				:value="`${prefix}.title`"
				:props="{tag: 'h4', variant: 'h3', color: 'black'}"
				class="content-title"
				v-if="item.title"
			/>
			<cms-editor-text
				:prioritizedValue="item.description"
				:props="{variant: 'paragraph'}"
				v-if="item.description"
				class="paragraph"
			/>
		</div>
	</div>

	<div
		class="block-section-item item-people"
		:class="{inverted: item.inverted}"
		v-else-if="isTemplateOfType('people')"
	>
		<cms-image :value="`${prefix}.image`"
			v-if="item.image"
			lazy
			:base="{width: 150, height: 161}"
			:xl="{width: 200, height: 200}"
		/>
		<div class="content-wrapper">
			<cms-text
				:value="`${prefix}.title`"
				:props="{tag: 'h4', variant: 'h3', color: 'black'}"
				class="content-title"
				v-if="item.title"
			/>
			<cms-editor-text
				:prioritizedValue="item.description"
				:props="{variant: 'paragraph'}"
				v-if="item.description" class="paragraph"/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: Object,
		prefix: String
	},
	methods: {
		isTemplateOfType (type) {
			if (!this.item.template) return false
			console.log(this.item.template === type)
			return this.item.template === type
		}
	}
}
</script>

<style lang="scss" scoped>
.block-section-item {

	display: flex;
	gap: 3rem;
	flex-direction: column;

	&.inverted {
		grid-auto-flow: dense;
		direction: rtl;
	}

	&.item-default {
		@include media-breakpoint-up(xl) {
			display: grid;
			grid-template-columns: 4fr 6fr;
			gap: 6rem;
			align-items: center;
		}
	}

	&.item-people {
		@include media-breakpoint-up(xl) {
			display: grid;
			grid-template-columns: 2fr 10fr;
			gap: 6rem;
			align-items: center;
		}
	}

	.image-wrapper {
		::v-deep(img) {
			border-radius: 1.3rem;
			width: initial;
			max-width: 100%;
			display: block;
			margin: 0 auto;
			@include media-breakpoint-up(xl) {
				margin: initial
			}
		}
	}

	.content-wrapper {
		text-align: left;

		.paragraph {
			direction: ltr;

			::v-deep(.ql-editor) {
				margin-bottom: 0;
			}
		}
	}

	::v-deep(a) {
		word-break: break-word;
		@include link_styles();
	}
}
</style>
