<template>
  <div class="wrapper">
	<base-number :numbers="value.numbers" :numbers_text="value.numbers_text"/>
  </div>
</template>

<script>
import BaseNumber from '../../components/molecules/BaseNumber/BaseNumber.vue'

export default {
	components: { BaseNumber },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
