import { render, staticRenderFns } from "./ArticlePhotoSection.vue?vue&type=template&id=9407d2ae&scoped=true"
import script from "./ArticlePhotoSection.vue?vue&type=script&lang=js"
export * from "./ArticlePhotoSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9407d2ae",
  null
  
)

export default component.exports