<template>
  <div class="wrapper">
	<base-container>
	<base-button type="secondary" :url="value.button_link" :title="value.button_title_seo">{{ value.button_title }}</base-button>
	<div class="background">
			<cms-image
			:src="value.backround_section_image"
			lazy
			:base="{width: 800, height: 150}"
			:md="{width: 1920, height: 291}"
			/>
	</div>
	</base-container>
  </div>
</template>

<script>

export default {
	computed: {
		backgroundImage () {
			const path = this.value.backround_section_image.path

			return {
				src: path,
				breakpoints: {
					base: { width: 1920, height: 291 }
				}
			}
		}
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.wrapper {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	min-height: 15rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $primary;
		z-index: -1;
	}

	@include media-breakpoint-up(lg) {
		min-height: 29.1rem;
	}

	::v-deep .background {
		position: absolute;
		left: 0;
		top: 0;

		.base-image {
			opacity: 0.18;
		}
	}

	.btn-secondary {
		z-index: $level0;
		padding: 1.7rem 2rem;
		position: relative;
		display: block;
		width: fit-content;
		margin: 0 auto;

		@include media-breakpoint-up(sm) {
			padding: 1.7rem 4rem;
		}
	}
}
</style>
