<template>
	<footer class="footer" v-background="background">
		<base-container>
			<client-only>
				<div class="wrapper">
					<div class="inner-wrapper">
						<div class="footer-address">
							<base-font variant="footer-header" class="footer-header">Zapraszamy do nas</base-font>
							<div class="footer-address-content">
								<div class="footer-address-wrapper">
									<div class="footer-street">
										<base-svg name="footerPin" />
										<cms-text layout :props="{variant: 'paragraph', color: 'white', marginReset: true}" value="footer_datas.footer_address"/>
									</div>
									<div class="footer-hours">
										<base-svg name="clock" />
										<div class="footer-hours-divider">
										<base-font variant="paragraph" color="white" marginReset><strong>Pon - Pt: </strong></base-font>
										<cms-text layout :props="{variant: 'paragraph', color: 'white', marginReset: true}" value="footer_datas.footer_hours" class="hours"/>
										</div>
									</div>
									<div class="footer-hours">
										<base-svg name="clock" />
										<div class="footer-hours-divider">
										<base-font variant="paragraph" color="white" marginReset><strong>Sob - Niedz: </strong></base-font>
										<cms-text layout :props="{variant: 'paragraph', color: 'white', marginReset: true}" value="footer_datas.footer_weekend_hours" class="hours"/>
										</div>
									</div>
								</div>
								<base-font tag="span" color="white" marginReset class="footer-additional">{{footerContent.footer_address_additional}}</base-font>
							</div>
						</div>
						<base-svg name="footerLogo" class="footer-logo" />
						<div class="footer-contact">
							<base-font variant="footer-header" class="footer-header">Skontaktuj się z nami</base-font>
							<div class="footer-contact-content-wrapper">
								<div class="footer-contact-content">
									<cms-text layout :props="{variant: 'paragraph', color: 'white'}" value="footer_datas.footer_address_data" class="footer-address-additional"/>
								</div>
								<div class="footer-links">
									<base-link type="footer-phone" underline :url="`tel:+48${footerData.phoneNumber}`" :title="footerData.phoneNumber">
										<base-svg name="phone" class="phone" />
										{{footerData.phoneNumber}}
									</base-link>
									<base-link type="footer-envelope" underline :url="`mailto:${footerData.email}`" :title="footerData.email">
										<base-svg name="envelope" class="envelope"/>
										{{footerData.email}}
									</base-link>
								</div>
							</div>
						</div>
					</div>
					<div class="footer-tiles">
							<div class="footer-tile footer-accounts">
								<base-font variant="footer-tile" class="header" uppercase >{{langs.accounts}}</base-font>
								<div class="account">
								<base-font variant="paragraph" color="red" weight="bold" marginReset>{{langs.depositAccount}}:</base-font>
								<base-font variant="paragraph" class="account-number" marginReset>{{footerData.depositAccount}}</base-font>
								</div>
								<div class="account">
								<base-font variant="paragraph" color="red" weight="bold" marginReset>{{langs.rentAccount}}:</base-font>
								<base-font variant="paragraph" class="account-number" marginReset>{{footerData.rentAccount}}</base-font>
								</div>
							</div>
							<div class="footer-tile footer-absence">
								<base-font variant="footer-tile" class="header" uppercase >{{langs.abscents}}</base-font>
								<div class="absence-wrapper">
									<div class="absence-content">
										<div class="sms">
											{{langs.sms}}
										</div>
										<base-font variant="paragraph" marginReset>{{langs.number}}</base-font>
										<base-link type="footer" :url="`tel:+48${footerData.phoneNumber}`" :title="footerData.phoneNumber">{{footerData.phoneNumber}}</base-link>
									</div>
									<base-button type="secondary" class="report-button" to="report-absence" title="Zgłoś niebecność we Wrocławskiej Szkole Tańca">{{langs.report}}</base-button>
								</div>
							</div>
						</div>
				</div>
			</client-only>
		</base-container>
	</footer>
</template>
<script>
import ClientOnly from 'vue-client-only'

export default {
	components: {
		ClientOnly
	},
	computed: {
		footerData () {
			return this.$app.getSettings().general.contact
		},
		footerContent () {
			return this.$app.layout.value.footer_datas
		},
		footerImage () {
			return this.$app.layout.value.footer_image
		},
		background () {
			const path = this.footerImage.path

			return {
				src: path,
				breakpoints: {
					base: { width: 400, height: 650 },
					lg: { width: 1920, height: 620 }
				}
			}
		},
		langs () {
			return this.$app.translator.get('footer')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('footer')
	}
}
</script>
<style lang="scss" scoped>
.footer {
	padding: 4rem 0;
	background-size: cover;
	background-position: center;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background: $secondary;
		opacity: .85;
		z-index: $level0;
	}
	@include media-breakpoint-up(xl) {
		padding: 9rem 0 6rem 0;
	}
    &-links {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        ::v-deep .link {
            display: flex;
            justify-content: center;
            align-items: center;

        }
        .svg-icon {
            margin-right: 2rem;
        }
        .svg-icon.phone {
            width: 23px;
            height: 23px;
        }
        .svg-icon.envelope {
            width: 18px;
            height: 14px;
        }
    }
    ::v-deep &-additional {
        width: 45%;
    }
    &-tiles {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        @include media-breakpoint-up(md) {
            flex-direction: row;
            .footer-tile {
                width: 50%;
            }
        }
        .footer-tile {
            background: rgba($white, .96);
            padding: 2.5rem;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            @include media-breakpoint-up(xl) {
                padding: 2rem 3rem;
            }
            ::v-deep .header {
                @include media-breakpoint-up(xl) {
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 1rem;
                    &::after {
                        content: '';
                        position: absolute;
                        top:50%;
                        left: 300px;
                        height: 1px;
                        width: 50%;
                        background: $black;
                        opacity: .15;
                    }
                }
            }
            .account {
                display: flex;
                flex-wrap: wrap;
                ::v-deep .account-number {
                    margin-left: auto;
                    @include media-breakpoint-up(xxl) {
                        margin-left: 1rem;
                    }
                }
            }
            &.footer-accounts {
                @include media-breakpoint-up(xxl) {
                    width: 70%;
                }
            }
            .absence-content {
                display: flex;
                align-items: center;
                gap: .5rem;
                .sms {
                    padding: 1rem 1.5rem;
                    background: $red-300;
                    color: $white;
                    border-radius: 8px;
                }
            }
            ::v-deep .report-button {
                width: fit-content;
                margin: 1rem auto 0 auto;
                @include media-breakpoint-up(xl) {
                    margin: 0;
                }
            }
        }
    }
    &-contact {
        .footer-contact-content-wrapper {
            @include media-breakpoint-up(xl) {
                display: flex;
                flex-direction: row-reverse;
                gap: 4rem;
            }
        }
    }
    &-absence {
        .absence-wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
            @include media-breakpoint-up(xl) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}
.wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: $level1;
    .inner-wrapper {
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }
	.footer-address,.footer-contact {
		::v-deep .footer-header {
			text-align: center;
			margin-bottom: 3.5rem;

		}
        @include media-breakpoint-up(lg) {
            width: 50%;
        }
        @include media-breakpoint-up(xl) {
            position: relative;
            overflow: hidden;
        }
	}
    .footer-contact {
        ::v-deep .footer-header {
            @include media-breakpoint-up(xl) {
                text-align: right;
            }
            @include media-breakpoint-up(xl) {
                &::after {
                    content: '';
                    position: absolute;
                    top:11px;
                    right: 240px;
                    width: 100%;
                    height: 1px;
                    background: $white;
                }
            }
        }
    }
	.footer-address {
         ::v-deep .footer-header {
            @include media-breakpoint-up(xl) {
                text-align: left;
                &::after {
                    content: '';
                    position: absolute;
                    top:11px;
                    left: 210px;
                    width: 100%;
                    height: 1px;
                    background: $white;
                }
            }

        }
		&-content {
			display: flex;
			flex-wrap: wrap;
			position: relative;
            justify-content: space-between;
            @include media-breakpoint-up(md) {
                flex-direction: column;
                align-items: center;
                gap: 2rem;
            }
            @include media-breakpoint-up(xl) {
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: row;
                gap: 0;
            }
		}
		.svg-icon {
			width: 18px;
			height: 18px;
			margin-right: 1.5rem;
		}
		.footer-street,.footer-hours {
			display: flex;
		}
		.footer-hours-divider {
			display: flex;

			.hours {
				margin-left: .5rem;
				font-weight: 400;
			}
		}
		.footer-address-wrapper {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			width: 55%;
            @include media-breakpoint-up(md) {
                width: auto;
            }
		}
		::v-deep .footer-additional {
			letter-spacing: 0.06em;
            line-height: 1.8rem;

            font-style: italic;
            @include media-breakpoint-up(md) {
                text-align: center;
            }
            @include media-breakpoint-up(xl) {
                text-align: left;
                font-size: 1.2rem;
                letter-spacing: 0.06em;
                line-height: 2rem;
            }
		}
        &-additional {
            text-align: center;
            line-height: 2.8rem;
            margin-bottom: 3rem;
            @include media-breakpoint-up(xl) {
                text-align: right;
            }
        }
	}
	::v-deep .footer-logo {
		width: 100px;
		height: 130px;
		margin: 4rem auto 6rem auto;
        @include media-breakpoint-up(xl) {
            width: 120px;
            height: 170px;
            margin: 4rem 2rem;
        }
        @include media-breakpoint-up(xxl) {
            width: 190px;
            height: 200px;
            margin: 4rem 6rem;
        }
	}
}
</style>
