<!--<template>-->

<!--</template>-->

<script>
import Vue from 'vue'

export default {
	props: {
		cellValue: String
	},
	data: () => ({
		templateRender: () => '',
		compiledValue: null
	}),
	methods: {
		rerender () {
			this.$options.staticRenderFns = []
			var compiledTemplate = Vue.compile(`<td class="table-col ${!this.compiledValue ? 'empty' : 'full'}">${this.compiledValue}</td>`)

			this.templateRender = compiledTemplate.render

			this.$options.staticRenderFns = compiledTemplate.staticRenderFns
		}
	},
	render (h) {
		return this.templateRender()
	},
	mounted () {
		this.compiledValue = this.cellValue || ''
		this.rerender()
	}
}
</script>

<style scoped lang="scss">
.table-col {
	padding: 2rem 2rem;
	max-width: 35rem;
	@include media-breakpoint-up(lg) {
		padding: 2rem 3rem;
	}
	&:first-of-type {
		font-weight: 700;
		text-transform: uppercase;
		text-align: left;
		padding-left: 2rem;
		width: max-content;
		display: inline-block;
		text-align: center;
		@include media-breakpoint-up(lg) {
			width: auto;
			display: block;
		}
	}

	.image-wrapper {
		margin: 2rem 0;
	}
}
</style>
