<template>
	<div class="wrapper">
		<grid-template cols="3" className="" :container="false">
			<base-dance-type v-for="(item, index) in value.dance_types" :key="index" :item="item"/>
		</grid-template>
	</div>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseDanceType from '../../components/atoms/BaseDanceType/BaseDanceType.vue'

export default {
	components: { GridTemplate, BaseDanceType },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.wrapper {
	margin-top: 1rem;

	@include media-breakpoint-up(lg) {
		margin-top: 3rem;
	}
}
</style>
