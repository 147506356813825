<template>
	<div class="cart-item" @click="open">
		<div class="cart-link item-wrapper" >
			<div class="item-icon-wrapper">
				<div ref="iconCircle" class="circle">{{cart.quantity}}</div>
				<base-svg class="icon" :class="{'white-cart': color}" name="cart"/>
			</div>
			<div class="cart-text-desktop">
				<cms-text  :props="{variant: 'menu'}" value="menu.cart" layout/>
			</div>
		</div>
	</div>
</template>
<script>
import { getPath } from 'utils/getPath'

export default {
	props: {
		color: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		cart () {
			return this.$app.cart
		}
	},
	methods: {
		open () {
			if (this.$app.utils.mobile) this.$app.vue.$router.push(this.createPath('cart-page'))
			else if (this.$app.page.route.meta.alias !== 'cart-page') {
				this.$app.cartModule.toggle()
			}
		},
		timeout () {
			this.timer = setTimeout(this.removeClass, 2000)
		},
		removeClass () {
			this.$refs.iconCircle.classList.remove('active')
		},
		createPath (to) {
			return getPath({
				app: this.$app,
				to
			})
		}
	},
	watch: {
		'cart.quantity' () {
			clearTimeout(this.timer)
			this.timeout()
			this.$refs.iconCircle.classList.add('active')
		}
	}
}
</script>
<style lang="scss" scoped>
@keyframes iconShake {
	0% {
		transform:  translateY(0px) rotate(0deg);
	}
	50% {
		transform:  translateY(-10px) rotate(10deg);
	}
	100% {
		transform:  translateY(0px) rotate(0deg);
	}
}
.cart-item {
	display: none;
	text-decoration: none;
	@include media-breakpoint-up(xl) {
		display: block;
		flex-grow: 1;
		background-color: $gray-500;
		cursor: pointer;
		@include transition;
		&:hover {
			background-color: $gray-300;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	@include media-breakpoint-up(xxl) {
		min-width: 160px;
	}
	.cart-text-desktop {
		display: none;
		@include media-breakpoint-up(xl) {
			display: block;
		}
	}
}
.item-icon-wrapper {
	position: relative;
	@include media-breakpoint-up(xl) {
		margin-right: 0;
	}
	.circle {
		position: absolute;
		top: -10px;
		right: -10px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		color: $white;
		font-weight: bold;
		font-size: 1rem;
		background-color: $danger;
		animation: iconShake 1s paused infinite alternate linear;
		&.active {
			animation-play-state: running;
		}
	}
}
.cart-link {
	display: flex;
	justify-content: center;
	flex-grow: 1;
	height: 100%;
	position: relative;
	text-decoration: none;
	@include media-breakpoint-up(xl) {
		padding: 3rem 2rem;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	@include media-breakpoint-up(xxl) {
		padding: 3rem 2.5rem;
	}
	.icon {
		width: 20px;
		height: 20px;
		@include media-breakpoint-up(xl) {
			display: block;
			margin-bottom: 6px;
		}
		::v-deep &.white-cart path {
			fill: $white;
		}
	}
}
</style>
