<template>
	<div class="field-wrapper"
		:info="input.info"
	>
		<div class="field-label">
			<base-font
				variant="label"
				color="black"
				:for="input.name"
				@click.stop="$emit('click')"
			>{{input.label}}:</base-font>
		</div>
		<div class="field-inner-wrapper">
			<textarea
				:id="input.name"
				:rows="6"
				v-model="input.value"
				class="textarea"
				:maxlength="input.maxLength"
				:placeholder="input.placeholder"
				:class="{
					invalid: input.isInvalidAndTouched,
					disabled: input.disable
				}"
				@blur="input.markAsTouched()"
			></textarea>
			<div
				v-if="input.info"
				class="field-icon input-info"
				v-tooltip="{content: input.info}"
			>
				<base-svg name="info_button"/>
			</div>
		</div>
		<base-error :error="input.error" />
	</div>
</template>

<script>
import mixin from '../utils/input.mixin'
import BaseError from '@f/components/BaseError'
export default {
	mixins: [mixin],
	components: {
		BaseError
	},
	props: {
		input: Object
	}
}
</script>

<style lang="scss" scoped src="../utils/fields.scss" />
