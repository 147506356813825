<template>
	<div class="calendar__item" :class="{ 'current': calendar.areDatesEqual(activeDate, date), 'exists' : eventsHas, 'disabled' : disabled}" @click="$emit('setActiveDate', date)" >
		<span v-if="date.getDate() >= 10">{{ date.getDate() }}</span>
		<span v-else>0{{ date.getDate() }}</span>
	</div>
</template>

<script>

export default {
	props: {
		prefix: String,
		date: Date,
		calendar: Object,
		activeDate: Date,
		eventsHas: Boolean,
		disabled: Boolean
	},
	computed: {

	},
	methods: {
	}
}
</script>

<style scoped>

</style>
