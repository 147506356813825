<template>
	<div
		class="slide"
		:class="{
			active: index === active,
			next: index > active,
			prev: index < active,
		}"
		v-background="src"
	>
		<base-container>
			<div class="slide-content" :class="slideActiveClass(index)">
				<div class="slide-content__headings">
					<h3 class="slide-content__title">{{ value.slide_title }}</h3>
					<h2 class="slide-content__subtitle" v-html="addTagsToText(value.slide_subtitle)"/>
				</div>
				<cms-image
					class="slide-content__img"
					:src="value.slide_image"
					preload
				/>
			</div>
		</base-container>
	</div>
</template>
<script>

export default {
	props: {
		prefix: String,
		index: Number,
		value: Object,
		active: Number,
		slideTime: Number
	},
	computed: {
		src () {
			if (!this.value.slide_photo) return ''
			return this.value.slide_photo.path
		}
	},
	methods: {
		addTagsToText (value) {
			const title = value.split('')
			const modifyTitle = title.map((letter, index) => `<span translate="no"  class="slide-content__subtitle--${index}">${letter}</span>`).join('')

			return modifyTitle
		},
		slideActiveClass (index) {
			return `slide-content--${index}`
		}
	}
}
</script>
<style lang="scss" scoped>
.slide {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	min-height: 100%;
	background-size: cover;
	flex-shrink: 0;
	width: 100%;
	transition: opacity 1s linear;
	z-index: $level3;
	padding-top: 6rem;
	padding: 0 2rem;
	z-index: 10;
	opacity: 0;

	@include media-breakpoint-up(lg) {
		padding: 0;
		align-items: center;
	}

	&.active {
		opacity: 1;
	}
	&-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&-content {

		z-index: $level4;
		position: relative;
		backface-visibility: hidden;

		&__headings {
			width: 100%;
			position: absolute;
			top: 4.2rem;

			@media (orientation: landscape) and (max-width: 813px) {
				top: 2.2rem;
			}

			@include media-breakpoint-up(xl) {
				top: 4rem;
			}

			@include media-breakpoint-up(xxl) {
				top: 4rem;
			}
		}

		&__title {
			font-size: 1.2rem;
			font-weight: 300;
			letter-spacing: 0.16em;
			text-transform: uppercase;
			color: $white;
			line-height: 1.4rem;
			text-align: center;
			z-index: $level2;
			position: relative;

			@include media-breakpoint-up(xl) {
				font-size: 2rem;
				line-height: 2.4rem;
			}
		}

		&__subtitle {
			text-align: center;
			font-family: $secondary-font;
			letter-spacing: 0.16em;
			font-weight: bold;
			font-size: 5.5rem;
			line-height: 8.6rem;
			text-transform: uppercase;
			color: $white;

			@include media-breakpoint-up(sm) {
				font-size: 5.7rem;
			}
            @include media-breakpoint-up(md) {
				font-size: 7.7rem;
                line-height: 10rem;
			}
            @include media-breakpoint-up(lg) {
				font-size: 10rem;
                line-height: 13rem;
			}
            @include media-breakpoint-up(xl) {
				font-size: 14rem;
				line-height: 17rem;
			}

			@include media-breakpoint-up(xxl) {
				font-size: 18rem;
				line-height: 17rem;
			}
			@include media-breakpoint-up(xl) {
				margin-top: 0.6rem;
			}
			@include media-breakpoint-up(xxl) {
				margin-top: 1.6rem;
			}
		}

		&__img {
			z-index: $level1;
			margin-top: 4.25rem;

			@media (orientation: landscape) and (max-width: 813px) {
				margin-top: 1.25rem;
			}

			@include media-breakpoint-up(md) {
				margin-top: 3rem;
			}

			@include media-breakpoint-up(xxl) {
				margin-top: 0rem;
			}

			::v-deep .base-image {
				display: block;
				width: 100%;
                @include media-breakpoint-landscape () {
                    max-height: 100vh;
                }
                @include media-breakpoint-up(md) {
                    max-height: 50vh;
                }
                @include media-breakpoint-up(lg) {
                    max-height: 65vh;
                }
                @include media-breakpoint-up(xl) {
                    max-height: 80vh;
                }
                @include media-breakpoint-up(xxl) {
                    max-height: 91vh;
                }
                @media (min-width: 2700px) {
                    height: 60vh;
                }
			}
		}

		::v-deep &--0 {
				.slide-content__subtitle {
					&--7,&--8{
					z-index: $level1;
					position: relative;
				}
					&--7,&--8{
					z-index: $level2;
					position: relative;
					@include media-breakpoint-up(xl) {
						z-index: $level2;
						position: relative;
					}
				}
			}

			.slide-content__img {
				// margin-top: 6rem;

				// @media (orientation: landscape) and (max-width: 813px) {
				// 	margin-top: 1.25rem;
				// }
			}
		}

		::v-deep &--1 {

			.slide-content__img {
				@include media-breakpoint-up(xl) {
                    transform: translateX(15px);
				}
                @media (min-width: 1600px) and (max-width: 1700px) {
                    transform: translate(15px, -50px);
                }
			}
		}

		::v-deep &--2 {

			.slide-content__img {
					// margin-top: 0rem;
			}
		}
	}
}
</style>
