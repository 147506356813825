const AppSnackbar = {
	showSnackbar (snackbar) {
		this.snackbar = snackbar
	},
	closeSnackbar () {
		this.snackbar = null
	}
}
export {
	AppSnackbar
}
