<template>
	<div class="socials-wrapper" :class="classList">
		<base-link :url="value.facebook" class="social-link">
			<base-svg name="facebook"/>
		</base-link>
        <base-link :url="value.instagram" class="social-link">
			<base-svg name="instagram"/>
		</base-link>
        <base-link :url="value.youtube" class="social-link">
			<base-svg name="youtube"/>
		</base-link>
	</div>
</template>
<script>
export default {
	props: {
		classList: {
			type: String,
			default: ''
		}
	},
	computed: {
		value () {
			return this.$app.layout.value
		}
	}
}
</script>
<style lang="scss" scoped>
	.socials-wrapper {
		display: flex;
		align-items: center;
		gap: .5rem;
		.social-link {
			line-height: normal;
		}
		::v-deep .svg-icon {
			height: 25px;
			width: 25px;
		}
        &.desktop_socials {
            display: none;
            @include media-breakpoint-up(xl) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: fixed;
                top: 106px;
                left:0;
                z-index: $level2;
                gap: .2rem;
                .social-link {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                    background-color: $primary;
                    position: relative;
                    @include transition;
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top:0;
                        height: 100%;
                        width: 100%;
                        background: rgba(0,0,0, .1);
                        opacity: 0;
                        @include transition;
                    }
                    &:hover {
                        width: 40px;
                        &::after {
                            opacity: 1;
                        }
                    }
                }
                ::v-deep .svg-icon {
                    height: 25px;
                    width: 25px;
                }

            }
            @include media-breakpoint-up(xxl) {
                .social-link {
                    width: 50px;
                    height: 50px;
                    &:hover {
                        width: 55px;
                        &::after {
                            opacity: 1;
                        }
                    }
                }
                ::v-deep .svg-icon {
                    height: 35px;
                    width: 35px;
                }
            }
        }

	}
</style>
