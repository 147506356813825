<template>
  <div class="wrapper">
	<base-features :features="value.features"/>
  </div>
</template>

<script>
import BaseFeatures from '../../components/molecules/BaseFeatures/BaseFeatures.vue'

export default {
	components: { BaseFeatures },
	computed: {},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
