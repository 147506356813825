import { Page } from '~/cms/front/core/pages/Page'
import EventPageComponent from './EventPage.vue'

class EventPage extends Page {
	static alias = 'event'

	async init () {
		await super.init(...arguments)
	}

	component = EventPageComponent
	event = {}

	constructor ({ app, route, from }) {
		super({ app, route, from })
	}

	get value () {
		return this.event
	}

	async fetchData () {
		const Event = this.app.getEntity('event')
		this.event = await Event.fetchOne(this.alias)
		if (!this.event) return this.app.vue.$redirect({ path: '/404' })
		await this.setPageData(this.event.page)
	}

	get header () {
		return {
			title: this.value.header ? this.value.header : this.event.name
		}
	}

	restore () {
		this.componentsData = window.__PAGE__.componentsData
		this.layout = this.app.getLayout(window.__PAGE__.layout)
		if (this.layout) this.layout.restore()
		this.event = window.__PAGE__.event
	}

	store () {
		const store = super.store()
		delete store.value
		store.event = this.event
		return store
	}

	async setPageData (data) {
		this.createdAt = data.createdAt
		this.updatedAt = data.updatedAt
		this.meta = data.meta
		this.layout = this.app.getLayout(data.layout)
		if (this.layout) await this.layout.fetch()
	}
}

export { EventPage }
