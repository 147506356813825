export function toPascalCase (text) {
	return text.match(/[a-z]+/gi)
		.map(function (word) {
			return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
		})
		.join('')
}

export function serialize (params) {
	const keys = Object.keys(params).filter(key => !!params[key])
	const serialized = keys.map(key => key + '=' + params[key]).join('&')
	return serialized ? `?${serialized}` : ''
}

export default function generateRandomString (length) {
	return [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('')
}

export function generateSpecificRandomString (length) {
	let result = ''
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
	const charactersLength = characters.length
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

export function cutQuery (url) {
	const queryIndex = ~url.indexOf('?') ? url.indexOf('?') : url.length
	return url
		.split('')
		.splice(0, queryIndex)
		.join('')
}

export function replacer (string, regex, replaceValue) {
	return string.replaceAll(regex, replaceValue)
}

export function price (price) {
	const parsedNumber = (price / 100).toFixed(2).replace('.', ',')
	return `${parsedNumber} zł`
}

export function generateColor (number) {
	return [
		'#3366cc',
		'#dc3912',
		'#ff9900',
		'#109618',
		'#990099',
		'#0099c6',
		'#dd4477',
		'#66aa00',
		'#b82e2e',
		'#316395',
		'#994499',
		'#22aa99',
		'#aaaa11',
		'#6633cc',
		'#e67300',
		'#8b0707',
		'#651067',
		'#329262',
		'#5574a6',
		'#3b3eac',
		'#b77322',
		'#16d620',
		'#b91383',
		'#f4359e',
		'#9c5935',
		'#a9c413',
		'#2a778d',
		'#668d1c',
		'#bea413',
		'#0c5922',
		'#743411'
	][number]
}
