import cookies from 'js-cookie'

class CookiesFront {
	get (name) {
		return cookies.get(name)
	}

	remove (name) {
		cookies.remove('rext-token-client')
	}

	set (name, value, options) {
		cookies.set(name, value, options)
	}
}

export { CookiesFront }
