<template>
	<div class="heading-wrapper" :class="{'white-heading': white}" ref="heading" :key="Math.random()">
		<cms-text
			v-if="heading"
			:props="{variant: 'heading', tag: tag, color: white ? 'white' : null }"
			:value="heading"
			:layout="layout"
			:class="tag"
		/>
		<cms-text
			v-if="html"
			:props="{variant: 'heading', tag: tag, color: white ? 'white' : null }"
			:class="tag"
			:additionalHtml="html"
		/>
		<cms-text
			v-if="subheading"
			class="subheading"
			:props="{variant: 'subheading', color: white ? 'white' : null }"
			:value="subheading"
			:layout="layout"
		/>
	</div>
</template>
<script>
export default {
	props: {
		heading: String,
		subheading: String,
		layout: Boolean,
		white: {
			type: Boolean,
			default: false
		},
		tag: {
			type: String,
			default: 'h2'
		},
		html: String
	},
	methods: {
		isDanceSection () {
			const headings = [...document.querySelectorAll('.heading-wrapper')]
			headings.forEach(heading => {
				let headingName = heading.innerText.replaceAll(' ', '_').toLowerCase().replaceAll(/\n/ig, '')

				if (headingName.includes('nauka_tańca')) {
					headingName = headingName.replace('nauka_tańca', '').replaceAll('\n', '')
				}
				this.$root.$refs[headingName] = heading
			})
		}
	},
	mounted () {
		this.$nextTick(() => { this.isDanceSection() })
	}
}
</script>
