<template>
    <div class="wrapper">
        <breadrcumb />
        <section class="section-wrapper">
            <base-container>
            <div class="event-intro">
				<div class="title">
					<base-font variant="heading" tag="h1">{{eventValue.name}}</base-font>
					<div class="event-intro-tags">
						<base-font variant="paragraph" size="event-tag" color="white" class="event-tag" v-for="(tag, index) in eventValue.tags" :key="index"> #{{tag}}</base-font>
					</div>
				</div>
            </div>
            <div class="event-information">
                <div class="event-information__date" v-if="Boolean(eventValue.data.startDateSecondDay) && Boolean(eventValue.data.endDateSecondDate)">
                    <base-font variant="h2" class="event-information__description-title">Kiedy?</base-font>
                    <base-font variant="paragraph" marginReset uppercase><strong>{{startDate}} {{getDay(eventValue.data.startDate)}} - {{new Date(eventValue.data.endDate).getHours()}}:{{getMinutes(eventValue.data.endDate)}}</strong> <strong></strong></base-font>
                    <base-font variant="paragraph" uppercase><strong>{{endDate}} {{getDay(eventValue.data.startDateSecondDay)}} - {{new Date(eventValue.data.endDateSecondDate).getHours()}}:{{getMinutes(eventValue.data.endDateSecondDate)}}</strong> <strong></strong></base-font>
                </div>
				<div class="event-information__date" v-else>
					<base-font variant="h2" class="event-information__description-title">Kiedy?</base-font>
					<base-font variant="paragraph" uppercase><strong>{{startDate}} {{getDay(eventValue.data.startDate)}} - {{new Date(eventValue.data.endDate).getHours()}}:{{getMinutes(eventValue.data.endDate)}}</strong></base-font>
				</div>
            </div>
            <div class="event-information__description">
                <div class="event-information__description-text">
                    <base-font variant="h2" class="event-information__description-title">Opis wydarzenia</base-font>
                    <base-font variant="paragraph" :html="eventValue.description"/>
                </div>
                <cms-image
                    v-if="eventValue.thumbnail"
                    lazy
                    :src="eventValue.thumbnail"
                    :base="{width: 500, height: 350}"
                    :sm="{width: 500, height: 250}"
                    :md="{width: 330, height: 180}"
                    :lg="{width: 450, height: 230}"
                    :xl="{width: 550, height: 250}"
                    :xxl="{width: 700, height: 350, position:'entropy'}"
                />
            </div>
			<div class="buttons-wrapper">
				<base-button :url="eventValue.linkToEvent" type="primary" v-if="eventValue.linkToEvent && !isEventTerminated" title="Zapisz się na wydarzenie">Zapisz się na wydarzenie</base-button>
                <base-button to="contact" type="primary" v-else-if="!isEventTerminated" title="Zapisz się na wydarzenie">Zapisz się na wydarzenie</base-button>
				<base-button to="events" type="secondary" title="Powrót do wszystkich wydarzeń">Powrót do wszystkich wydarzeń</base-button>
			</div>
            </base-container>
        </section>
    </div>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'

import { pageMixin } from '~/website/front/core/pages/page.mixin'
export default {
	mixins: [pageMixin],
	components: {
		Breadrcumb,
		BaseContainer
	},
	computed: {
		eventValue () {
			return this.$app.page.event
		},
		startDate () {
			return new Date(this.eventValue.data.startDate).toLocaleDateString('pl-Pl')
		},
		endDate () {
			return new Date(this.eventValue.data.endDateSecondDate).toLocaleDateString('pl-Pl')
		},
		isEventTerminated () {
			if (this.eventValue.data.endDateSecondDate) {
				return new Date(this.eventValue.data.endDateSecondDate) < Date.now()
			}
			return new Date(this.eventValue.data.endDate) < Date.now()
		}

	},
	methods: {
		parseDate (date, options) {
			return new Date(date).toLocaleDateString('pl-Pl', options)
		},
		getDay (day) {
			return new Date(day).toLocaleDateString('pl-Pl', { weekday: 'long', hour: 'numeric', minute: 'numeric' })
		},
		getMinutes (date) {
			return `${new Date(date).getMinutes() < 10 ? '0' : ''}${new Date(date).getMinutes()}`.replaceAll(' ', '')
		}
	}
}
</script>

<style scoped lang="scss">

.buttons-wrapper {
	display: flex;
	flex-direction: column;
	gap: 2rem 2rem;

	@include media-breakpoint-up(md) {
        flex-direction: row;
    }
	@include media-breakpoint-up(xl) {
        gap: 2rem 3.3rem;
		margin-top: 3rem;
    }
}

.event-information {
    display: flex;
    flex-direction: column-reverse;
    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

	::v-deep &__description-title {
		@include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }
	}
	::v-deep &__description {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;

		&-wrapper {
			h2 {
				@include h2_styles()
			}
		}
        @include media-breakpoint-up(md) {
            align-items: normal;
            flex-direction: row;
        }

        &-wrapper{
            margin-top: 2rem;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            column-gap: 3rem;
            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(2, 1fr);
				align-items: flex-end;
            }
        }

        &-text{
            margin-top: 3rem;
            @include media-breakpoint-up(md) {
                margin-top: 0;
            }

            h4 {
                font-family: "Teko", sans-serif;
                font-size: 2.2rem;
                letter-spacing: 0.08em;
                font-weight: 500;
                line-height: 3.3rem;
                margin: 3rem 0;
                text-transform: uppercase;

                @include media-breakpoint-up(lg) {
                    font-size: 3rem;
                    line-height: 4.6rem;
                    margin: 2rem 0;
                }
            }
        }

		a {
			@include link_styles();
		}

	}
}
.event {
    &-intro {
        border-bottom: 1px solid $primary;
        margin-bottom: 3rem;
        @include media-breakpoint-up(xl) {
            margin-bottom: 6rem;
        }
    }
    &-intro-tags {
        display: flex;
        gap: 1rem;
        padding-bottom: 2rem;
        padding-top: 1rem;
        @include media-breakpoint-up(xl) {
            padding-top: 0;
        }
    }
}
::v-deep .image-wrapper .base-image {
    width: 100%;
    height: auto;
    max-width: 100%;
    margin-bottom: 3rem;
	border-radius: 1.3rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-left: 2rem;
        max-width: initial;
        width: auto;
    }
}
::v-deep .event-tag {
    padding: .5rem 1rem;
    background: $primary;
}
</style>
