import { getPath } from 'utils/getPath'

class RedirectionFront {
	to (alias, { params, query } = {}) {
		const path = getPath({
			app: window.app,
			to: alias,
			params,
			query,
			returnIfNotFound: false
		})

		if (!path) return false

		window.app.vue.$router.replace({ path })
	}
}

export { RedirectionFront }
