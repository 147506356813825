import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getArticle (alias) {
			const { data, status } = await this.http.get(`/page/${alias}/article?response=front`)
			if (status !== 200) return { error: true }
			return data
		}

		async getArticles ({ take, skip = 0, where } = {}) {
			const query = getQuery({
				order: { datePublished: 'DESC' },
				take,
				skip,
				where: {
					...where,
					page: {
						status: 'active',
						isPreview: false
					}
				},
				relations: ['author', 'page']
			})
			const { data, status } = await this.http.get(`/article${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getArticleCategories () {
			const { data, status } = await this.http.get('/article-category')

			if (status !== 200) return { error: true }
			return data
		}

		async getArticlesLength ({ where }) {
			const query = getQuery({
				where
			})
			const { data, status } = await this.http.get(`/article/numOfArticles${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async createArticleView (alias) {
			const { data, status } = await this.http.post(`/article/${alias}/view`)
			if (status !== 201) return { error: true }
			return data
		}

		async getMostReadArticles ({ timeInterval, limit }) {
			const query = getQuery({
				timeInterval,
				limit
			})
			const { data, status } = await this.http.post(`/article/most-read${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getRecommendedArticles (id) {
			const { data, status } = await this.http.get(`/article/${id}/recommended`)
			if (status !== 200) return { error: true }
			return data
		}
	}
}
