<template>
	<div
		class="buttons-wrapper"
		:style="{justifyContent: setAlignment}"
		:class="setPaddingClasses"
	>
		<base-button
			v-for="(button, i) in this.value['buttons-group']"
			:type="button.btn_type"
			:key="i"
			:to="isExternalLink(button.link) ? null : button.link"
			:icon="button.icon"
			:url="isExternalLink(button.link) ? button.link : null"
			:title="button.btn_title ? button.btn_title : `${capitalizeFirstLetter(button.btn_content)} we Wrocławskiej Szkole Tańca`"
		>
			<span>{{ button.btn_content }}</span>
		</base-button>
	</div>
</template>

<script>
import { capitalizeFirstLetter } from 'utils/addStrong'
import BaseButton from '../../components/BaseButton'
export default {
	components: {
		BaseButton
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		capitalizeFirstLetter,
		isExternalLink (value) {
			return !!value.includes('/')
		}
	},
	computed: {
		setAlignment () {
			if (this.value.alignment === 'right') {
				return 'flex-end'
			} else if (this.value.alignment === 'left') {
				return 'flex-start'
			} else if (this.value.alignment === 'center') {
				return 'center'
			} else {
				return 'center'
			}
		},

		setPaddingClasses () {
			if (!this.value.padding) return ''

			const classes = {}
			this.value.padding.forEach(item => { classes[`padding-${item}`] = true })
			return classes
		}
	}
}
</script>
<style lang="scss" scoped>
.buttons-wrapper {

	&.padding-bottom {
		padding-bottom: 3rem;
	}

	&.padding-top {
		padding-top: 3rem;
	}

	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
	@include media-breakpoint-up(xl) {
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
	}
	@include media-breakpoint-up(xxl) {
		justify-content: flex-start;
		gap: 3.3rem;
	}

	.btn {
		@include media-breakpoint-up(xl) {
			display: block;
		}
	}
}
</style>
