const mixin = {
	props: {
		input: Object
	},
	computed: {
		error () {
			if (!this.input.isInvalidAndTouched) return false
			return this.input.error
		}
	}
}

export default mixin
