const wyswigMode = {
	isOn: false,
	mode: null,
	run (mode) {
		this.isOn = true
		window.addEventListener('click', e => {
			e.stopPropagation()
			e.preventDefault()
		}, true)
		this.mode = mode
	},
	async setValue (value) {
		window.app.page.value = {}
		await window.app.vue.$nextTick()
		window.app.page.value = value
	}
}

export { wyswigMode }
