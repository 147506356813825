import { Page } from '~/cms/front/core/pages/Page'
import component from './PricingPage.vue'

class PricingPage extends Page {
	static alias = 'pricing'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('pricing')
	}

	component = component
}

export { PricingPage }
