<template>
	<div class="wrapper">
		<breadrcumb />
		<base-container>
			<div class="section-wrapper">
				<cms-multi-section value="content"/>
			</div>
		</base-container>
	</div>
</template>

<script>
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'

export default {
	components: { Breadrcumb, CmsMultiSection }

}
</script>
<style lang="scss" scoped>
::v-deep .grid-wrapper {
	grid-gap: 2rem 3rem;
}

::v-deep .section-wrapper.gallery-section {
	background-color: transparent;
	padding: 0 0 6rem 0;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		bottom: 3rem;
		left: 0;
		width: 100%;
		height: .1rem;
		background-color: $gray-200;
	}
}
::v-deep .section-wrapper.service-wrapper {
	padding-bottom: 0;

	.container {
		padding: 0;
	}
}
.section-wrapper {
    z-index: initial;
}

::v-deep .tab-content {
	.ql-editor {
		margin-bottom: 0;
	}
}
</style>
