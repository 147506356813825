<template>
	<section class="section-wrapper">
		<base-container>
				<event-date-card :value="getHighlightedEvents" />
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import EventDateCard from '../../components/EventDateCard.vue'
export default {
	components: {
		BaseContainer,
		EventDateCard
	},
	computed: {
		getHighlightedEvents () {
			return this.$app.page.events.highlighted
		},
		calendar () {
			return this.$app.page.calendar
		}
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
::v-deep .section-wrapper .event-wrapper {
	border: .1rem solid $gray-700;

	@include media-breakpoint-up(xl) {
            &:hover {
                border: .1rem solid $gray-700;
            }
    }
}
</style>
