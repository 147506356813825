<template>
	<section class="banner" :class="padding" v-if="value.container">
		<base-container>
			<base-link
				:title="value.link.title"
				:to="value.link.data.type"
				:params="value.link.data.params"
				class="banner-inner"
			>
				<cms-image
					class="banner-image"
					:value="`${prefix}.image`"
					lazy
					:base="{width: 575}"
					:md="{width: 992}"
					:lg="{width: 1200}"
					:xxl="{width: 1390}"
				>
					<cms-text
						v-if="value.text"
						class="banner-text"
						:value="`${prefix}.text.text`"
						:props="{variant: 'banner-title', tag: 'h2', color: value.text.text_color}"
					/>
				</cms-image>
			</base-link>
		</base-container>
	</section>

	<section class="banner" :class="padding" v-else>
		<base-link
			:title="value.link.title"
			:to="value.link.data.type"
			:params="value.link.data.params"
			class="banner-inner"
		>
			<cms-image
				class="banner-image"
				:value="`${prefix}.image`"
				lazy
				:base="{width: 575}"
				:md="{width: 992}"
				:lg="{width: 1200}"
				:xxl="{width: 1390}"
			>
				<cms-text
					v-if="value.text"
					class="banner-text"
					:value="`${prefix}.text.text`"
					:props="{variant: 'banner-title', tag: 'h2', color: value.text.text_color}"
				/>
			</cms-image>
		</base-link>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import { paddingMixin } from '@f/mixins/padding.mixin.js'
import BaseLink from '~/cms/front/components/BaseLink/BaseLink.vue'
export default {
	components: { BaseLink, BaseContainer },
	props: {
		prefix: String,
		value: Object
	},
	mixins: [paddingMixin]
}
</script>

<style lang="scss" scoped>
.banner {

	&-inner {
		display: block;
		@include transition;

		&:hover {
			transform: scale(1.025);
		}
	}

	&-image {
		position: relative;
		max-width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		:deep(img) {
			max-width: 100%;
		}
	}

	&-text {
		text-align: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		padding: 20px;
		width: fit-content;
		height: fit-content;
	}

}
</style>
