<template>
		<section class="section-wrapper" id="first-visit">
				<grid-template cols="2" :className="value.reverse ? 'reverse' : ''">
                    <template #title>
                        <base-heading tag="h1" :html="addStrong(value.section_title)" :subheading="`${prefix}.section_subtitle`" class="section-header"/>
                    </template>
					<div class="text-content">
						<cms-multi-section class="section-elements" :value="`${prefix}.section_content`" />
					</div>
					<image-wrapper class="img-wrapper">
						<cms-image :value="`${prefix}.character`"
							class="character"
							lazy
							:base="{width: 320}"
							:md="{width: 400}"
							:lg="{width: 400}"
							:xl="{ width: 570, height: 746}"
							preload
						/>
							<cms-image :value="`${prefix}.background`"
							class="background"
							lazy
							:base="{width: 900}"
							:md="{width: 690}"
							:xl="{width: 570,height: 337}"
							preload
						/>
					</image-wrapper>
				</grid-template>
		</section>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'
import { addStrong } from 'utils/addStrong.js'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'

export default {
	components: {
		CmsMultiSection,
		GridTemplate,
		BaseHeading,
		ImageWrapper
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		addStrong
	}
}
</script>
<style lang="scss" scoped>
::v-deep .text {
	p:nth-of-type(1) {
		margin-bottom: 3rem;
	}
}
::v-deep .section-header {
    margin-bottom: 4rem;
    @include media-breakpoint-up(xl) {
        margin-bottom: 6rem;
    }
}
::v-deep .img-wrapper {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin-top: 11rem;

	@include media-breakpoint-up(md) {
        margin-bottom: 6rem;
    }

	@include media-breakpoint-up(xl) {
        justify-content: flex-end;
    }
	.base-image {
		height: 100%;
		width: 100%;
		border-radius: $img-radius;
	}

	.character {
		position: absolute;
		bottom: 0rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: $level1;
		overflow: hidden;

		@include media-breakpoint-up(xl) {
			left: 50%;
		}

		@include media-breakpoint-up(xxl) {
			left: 59%;
		}

		.base-image {
			width: initial;
			height: initial;
			transform: translateY(11rem);
			transition: all .8s ease-in-out;

			@include media-breakpoint-up(xl) {
				transform: translateY(19rem);
			}
		}
	}

	.background-wrapper {
		border-radius: $img-radius;
	}

	.background {
		border-radius: $img-radius;
		width: 100%;
		height: 100%;
		min-height: 18.4rem;
		position: relative;
		overflow: hidden;
		max-height: 33.7rem;
		max-width: 33rem;
		box-shadow: 0px 3px 49px rgba(0, 0, 0, 0.16);

		@include media-breakpoint-up(md) {
			max-height: 20rem;
		}

		@include media-breakpoint-up(xl) {
			min-height: 33.7rem;
			max-width: 57rem;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			height: 107%;
			transition: all .8s ease-in-out;
			min-height: 100%;

			@include media-breakpoint-up(xl) {
				min-height: 33.7rem;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		&:hover {
			.background img {
				transform: translateY(-2rem);
			}

			.character .base-image {
				transform: translateY(17rem);
			}
		}
	}
}

::v-deep .grid-wrapper {
	grid-template-columns: auto;
	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-up(xl) {
		gap: 4rem;
	}
	@include media-breakpoint-up(xxl) {
		gap: 1rem;
	}
}

::v-deep .text-content .blockquote-wrapper {
	margin: 2.5rem 0 3rem 0;
}

</style>
