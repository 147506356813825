import { Page } from '~/cms/front/core/pages/Page'
import { InputEmail } from '@f/core/forms/inputs'
import { email } from 'utils/inputs/validation-front'
import { Form } from '@f/core/forms/Form'
import component from './RemindPassword.vue'

class RemindPasswordPage extends Page {
	static alias = 'remind-password'
	component = component

	status = ''
	form = false
	processing = false

	get translations () {
		return this.app.translator.get('forms.remindPassword')
	}

	async createForm () {
		await this.app.translator.prefetch('errors')
		await this.app.translator.prefetch('forms.remindPassword')

		this.form = new Form()
		this.form.addInput(InputEmail.create({
			translations: () => this.app.translator.get('forms.remindPassword.inputEmail'),
			attribute: 'email',
			name: 'email',
			validation: [email()]
		}))
	}

	timeoutRedirect () {
		setTimeout(function () {
			this.app.vue.redirection.to('homepage')
		}, 5000)
	}

	async remindPassword () {
		if (!await this.form.validate()) return false
		try {
			this.processing = true

			const { email } = this.form.getValues()
			const { error, alias } = await this.app.getService('rext').remindPassword(email)

			this.processing = false
			if (error) {
				this.status = {
					message: this.status = this.translations.status[alias],
					code: 'error'
				}
			} else {
				this.status = {
					message: this.status = this.translations.status.SEND_MAIL,
					code: 'correct'
				}
				this.timeoutRedirect()
			}
		} catch (err) {
			console.error(err)
			this.status = {
				message: this.translations.status.SERVER_ERROR,
				code: 'error'
			}
		}
	}
}

export { RemindPasswordPage }
