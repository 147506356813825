export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async sendNotFoundPage (path, fullPath) {
			const { data } = await this.http.post('/not-found', {
				path,
				fullPath
			})
			return data
		}
	}
}
