import { Page } from '~/cms/front/core/pages/Page'
import component from './SoloDancePage.vue'

class SoloDancePage extends Page {
	static alias = 'solo'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('solo')
	}

	component = component
}

export { SoloDancePage }
