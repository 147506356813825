import EventDate from '~/events/front/core/calendar/EventDate'

class Calendar {
	static async create (app) {
		const events = await this.fetchAll(app)
		const calendar = new Calendar(events)
		return calendar
	}

	static async fetchAll (app) {
		const service = app.getService('rext')
		const Event = app.getEntity('event')
		const events = await service.getAllEvents()
		return events.map(event => Event.createAndReturnInstance(event))
	}

	selectedDate
	dates
	events

	constructor (events) {
		this.events = events
		this.setActiveDate(new Date())
		this.initDates(new Date())
	}

	get eventsForSelectedDate () {
		const selectedDateInstance = this.dates.find(dateInstance => this.areDatesEqual(dateInstance.date, this.selectedDate))
		if (selectedDateInstance) return selectedDateInstance.events
		return []
	}

	AreEventsInDay () {
		const selectedDateInstance = this.dates.find(dateInstance => this.areDatesEqual(dateInstance.date, this.selectedDate))
		if (selectedDateInstance.events.length) return false
		return true
	}

	initDates (date) {
		const dates = this.getAllDaysInMonth(date)
		this.dates = dates.map(date => {
			const events = []
			if (!this.events) return new EventDate(date)
			this.events.forEach((event, i) => {
				if (this.areDatesEqual(new Date(event.data.startDate), date)) {
					events.push(event)
				}
			})
			if (events.length !== 0) return new EventDate(date, events)
			return new EventDate(date)
		})
	}

	areDatesEqual (a, b) {
		if (a && b) {
			const answer = new Date(a.getFullYear(), a.getMonth(), a.getDate()).getTime() === new Date(b.getFullYear(), b.getMonth(), b.getDate()).getTime()
			return answer
		}
		return false
	}

	getWeekdayAndMonth (date) {
		return {
			month: date.toLocaleString('default', { month: 'long' }),
			weekday: date.toLocaleString('default', { weekday: 'long' })
		}
	}

	setActiveDate (date) {
		this.selectedDate = date
	}

	getWeekday (date) {
		const days = { Monday: 0, Tuesday: 1, Wednesday: 2, Thursday: 3, Friday: 4, Saturday: 5, Sunday: 6 }
		const weekday = date.toLocaleString('en-GB', { weekday: 'long' })
		return days[weekday]
	}

	getAllDaysInMonth (date) {
		const lastMonthDays = []
		const currentMonthDays = []
		const nextMonthDays = []

		const firstWeekdayOfCurrentMonth = this.getWeekday(new Date(date.getFullYear(), date.getMonth(), 1))
		const maxDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()

		for (let i = 0; i < firstWeekdayOfCurrentMonth; i++) {
			lastMonthDays.push(new Date(date.getFullYear(), date.getMonth(), -i))
		}
		lastMonthDays.reverse()
		for (let i = 1; i <= maxDayOfCurrentMonth; i++) {
			currentMonthDays.push(new Date(date.getFullYear(), date.getMonth(), i))
		}

		const getNextMonthDaysValue = 42 - lastMonthDays.length - currentMonthDays.length

		for (let i = 1; i <= getNextMonthDaysValue; i++) {
			nextMonthDays.push(new Date(date.getFullYear(), date.getMonth() + 1, i))
		}
		return lastMonthDays.concat(currentMonthDays, nextMonthDays)
	}

	onArrowClick (i) {
		const monthNum = this.selectedDate.getMonth() + i
		let date
		if (monthNum < 0) {
			date = new Date((this.selectedDate.getFullYear() - 1), 11, this.selectedDate.getDate())
		} else if (monthNum > 11) {
			date = new Date((this.selectedDate.getFullYear() + 1), 0, this.selectedDate.getDate())
		} else {
			date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + i, this.selectedDate.getDate())
		}
		this.setActiveDate(date)
		this.initDates(date)
	}
}

export default Calendar
