<template>
        <section class="section-wrapper">
			<base-container>
			<cms-multi-section :value="`${prefix}.section_content`"/>
                <base-table  v-for="(item, index) in value.tables" :key="index" :item="item.table"/>
			</base-container>
        </section>
</template>
<script>
import BaseTable from '../../components/molecules/BaseTable/BaseTable.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'

export default {
	components: {
		BaseTable,
		CmsMultiSection
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding-bottom: 0;
}

</style>
