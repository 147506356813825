import Vue from 'vue'
import { AppLayout } from './Application.layout'
import { toPascalCase as tPC } from 'utils/strings'
import { Emitter } from '@f/core/Emitter'
import { AppService } from './Application.service'
import { AppVue } from './Application.vue'
import { AppUtils } from './Application.utils'
import { AppModal } from './Application.modal'
import { wyswigMode } from './wyswigMode'
import { AppSnackbar } from './Application.snackbar'

const EXTENDED_CLASSES = [
	['service', 'services'],
	['layout', 'layouts'],
	['page', 'pages'],
	['entity', 'entities']
]

class Application extends Emitter {
	modules = []
	notifications = []
	prompt = false
	wyswig = wyswigMode
	page
	layout
	modal
	snackbar
	utils = AppUtils
	settings = false
	getSettingsCallbacks = []

	initReactive () {
		Vue.observable(this)
	}

	use (module) {
		this.modules.push(module)
	}

	init () {
		this.runLifecycle('beforeInitParts')
		EXTENDED_CLASSES.forEach((part) => {
			this.initPart(part)
		})
		this.runLifecycle('beforeInitReactive')
		this.initReactive()
		this.runLifecycle('created')
		this.loadSettings()
	}

	initPart ([singular, plural]) {
		this[plural] = {}

		this[`add${tPC(singular)}`] = (alias, element) => {
			if (typeof alias !== 'string') {
				element = alias
				alias = element.alias
			}
			if (this[plural][alias]) console.warn(`${tPC(singular)} with alias '${alias}' already exist!`)
			this[plural][alias] = element
		}

		this.runLifecycle(`readyToAdd${tPC(plural)}`)

		this[`get${tPC(singular)}`] = (alias) => {
			const Class = this[plural][alias]
			if (!Class) return false
			Class.app = this
			return Class
		}
		this[`get${tPC(plural)}`] = () => {
			return Object.values(this[plural])
		}
		this[`extend${tPC(singular)}`] = (alias, extendFunction) => {
			const element = this[`get${tPC(singular)}`](alias)
			this[plural][alias] = extendFunction(element)
		}

		this.runLifecycle(`readyToExtend${tPC(plural)}`)
		this.runLifecycle(`create${tPC(plural)}`)
		this.runLifecycle(`after${tPC(plural)}Created`)
	}

	runLifecycle (cycle) {
		if (this[cycle]) this[cycle]()
		this.modules.forEach((module) => {
			if (module[cycle]) module[cycle](this)
		})
	}

	async loadSettings () {
		if (process.client) {
			this.settings = window.__SETTINGS__
			return false
		}
		const service = this.getService('rext')

		const { data, status } = await service.http.get('/setting')

		if (status !== 200) return { error: true }

		this.settings = {}
		Object.keys(data).forEach((setting) => {
			this.settings[setting] = data[setting]
		})

		this.getSettingsCallbacks.map(cb => cb(this.settings))
	}

	getSettings () {
		if (this.settings) return this.settings
		return new Promise(resolve => this.getSettingsCallbacks.push(resolve))
	}
}

Object.assign(Application.prototype, AppService, AppLayout, AppVue, AppModal, AppSnackbar)

export {
	Application
}
