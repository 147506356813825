<template>
	<div class="section-wrapper">
		<base-container>
			<grid-template cols="2" className="dancing-benefits" :container="false">
				<base-benefit v-for="(item, index) in value.benefits" :key="index" :benefit="item"/>
			</grid-template>
			<base-button type="primary" class="map-button" :url="value.button_link" title="Zorganizuj Panieński w Szkole Tańca Wrocław">{{ value.button_title }}</base-button>
		</base-container>
	</div>
</template>

<script>
import BaseBenefit from '~/website/front/components/atoms/BaseBenefit/BaseBenefit.vue'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'

export default {
	components: { BaseBenefit, GridTemplate },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
::v-deep .dancing-benefits {
	grid-template-columns: auto;
	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(2, 1fr);
    }
}

.btn-primary {
	display: block;
	margin: 3rem auto 0rem auto;
	padding: 1.7rem 2.5rem;
	width: fit-content;

	@include media-breakpoint-up(sm) {
        padding: 1.7rem 4rem;
    }

	@include media-breakpoint-up(xl) {
        margin: 6rem auto 0 auto;
    }
}
</style>
