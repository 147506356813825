<template>
	<div class="wrapper">
		<base-article-photo v-for="(item, id) in value.section" :key="id" :articlePhoto="item"/>
	</div>
</template>

<script>
import BaseArticlePhoto from '../../components/molecules/BaseArticlePhoto/BaseArticlePhoto.vue'
export default {
	components: { BaseArticlePhoto },
	computed: {},
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>

</style>
