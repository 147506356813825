import { Page } from '~/cms/front/core/pages/Page'
import component from './PairsPage.vue'

class PairsPage extends Page {
	static alias = 'pairs'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('pairs')
	}

	component = component
}

export { PairsPage }
