<template>
  <section class="section-wrapper">
		<div class="heading">
			<div class="heading__step">
				{{ value.important_title }}
			</div>
			<h2 class="heading__title">{{ value.important_subtitle }}</h2>
		</div>
		<cms-multi-section :value="`${prefix}.section_content`" />
  </section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
export default {
	components: { CmsMultiSection },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.heading {
	display: flex;
	flex-direction: column;
	gap: 2rem 3rem;
	align-items: center;
	margin-bottom: 2rem;

	@include media-breakpoint-up(md) {
		flex-direction: row;
		margin-bottom: 5rem;
	}

	&__step {
		font-family: $secondary-font;
		padding: 1.3rem 4rem;
		line-height: 4.3rem;
		text-transform: uppercase;
		font-size: 3rem;
		letter-spacing: 2.4px;
		background-color: $primary;
		color: $white;
		border-radius: .4rem;
		font-weight: 500;
	}

	&__title {
		font-family: $secondary-font;
		font-size: 3rem;
		line-height: 4.3rem;
		letter-spacing: 2.4px;
		text-transform: uppercase;
		font-weight: 500;
		text-align: center;
	}
}
::v-deep .text {
	margin-bottom: 2rem;
}

#app  .section-wrapper {
	padding-top: 0;
	padding-bottom: 6rem;

	&:last-of-type {
		padding-bottom: 6rem;

		@include media-breakpoint-up(xl) {
			padding-bottom: 12rem;
		}
	}
	::v-deep .buttons-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0rem;
		}
	}

	::v-deep h2 {
		margin-top: 2rem;

		@include media-breakpoint-up(lg) {
			margin-top: 4rem;
		}

		&:nth-of-type(1) {
			margin-top: 0;
		}
	}

	::v-deep .text {
		margin-bottom: 0;
	}
}

::v-deep .features__description .ql-editor{
	margin-bottom: 0;

	p:last-of-type {
		margin-bottom: 0;
	}
}

::v-deep .features {
	.wrapper {
		gap: 0;
	}
}
</style>
