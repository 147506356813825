<template>
<div class="wrapper" v-if="features">
	<div class="features" v-for="( item , index) in features" :key="index">
			<grid-template cols="6" className="grid-wrapper">
				<image-wrapper class="img-wrapper">
					<cms-image :src="item.features_image"
						lazy
						:base="{width: 318}"
						:xl="{width: 690}"
					/>
				</image-wrapper>
				<div class="features__content">
					<base-font variant="h3" color="black" class="features__title" v-if="item.features_title">{{ item.features_title }}</base-font>
					<base-font variant="paragraph" color="black" class="features__description" :html="item.features_description" />
				</div>
			</grid-template>
		</div>
</div>
</template>

<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'

export default {
	components: { GridTemplate, BaseFont, ImageWrapper },
	props: {
		features: {
			type: Array
		}
	}
}
</script>

<style lang="scss" scoped>
#app .wrapper {
	display: grid;
	gap: 3rem 0;
	margin-top: 2rem;
	margin-bottom: 0;
}
::v-deep .features {
	.grid-wrapper {
		gap: 2rem 2rem;
		grid-template-columns: auto;

		@include media-breakpoint-up(sm) {
			grid-template-columns: auto;
		}

		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(2,auto);
			align-items: center;
			gap: 0 3rem;
		}
		@include media-breakpoint-up(xl) {
			gap: 3rem 15rem;
		}

		.img-wrapper {
			margin: 0 auto;

		}
	}

&__content {
	display: flex;
	flex-direction: column;

		@include media-breakpoint-up(xl) {
			justify-content: center;
		}
	}
}
</style>
