<template>
		<div class="logo-wrapper" :class="{'show-menu': scrollDirection === 'down'}">
			<base-link class="logo-link" to="homepage">
				<base-svg name="logo_color" class="navbar-logo"/>
			</base-link>
			<button
				@click.stop="$emit('menuButtonClick')"
				class="mobile-button"
			>
				<div class="mobile-button-box">
					<span class="mobile-button-inner-wrapper"></span>
				</div>
			</button>
		</div>
</template>
<script>

export default {
	data: () => ({
		scrollPosition: 0,
		scrollDirection: 'down'
	}),
	props: {
		activeMobileNav: {
			type: Boolean
		}
	},
	methods: {
		toggleMenu (e) {
			const distanceFromTop = window.scrollY
			if (this.scrollPosition < 200) {
				this.scrollDirection = 'down'
				this.scrollPosition = distanceFromTop
			} else if (!this.activeMobileNav && this.$app.utils.mobile) {
				this.scrollDirection = distanceFromTop > this.scrollPosition ? 'up' : 'down'
				this.scrollPosition = distanceFromTop
			}
		}
	},
	mounted () {
		window.addEventListener('scroll', this.toggleMenu)
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.toggleMenu)
	}
}
</script>
<style lang="scss" scoped>
.logo-wrapper {
    position: relative;
	width: 100vw;
	height: 56px;
	z-index: 1;
	display: flex;
	justify-content: space-between;
	z-index: $level2;
    background: $menu-mobile;
    backdrop-filter: blur(5px);
	@include media-breakpoint-up(xl) {
		position: absolute;
        height: 90px;
	}
    @media (min-width:2000px) {
        height: 91px;
    }

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100%;
		background: $white;
		z-index: -1;
		box-shadow: 0 3px 15px rgba($black, 0.2);
	}
}
	.logo-link {
		position: relative;
        width: 150px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: $gray-500;
        @include media-breakpoint-up(xl) {
            width: 150px;
            height: 100px;
        }
        @include media-breakpoint-up(xxl) {
            width: 250px;
            height: 100px;
        }
        .svg-icon {
            width: 95px;
            height: 35px;
            @include media-breakpoint-up(xl) {
                width: 120px;
                height: 45px;
            }
            @include media-breakpoint-up(xxl) {
                width: 250px;
                height: 55px;
            }
        }
        &::after {
            content: '';
            position: absolute;
            bottom:-4px;
            left: 0;
            height: 4px;
            width: 100%;
            background: $primary;
        }
	}
.mobile-button {
	position: absolute;
	top: 20px;
	right: 35px;
	display: inline-block;
	margin: 0;
	cursor: pointer;
	transition: opacity, filter .3s linear;
	border: 0;
	outline: none;
	background-color: transparent;
	-webkit-tap-highlight-color: transparent;
	@include media-breakpoint-up(xl) {
		display: none;
	}
	&-box {
		position: relative;
		display: inline-block;
		width: 19px;
		height: 16px;
	}
	&-inner-wrapper {
		top: -1px;
		&,
		&::before,
		&::after {
			position: absolute;
			width: 19px;
			height: 2px;
			transition: transform .3s ease;
			background-color: $white;
		}
		&::before,
		&::after {
			display: block;
			content: '';
		}
		&::after {
			top: 14px;
		}
		&::before {
			top: 7px;
			transition: transform, opacity .3s ease;
		}
	}
}

</style>
