<template>
	<base-blockquote :title="value.blockquote_section_title" :description="value.blockquote_section_description"/>
</template>

<script>
import BaseBlockquote from '../../components/atoms/BaseBlockquote/BaseBlockquote.vue'

export default {
	components: { BaseBlockquote },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
