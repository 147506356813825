<template>
	<div class="buttons-wrapper">
		<base-button
			v-for="(button, i) in getValue['buttons-group']"
			:type="button.btn_type"
			:key="i"
			:to="isExternalLink(button.link) ? null : button.link"
			:url="isExternalLink(button.link) ? button.link : null"
			:icon="button.icon"
		>
			<span>{{ button.btn_content }}</span>
		</base-button>
	</div>
</template>

<script>
import { getValue } from 'utils/objects'
export default {
	components: {
	},
	props: {
		value: String
	},
	methods: {
		isExternalLink (value) {
			if (value.includes('/')) return true
			return false
		}
	},
	computed: {
		elements () {
			const value = this.$app.page.value

			return getValue(this.value, value, 'none')
		},
		getValue () {
			return this.elements[0].value.section_content[0].value
		}
	}
}
</script>
<style lang="scss" scoped>
.buttons-wrapper {
	display: flex;
	flex-direction: column;
    gap: 2rem;
    max-width: 315px;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        flex-direction: row;
        max-width: 100%;
        justify-content: center;
    }
    @include media-breakpoint-up(xl) {
        display: none;
    }
}
</style>
