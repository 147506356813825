const dateFilter = value => {
	const createPartOfDate = num => num < 10 ? `0${num}` : num

	const date = new Date(value)
	const day = createPartOfDate(date.getDate())
	const month = createPartOfDate(date.getMonth() + 1)
	const year = date.getFullYear()
	return `${day}.${month}.${year}`
}

export {
	dateFilter
}
