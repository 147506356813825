<template>
	<base-container>
		<div>
			<div>
				<base-font variant="section-header">{{translations.title}}</base-font>
				<info-box v-if="!!page.status" :status="page.status.code">
					{{page.status.message}}
				</info-box>
				<form v-if="form" class="form">
					<div class="form-inner-wrapper">
						<input-template wider :input="form.inputs.newPassword" autocomplete="email" />
					</div>

					<button-submit @click="page.changePassword()" :loading="page.processing" type="secondary" :rightSlot="true" class="btn-wider">
						<base-font color="white" tag="span">{{translations.submitButton}}</base-font>
					</button-submit>
				</form>
			</div>
		</div>
	</base-container>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/buttons/ButtonSubmit'
import { InputTemplate } from '@f/core/forms/inputs'
import InfoBox from '~/authorization/front/components/InfoBox'

export default {
	components: {
		ButtonSubmit,
		InputTemplate,
		InfoBox
	},
	computed: {
		page () {
			return this.$app.page
		},
		form () {
			return this.page.form
		},
		translations () {
			return this.$app.translator.get('forms.changePassword')
		}
	},
	async mounted () {
		await this.page.createForm()
	}
}
</script>
<style lang="scss" scoped>
.form {
	& ::v-deep label {
		color: $primary;
	}
}
::v-deep .btn-wider {
	.btn {
		width: 100%;
	}
}
</style>
