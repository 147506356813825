const AppUtils = {
	get mobile () {
		if (!process.client) return
		return window.innerWidth < 1200
	},
	get tabletOrDesktop () {
		if (!process.client) return
		return window.innerWidth >= 768
	}
}

export {
	AppUtils
}
