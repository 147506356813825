<template functional>
	<component
		v-if="props.html"
		:is="$options.tag(props)"
		:ref="data.ref"
		:class="$options.classes(props, data)"
		:style="[data.style, data.staticStyle]"
		v-bind="data.attrs"
		v-on="listeners"
		v-html="$options.cut(props)"
	/>
	<component
		v-else
		:is="$options.tag(props)"
		:ref="data.ref"
		:class="$options.classes(props, data)"
		:style="[data.style, data.staticStyle]"
		v-bind="data.attrs"
		v-on="listeners"
	>
		<slot />
	</component>
</template>

<script>
import BaseFont from '@f/components/BaseFont'
export const tags = ['', 'div', 'a', 'p', 'span', 'label', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']
export const colors = ['primary', 'black', 'white', 'gold', 'red', 'offer-header']
export const fontFamily = ['primary', 'secondary']
export const weights = ['', 'bold', 'medium', 'regular', 'semibold']
export const sizes = ['event-desc', 'event-tag', 'offer-header', 'instructor-header', 'service-size', 'price', 'date', 'menu', 'label', 'xs', 'sm', 'md', 'lg', 'cta', 'hero-medium', 'hero', 'h1', 'subheading', 'h2', 'h3', 'paragraph', 'accordion-title', 'number-title', 'section-italic', 'menu-mobile', 'footer-header', 'footer-tile', 'instructor']
export const variants = {
	'offer-header': {
		color: 'offer-header',
		tag: 'h2',
		size: 'offer-header',
		family: 'secondary'
	},
	'small-heading': {
		color: 'red',
		size: 'md',
		family: 'primary',
		tag: 'h4',
		uppercase: true,
		weight: 'bold'
	},
	heading: {
		color: 'black',
		tag: 'h1',
		size: 'h1',
		family: 'secondary'
	},
	instructor: {
		color: 'black',
		tag: 'span',
		size: 'instructor',
		family: 'primary'
	},
	'footer-header': {
		color: 'white',
		tag: 'h3',
		size: 'footer-header',
		family: 'primary'
	},
	h2: {
		color: 'black',
		tag: 'h2',
		size: 'h2',
		family: 'secondary'
	},
	h3: {
		color: 'black',
		tag: 'h3',
		size: 'h3',
		family: 'primary'
	},
	p: {
		color: 'black',
		tag: 'p',
		size: 'p',
		family: 'primary'
	},
	subheading: {
		color: 'black',
		tag: 'span',
		size: 'subheading',
		family: 'primary'
	},
	menu: {
		color: 'white',
		tag: 'span',
		size: 'menu',
		weight: 'medium',
		family: 'primary'
	},
	'menu-mobile': {
		color: 'white',
		tag: 'span',
		size: 'menu-mobile',
		weight: 'medium',
		family: 'primary'
	},
	small: {
		color: 'primary',
		tag: 'span',
		size: 'date',
		family: 'primary'
	},
	price: {
		color: 'primary',
		tag: 'span',
		size: 'price',
		family: 'secondary'
	},
	paragraph: {
		color: 'gold',
		tag: 'p',
		size: 'paragraph',
		family: 'primary'
	},
	label: {
		color: 'black',
		tag: 'label',
		size: 'label',
		weight: 'bold',
		family: 'primary'
	},
	'small-header': {
		color: 'black',
		size: 'sm',
		weight: 'bold',
		family: 'primary',
		tag: 'h3'
	},
	'medium-header': {
		color: 'primary',
		tag: 'h2',
		size: 'md',
		family: 'secondary'
	},
	'section-header': {
		color: 'primary',
		size: 'lg',
		tag: 'h1',
		family: 'secondary'
	},
	cta: {
		color: 'white',
		tag: 'h3',
		size: 'cta',
		family: 'secondary'
	},
	'hero-header-medium': {
		color: 'white',
		tag: 'h3',
		size: 'hero-medium',
		family: 'secondary'
	},
	'hero-header': {
		color: 'white',
		tag: 'h2',
		size: 'hero',
		family: 'secondary'
	},
	'accordion-title': {
		color: 'white',
		tag: 'p',
		size: 'accordion-title',
		family: 'primary'
	},
	'number-title': {
		color: 'black',
		tag: 'span',
		size: 'number-title',
		family: 'secondary'
	},
	'number-subtitle': {
		color: 'primary',
		tag: 'p',
		size: 'number-subtitle',
		family: 'primary'
	},
	'section-italic': {
		color: 'black',
		tag: 'p',
		size: 'section-italic',
		family: 'primary'
	},
	'footer-tile': {
		color: 'black',
		tag: 'p',
		size: 'footer-tile',
		family: 'primary'
	},
	'service-title': {
		color: 'red',
		tag: 'h3',
		size: 'service-size',
		family: 'secondary'
	},
	'banner-title': {
		uppercase: true,
		size: 'h1',
		weight: 'bold',
		family: 'primary'
	}
}

export default {
	extends: BaseFont,
	name: 'BaseFont',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.fs {
	:deep(p) {
		word-break: break-word;
	}
    &-event-tag {
        font-size: 1.5rem;
		letter-spacing: 0.03em;
		line-height: 2.6rem;
        font-weight: 500;
    }
    &-service-size{
        font-size: 2.2rem;
        letter-spacing: 0.08em;
        line-height: 3.3rem;
        @include media-breakpoint-up(sm) {
            font-size: 1.8rem;
            line-height: 2rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 2.4rem;
            line-height: 3.6rem;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 3rem;
            line-height: 4.6rem;
        }
    }
	&-h1{
		font-size: 2.8rem;
		letter-spacing: 0.08em;
		line-height: 3rem;
		color: $black;
		font-weight: 500;
		text-transform: uppercase;
		&::v-deep .strong {
			color: $primary;
		}

		@include media-breakpoint-up(lg) {
			font-size: 5.4rem;
			line-height: 8.1rem;
			color: $black;
		}
	}

	&-h2{
		font-size: 2.2rem;
		letter-spacing: 0.08em;
		font-weight: 500;
		line-height: 3.3rem;
		margin-bottom: 3rem;
		text-transform: uppercase;
		@include media-breakpoint-up(lg) {
			font-size: 3rem;
			line-height: 4.6rem;
			margin-bottom: 4rem;
		}
	}

	&-h3{
		font-size: 1.4rem;
		font-weight: bold;
		letter-spacing: 0.06em;
		line-height: 2.4rem;
		margin-bottom: 3rem;
		text-transform: uppercase;

		@include media-breakpoint-up(lg) {
			font-size: 18px;
		}
	}

	&-paragraph{
		margin-bottom: 3rem;
		font-size: 1.4rem;
		letter-spacing: 0.04em;
		line-height: 2.2rem;
		text-transform: none;
		font-weight: 400;

		@include media-breakpoint-up(lg) {
			font-size: 1.6rem;
			line-height: 2.8rem;
		}
	}

	&-accordion-title {
		font-size: 1.6rem;
		text-transform: uppercase;
		letter-spacing: 0.08em;
		line-height: 2.2rem;

		@include media-breakpoint-up(lg) {
			font-size: 1.8rem;
		}
	}

	&-subheading {
		text-transform: uppercase;
		font-size: 1.4rem;
		letter-spacing: 0.04em;
		line-height: 1.7rem;
		display: flex;
		align-items: center;
		margin-top: .5rem;

		@include media-breakpoint-up(lg) {
			font-size: 2rem;
			line-height: 2.4rem;
			margin-top: 0;
		}

		&::before{
			content: "";
			display: inline-block;
			width: 1.2rem;
			height: .1rem;
			background-color: $black;
			margin-right: 1rem;

			@include media-breakpoint-up(lg) {
				width: 2rem;
			}
		}
        &.fc-white {
            &::before {
                background-color: $white;
            }
        }

	}

	&-number-title {
		font-size: 7rem;
		text-transform: uppercase;
		letter-spacing: 0.06em;
		line-height: 7rem;
		font-weight: 600;

		@include media-breakpoint-up(xl) {
			font-size: 10rem;
			line-height: 12rem;
		}
	}

	&-number-subtitle {
		text-transform: uppercase;
		font-size: 1.4rem;
		font-weight: bold;
		line-height: 2.4rem;
		letter-spacing: 0.06em;

		@include media-breakpoint-up(xl) {
			font-size: 1.8rem;
			margin-top: 1rem;
		}
	}

	&-section-italic {
		font-size: 1.8rem;
		font-style: italic;
		letter-spacing: 0.04em;
		line-height: 2.8rem;
		font-weight: 200;
		text-transform: uppercase;
		color: $black;

		@include media-breakpoint-up(lg) {
			font-size: 2.5rem;
		}
	}

	&-hero {
		font-size: 4.3rem;
		line-height: 4.5rem;
		letter-spacing: 0.03em;
		font-weight: normal;
		@include media-breakpoint-up(md) {
			font-size: 5.5rem;
			line-height: 8.3rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 7rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 9rem;
			line-height: 13.3rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 10rem;
		}
		&-medium {
			font-size: 2.4rem;
			line-height: 2.5rem;
			letter-spacing: 0.03em;
			font-weight: normal;
			@include media-breakpoint-up(md) {
				font-size: 3.2rem;
				line-height: 3.6rem;
			}
			@include media-breakpoint-up(xl) {
				font-size: 4.2rem;
				line-height: 5.6rem;
			}
		}
	}
    &-instructor {
        font-size: 1.2rem;
		text-align: center;
        letter-spacing: 0.04em;
        line-height: 1.4rem;
        opacity: .4;
        @include media-breakpoint-up(md) {
            line-height: 1.6rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
    &-instructor-header {
        font-size: 9px;
        letter-spacing: 0.1em;
        line-height: 1.1rem;
        @include media-breakpoint-up(md) {
            font-size: 1rem;
            line-height: 1.7rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 1.4rem;
            line-height: 1.7rem;
        }
    }
	&-cta {
		font-size: 2.6rem;
		line-height: 3.5rem;
		letter-spacing: 0.03em;
		font-weight: 400;
		@include media-breakpoint-up(md) {
			font-size: 5.4rem;
			line-height: 6.6rem;
			margin-bottom: 2rem;
		}
		@include media-breakpoint-up(xl){
			font-size: 6.2rem;
			line-height: 7.6rem;
			margin-bottom: 3rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 8rem;
			line-height: 10.6rem;
			margin-bottom: 4rem;
		}
	}
	&-lg {
		position: relative;
		display: inline-block;
		font-weight: normal;
		font-size: 2.6rem;
		line-height: 3.5rem;
		letter-spacing: 0.00em;
		z-index: 2;
		margin-bottom: 4rem;
		overflow: hidden;

		@include media-breakpoint-up(md) {
			font-size: 3.6rem;
			line-height: 4.2rem;
			margin-bottom: 6rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 5rem;
			line-height: 6.7rem;
			margin-bottom: 8rem;

		}
	}
	&-md {
		font-size: 2.4rem;
		line-height: 3rem;
		letter-spacing: 0.03em;
		font-weight: normal;
		@include media-breakpoint-up(md) {
			font-size: 2.6rem;
			margin-bottom: 1.5rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 3rem;
			margin-bottom: 3rem;
		}
	}
	&-sm {
		font-size: 1.6rem;
		line-height: 2rem;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		@include media-breakpoint-up(md) {
			font-size: 1.8rem;
			line-height: 2.5rem;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 2rem;
		}
	}
	&-xs {
		font-size: 1.4rem;
		line-height: 2.4rem;
		letter-spacing: 0.03em;
		@include media-breakpoint-up(md) {
			font-size: 1.6rem;
			line-height: 3rem;
		}
	}
	&-label {
		font-size: 1.4rem;
		font-weight: bold;
		letter-spacing: 0.04em;
		line-height: 2.8rem;
		color: $black;
		@include media-breakpoint-up(lg) {
			font-size: 1.6rem;
		}
	}
	&-price {
		font-size: 1.8rem;
		line-height: 3.2rem;
		letter-spacing: 0.0em;
		@include media-breakpoint-up(md) {
			font-size: 2rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 2.4rem;
		}
	}
	&-date {
		font-size: 1rem;
		line-height: 1rem;
		letter-spacing: 0.06em;
	}
	&-menu {
		font-size: 1.4rem;
		line-height: 1.9rem;
		letter-spacing: 0.02em;
		text-transform: uppercase;
        text-decoration: none;
		@include media-breakpoint-up(xl) {
			font-size: 1.3rem;
            line-height: 1.6rem;
            letter-spacing: 0.12em;
		}
	}
    &-menu-mobile {
        font-size: 1.4rem;
		line-height: 1.9rem;
		letter-spacing: 0.02em;
		text-transform: uppercase;
        text-decoration: none;
		@include media-breakpoint-up(xl) {
			font-size: 1.3rem;
            line-height: 1.6rem;
            letter-spacing: 0.12em;
		}
    }
	&-footer-header {
		font-size: 1.4rem;
		line-height: 1.7rem;
		letter-spacing: 0.08em;
		text-transform: uppercase;
        text-decoration: none;
		@include media-breakpoint-up(xl) {
			font-size: 1.8rem;
            line-height: 2.2rem;
            letter-spacing: 0.08em;
		}
	}
    &-footer-tile {
		font-size: 1.4rem;
		letter-spacing: 0.08em;
		line-height: 1.7rem;
		text-transform: none;
		font-weight: 700;

		@include media-breakpoint-up(xl) {
			font-size: 1.8rem;
			line-height: 2.2rem;
		}
    }
    &-event-desc {
		font-size: 1.4rem;
		letter-spacing: 0.04em;
		line-height: 1.7rem;
		text-transform: none;
		font-weight: 500;

		@include media-breakpoint-up(xl) {
			font-size: 1.8rem;
			line-height: 2.2rem;
		}
    }
    &-offer-header {
        font-size: 2.8rem;
		letter-spacing: 0.08em;
		line-height: 3rem;
		text-transform: none;
		font-weight: 500;

        @include media-breakpoint-up(md) {
			font-size: 2rem;
			line-height: 2.2rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 3rem;
			line-height: 4.6rem;
		}
    }
}
.ff {
	&-primary {
		font-family: $primary-font;
	}
	&-secondary {
		font-family: $secondary-font;
	}
}
.fc {

	&-primary {
		color: $primary;
	}
	&-black{
		color: $secondary;
	}
	&-white {
		color: $white;
	}
	&-gold {
		color: $secondary;
	}
    &-red {
        color: $red-300;
    }
    &-offer-header {
        color: $white;
        @include media-breakpoint-up(xl) {
            color: $black;
        }
    }
}

.fuc {
	text-transform: uppercase;
}
.fi {
	font-style: italic;
}
.fw {
	&-bold {
		font-weight: bold;
	}
	&-medium {
		font-weight: 500;
	}
	&-regular {
		font-weight: normal;
	}
    &-semibold {
        font-weight: 600;
    }
}
.mr{
	margin-block: 0!important;
}

</style>
