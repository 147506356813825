<script>
import BaseImage from '@f/components/BaseImage/BaseImage'

export default {
	extends: BaseImage,
	name: 'BaseImage',
	globalComponent: true
}
</script>

<style scoped lang="scss">

</style>
