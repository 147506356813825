const uvue = {
	plugins: [
		'@uvue/core/plugins/prefetch',
		'@uvue/core/plugins/asyncData',
		'@uvue/core/plugins/middlewares',
		'@uvue/core/plugins/errorHandler',
		'~/cms/front/plugins/fetchPages',
		'~/seo/front/plugins/redirect',
		'@f/plugins/contextPlugins'
	]
}

export default uvue
