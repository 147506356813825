import { Input } from './Input'
import InputTextComponent from '~/website/front/components/molecules/fileds/FieldText'

class InputText extends Input {
	component = InputTextComponent
	_mask = false
	label = this.labelValue
	eventFunction

	constructor ({ mask = false, linkedElement, eventFunction, ...data }) {
		super(data)
		this._mask = mask
		this.eventFunction = eventFunction
	}

	get labelValue () {
		const { attribute } = this
		if (attribute === 'phoneNumber') return 'Podaj numer telefonu*'
		return 'Podaj imię*'
	}

	get mask () {
		if (typeof this._mask === 'function') return this._mask()
		return this._mask
	}
}

export { InputText }
