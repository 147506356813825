<template>
    <base-link :to="isHomepage ? service.service_link : '' " class="service-wrapper" @click="isHomepage ? null : scrollTo()" :title="`${capitalizeFirstLetter(service.service_title)} we Wrocławskiej Szkole Tańca`">
        <cms-image
            class="service-image"
			:src="service.service_image"
			:base="{width: 400, height: 120}"
			:sm="{width: 400, height: 250}"
			:lg="{width: 600, height: 350}"
			:xl="{width: 330, height: 420, position: 'entropy'}"
            :xxl="{width: 330, height: 420, position: service.service_title.toLowerCase() === 'wydarzenia' ? 'entropy' : 'left'}"
			v-if="isHomepage"
        />
        <base-font variant="service-title" class="service-title">{{service.service_title}}</base-font>
    </base-link>
</template>

<script>
import { capitalizeFirstLetter } from 'utils/addStrong'
export default {
	props: {
		service: Object
	},
	computed: {
		getName () {
			return this.service.service_title.split(' ').join('_').toLowerCase()
		},
		isHomepage () {
			return this.$app.page.route.meta.alias === 'homepage'
		}
	},
	methods: {
		capitalizeFirstLetter,
		scrollTo () {
			const scrollSection = this.$root.$refs[this.getName]
			const sectionParametr = scrollSection.getBoundingClientRect()
			const getDistance = sectionParametr.top + window.scrollY - (this.isMobile() ? 90 : 120)
			scrollTo({
				top: getDistance,
				left: 0,
				behavior: 'smooth'
			})
		},
		isMobile () {
			if (process.client) {
				return window.innerWidth <= 992
			} else {
				return false
			}
		}
	}

}
</script>
<style lang="scss" scoped>
.service {
    &-wrapper {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid $gray-100;
        @include transition;
		min-height: 10rem;
		max-height: 10rem;
		background-color: $white;

		@include media-breakpoint-up(lg) {
            max-height: initial;
        }
        ::v-deep .svg-icon.arrow-down {
            width: 25px;
            height: 25px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 5rem;
            opacity: 0;
            @include transition;
            path {
                fill: $primary;
            }
        }
    }
    ::v-deep &-image {
        position: relative;
		height: 100%;
        &::before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            background: $white;
            opacity: .7;
            height: 100%;
            width: 100%;
        }
        .base-image {
            max-width: 100%;
            display: flex;
			height: 100%;
			width: 100%;
            @include media-breakpoint-up(lg) {
                width: 100%;
            }
        }
    }
    ::v-deep &-title {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        font-weight: 500;
        @include transition;

    }
    @include media-breakpoint-up(xl) {
        &-wrapper:hover {
            border: 1px solid $red-300;
            ::v-deep .service-title {
                transform: translate(-50%, -70%)
            }
            ::v-deep .svg-icon.arrow-down {
                opacity: 1;
                animation: swipe .4s linear infinite alternate;
            }
        }
    }
}

@keyframes swipe {
    0% {
        transform: translate(-50%, 0)
    }
    100% {
        transform: translate(-50%, 10px);
    }
}
</style>
