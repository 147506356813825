<script>
import BaseLink from '~/cms/front/components/BaseLink'
export default {
	extends: BaseLink,
	name: 'BaseLink',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.link {
	font-family: $primary-font;
	font-size: 1.4rem;
	line-height: 2.2rem;
	letter-spacing: 0.04em;
	font-weight: bold;
	@include transition;

	@include media-breakpoint-up(lg) {
		font-size: 1.6rem;
		line-height: 2.8rem;
	}

	&.underline{
		text-decoration: underline;
	}
    &.no-underline {
        text-decoration: none;
    }

	&-primary{
		color: $primary;
		will-change: opacity;
	}
	&-footer {
		color: $black;
	}
    &-footer-envelope {
        font-size: 1.8rem;
        letter-spacing: 0.08em;
        line-height: 2.2rem;
        color: $white;
        font-weight: normal;
        text-transform: uppercase;
    }
    &-footer-phone {
        font-size: 2.2rem;
        letter-spacing: 0.08em;
        line-height: 2.6rem;
        color: $white;
        font-weight: bold;
        @include media-breakpoint-up(xl) {
            font-size: 3.2rem;
            line-height: 3.8rem;
        }
    }
    &-event-black {
        letter-spacing: 0.1em;
        color: #000;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;
        text-transform: uppercase;
    }

	&-secondary{
		color: $white;
		will-change: color;

	@include media-breakpoint-up(lg) {
		&:hover{
			color: $primary
		}
	}
	}
}
</style>
