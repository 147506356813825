<template>
	<section class="section-wrapper section-wrapper--dark">
		<base-container>
			<base-font variant="heading" color="black" class="event__title" >{{ value.section_title }}</base-font>
			<grid-template cols="3" :container="false">
				<event-card	 :event="event" v-for="event in pastEvents" :key="event.id"/>
			</grid-template>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import EventCard from '../../components/EventCard.vue'
export default {
	components: {
		BaseContainer,
		GridTemplate,
		EventCard
	},
	computed: {
		pastEvents () {
			return this.$app.page.pastEvents
		}
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
::v-deep .section-wrapper .event-wrapper {
	border: .1rem solid $gray-700;

	@include media-breakpoint-up(xl) {
            &:hover {
                border: .1rem solid $gray-700;
            }
    }
}

::v-deep .event__title {
        margin-bottom: 4rem;
		color: $secondary;
        @include media-breakpoint-up(xl) {
            margin-bottom: 6rem;
		}
}
</style>
