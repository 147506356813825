<template>
<div class="section-wrapper" id="news">
		<base-container :fluid="value.fluid">
			<cms-multi-section :value="`${prefix}.section_content`" />
		</base-container>
			<div :reverse="value.reverse">
				<div class="wrapper">
					<grid-template cols="6" className="grid-wrapper">
						<skeleton-loader v-for="(article, i) in articles" :key="i"  :isVisible="loading">
							<base-article
								:article="article"
								:prefix="prefix"
								:imageSize="i === 0 ? 'lg' : 'sm'"
							/>
						</skeleton-loader>
					</grid-template>
				</div>
			</div>
			<mobile-buttons :value="`${prefix}.section_content`"/>
</div>
</template>

<script>
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'
import { isPageFromNull } from 'utils/ssrCheck'
import BaseArticle from '../../components/article/BaseArticle.vue'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import MobileButtons from '~/blog/front/components/MobileButtons/MobileButtons.vue'

export default {
	async prefetch () {
		if (process.server) {
			await this.fetchArticles()

			this.$app.page.componentsData.newest_blog_posts = {}
			this.$app.page.componentsData.newest_blog_posts.articles = this.articles
		}
	},
	data () {
		return {
			articles: Array(3).fill({}),
			loading: true
		}
	},
	components: {
		BaseArticle,
		SkeletonLoader,
		GridTemplate,
		CmsMultiSection,
		MobileButtons
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		async fetchArticles () {
			const data = await this.$app.getService('rext').getArticles({ take: 2 })
			if (data.error) return this.articles
			this.articles = data
			this.timeout()
		},
		recreateDataFromComponentsData () {
			this.articles = this.$app.page.componentsData.newest_blog_posts.articles.slice(0, 3)
			this.timeout()
		},
		timeout () {
			setTimeout(() => {
				this.loading = false
			}, 500)
		}
	},
	mounted () {
		if (isPageFromNull(this.$app)) {
			this.recreateDataFromComponentsData()
		} else {
			this.fetchArticles()
		}
	}
}
</script>
<style lang="scss" scoped>
::v-deep .wrapper {
	height: 100%;
}

::v-deep .grid-wrapper {
	grid-template-columns: auto;

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2 ,1fr);
	}
}

::v-deep(.buttons-wrapper) {
	margin-top: 2rem!important;
}
</style>
