import { Page } from '~/cms/front/core/pages/Page'
import component from './NotFoundPage.vue'

class NotFoundPage extends Page {
	static alias = 'not-found'
	component = component
	statusCode = 404

	async init () {
		await this.sendNotFoundPage()
	}

	async sendNotFoundPage () {
		try {
			const service = await this.app.getService('rext')
			const { path, fullPath } = this.route
			await service.sendNotFoundPage(path, fullPath)
		} catch (err) { console.error(err) }
	}

	afterRedirect () {
		this.app._emit('not_found_page')
	}
}

export { NotFoundPage }
