<template>
    <section class="section-wrapper">
        <base-container>
        <cms-multi-section :value="`${prefix}.section_content`" />
        </base-container>
        <grid-template container cols="4" className="instructors-grid">
            <base-instructor v-for="(instructor, index) in value.instructors" :key="index" :item="instructor"/>
        </grid-template>
        <base-container>
        <mobile-buttons :value="`${prefix}.section_content`"/>
        </base-container>
    </section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import MobileButtons from '~/blog/front/components/MobileButtons/MobileButtons.vue'
import BaseInstructor from '../../components/molecules/BaseInstructor/BaseInstructor.vue'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
export default {
	components: {
		CmsMultiSection,
		MobileButtons,
		BaseInstructor,
		GridTemplate
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    padding: 6rem 0 0 0;
    @include media-breakpoint-up(xl) {
        padding: 12rem 0 0 0;
    }
    ::v-deep .instructors-grid {
        position: relative;
        margin-bottom: 3rem;
        grid-template-columns: repeat(2, 1fr);
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include media-breakpoint-up(xl) {
            grid-gap: 3rem;
            margin-bottom: 0;
        }
        &::after {
            content: '';
            position: absolute;
            bottom:0;
            left:0;
            width: 100%;
            height: 150px;
            background: linear-gradient(rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0.69) 46.09%, rgba(246, 246, 246, 0.84) 59.16%, rgba(246, 246, 246, 0.9) 64.6%, rgba(246, 246, 246, 0.98) 82.8%, rgba(246, 246, 246, 1) 95.55%, #f6f6f6 100%);
            pointer-events: none;
			@include media-breakpoint-up(xl) {
                height: 279px;
            }
        }
        .instructor-wrapper:nth-of-type(1n+5) {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }
}
</style>
