const AppLayout = {
	createLayouts () {
		this.getLayouts().forEach(Layout => {
			this.layouts[Layout.alias] = new Layout({ app: this })
		})
	}
}

export {
	AppLayout
}
