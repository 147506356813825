<template>
	<div class="contact-item">
		<base-svg :name="icon"/>
		<base-font weight="bold" uppercase class="contact-item-title" v-if="title">{{title}}: </base-font>
		<base-link :url="link" v-if="type !== 'text'" class="contact-item-content" :title="title +' do Wrocławskiej Szkoły Tańca'" :underline="true">
			{{content}}
		</base-link>
		<cms-editor-text
				v-else weight="medium" class="contact-item-content"
				:prioritizedValue="content"
				:props="{variant: 'paragraph', tag: 'p'}"
		/>
	</div>
</template>
<script>
export default {
	props: {
		icon: String,
		title: String,
		type: {
			type: String,
			default: 'text'
		},
		content: String
	},
	computed: {
		link () {
			return this.type === 'mail' ? `mailto:${this.content}` : `tel:${this.content.replace(/\s/g, '')}`
		}
	}
}
</script>
<style lang="scss" scoped>
	.contact-item {
		display: flex;
		gap: .3rem;
		width: 100%;
		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}

		::v-deep .svg-icon {
			height: 2rem;
			width: 2rem;
			margin-right: 1rem;
			@include media-breakpoint-up(lg) {
				height: 2.5rem;
				width: 2.5rem;
			}
			path {
				fill: $primary;
			}
		}
		&-title {
			grid-column: 2;
		}

		::v-deep &-content {
			a {
				@include link_styles();
				word-break: break-word;
			}
		}
	}

</style>
