import { config } from '@f/config/config'

class Seo {
	constructor (app, { page }) {
		this.page = page
		this.app = app
	}

	get layout () {
		return this.page.layout
	}

	getMeta () {
		if (!this.page.meta) return {}
		return {
			title: this.page.meta.title,
			meta: [
				{ name: 'description', content: this.page.meta.description },
				...this.robots,
				...this.openGraph
			].map(el => {
				el.vmid = el.name || el.property
				return el
			}),
			link: [
				{
					href: this.app.settings.env.origin + this.page.route.path,
					rel: 'canonical'
				},
				...this.canonical
			].map(el => {
				el.vmid = el.href
				return el
			}),
			script: [{
				vmid: 'schema',
				type: 'application/ld+json',
				json: this.schemaJSON
			}]
		}
	}

	get robots () {
		return this.page.meta.robots.map(content => ({
			name: 'robots',
			content
		}))
	}

	get canonical () {
		return this.page.meta.canonical.map(canonical => ({
			href: canonical.link,
			rel: 'canonical'
		}))
	}

	get openGraph () {
		return [
			{
				property: 'og:title',
				content: this.page.meta.title
			},
			{
				property: 'og:description',
				content: this.page.meta.description
			},
			{
				property: 'og:type',
				content: 'website'
			},
			{
				property: 'og:image',
				content: `${this.app.settings.env.origin}${config.services.rext.imagesUrl}${this.page.value.thumbnail ? this.page.value.thumbnail.path : this.layout.value.logo.path}?width=1200&height=630`
			},
			{
				property: 'og:site_name',
				content: this.app.settings.env.origin
			},
			{
				property: 'og:url',
				content: this.app.settings.env.origin + this.page.route.path
			}
		]
	}

	get schemaJSON () {
		return {
			'@context': 'http://schema.org',
			'@type': 'WebPage',
			url: this.app.settings.env.origin + this.page.route.path,
			datePublished: this.page.createdAt,
			dateModified: this.page.updatedAt,
			description: this.page.meta.description,
			inLanguage: this.page.route.meta.language,
			image: {
				'@type': 'ImageObject',
				height: 1200,
				width: 630,
				url: `${this.app.settings.env.origin}${config.services.rext.imagesUrl}${this.layout.value.logo.path}?width=229&height=60`
			},
			about: {
				'@type': 'Organization',
				'@id': this.app.settings.env.origin,
				url: this.app.settings.env.origin,
				name: this.app.settings.env.origin,
				telephone: this.app.settings.general.contact.phoneNumber,
				address: {
					'@type': 'PostalAddress',
					addressCountry: this.app.settings.general.contact.addressCountry,
					addressLocality: this.app.settings.general.contact.addressLocality,
					streetAddress: this.app.settings.general.contact.streetAddress,
					postalCode: this.app.settings.general.contact.postalCode
				},
				founder: [
					{
						'@type': 'Person',
						name: this.app.settings.general.contact.owner,
						telephone: this.app.settings.general.contact.phoneNumber
					}
				],
				logo: {
					'@type': 'ImageObject',
					height: 60,
					width: 160,
					url: `${this.app.settings.env.origin}${config.services.rext.imagesUrl}${this.layout.value.logo.path}?width=229&height=60`
				},
				sameAs: this.page.meta.canonical.map(canonical => canonical.link)
			}
		}
	}
}

export { Seo }
