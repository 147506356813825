import { Page } from '~/cms/front/core/pages/Page'
import component from './StatutePage.vue'

class StatutePage extends Page {
	static alias = 'statute'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('statute')
	}

	component = component
}

export { StatutePage }
