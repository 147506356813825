<template>
	<div class="simplified-layout-wrapper">
		<simplified-header/>
		<main class="content">
			<slot />
		</main>
		<!-- <base-cookie/> -->
		<simplified-footer class="simplified-layout-footer"/>
	</div>
</template>

<script>
// import BaseCookie from '~/website/front/components/organisms/BaseCookie'
import SimplifiedFooter from '~/authorization/front/components/SimplifiedFooter'
import SimplifiedHeader from '~/authorization/front/components/SimplifiedHeader'
export default {
	components: {
		// BaseCookie,
		SimplifiedFooter,
		SimplifiedHeader
	}
}
</script>
<style lang="scss" scoped>
.content {
	position: relative;
}
.simplified-layout-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
.simplified-layout-footer {
	margin-top: auto;
	padding: 1.5rem 0;
}

</style>
