<template>
  <div class="wrapper">
	<base-tile :tiles="value.tiles" :tiles_background="value.tiles_background"/>
  </div>
</template>

<script>
import BaseTile from '../../components/atoms/BaseTile/BaseTile.vue'

export default {
	components: { BaseTile },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>

</style>
