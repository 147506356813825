<template>
	<li class="accordion-wrapper">
		<div
			v-if="$slots['accordion-trigger']"
			class="accordion-trigger accordion"
			:class="{'active': visible}"
			@click="open"
		>
			<button class="accordion-button" :class="{'open': visible}">
				<slot name="accordion-trigger"></slot>
				<base-svg class="accordion-arrow" name="accordion_arrow"/>
			</button>
		</div>
		<transition
			name="accordion"
			@enter="start"
			@after-enter="end"
			@before-leave="start"
			@after-leave="end"
		>
			<div v-if="$slots['accordion-content']" class="accordion-content-wrapper" v-show="visible">
				<div class="accordion-content">
					<slot name="accordion-content"></slot>
				</div>
			</div>
		</transition>
	</li>
</template>

<script>
export default {
	props: {},
	inject: ['Accordion'],
	data () {
		return {
			index: null
		}
	},
	computed: {
		visible () {
			return this.index === this.Accordion.active
		}
	},
	methods: {
		open () {
			if (this.visible) {
				this.Accordion.active = null
			} else {
				this.Accordion.active = this.index
			}
		},
		start (el) {
			el.style.height = el.scrollHeight + 'px'
		},
		end (el) {
			el.style.height = ''
		}
	},
	created () {
		this.index = this.Accordion.count++
	}
}
</script>

<style lang="scss" scoped>

.accordion-enter-active,
.accordion-leave-active {
	will-change: height, opacity;
	transition: height 0.3s ease, opacity 0.3s ease;
	overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
	height: 0 !important;
	opacity: 0;
}

.accordion {
	&-wrapper {
		margin-bottom: 2rem;
		max-width: 69rem;
		background-color: $white;
		border-radius: .8rem;
	}
	&-button {

		padding: 2rem 3rem;
		border: none;
		outline: none;
		cursor: pointer;
		width: 100%;
		position: relative;
		border-radius: .8rem;
		background-color: $white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		transition: .2s ease-in;
		::v-deep &__title {
			line-height: 1.8rem;
			text-align: left;
			color: $black;
			@include media-breakpoint-up(lg) {
				line-height: 2.2rem;
				text-align: center;
			}
		}
		@include media-breakpoint-up(xl) {
			&:hover {
				background-color: $primary;

				.accordion-button__title {
					color: $white;
				}

				::v-deep .svg-icon path {
					fill: $white;
				}
			}
		}
		::v-deep .svg-icon {
			flex-shrink: 0;
			position: relative;
			transition: .3s ease-in;
			pointer-events: none;
			margin-left: 2rem;
			height: 11px;
			width: 11px;
			transform: rotate(180deg);
			path {
				fill: $black;
			}

			@include media-breakpoint-up(lg) {
				margin-left: 0;
			}

		}
		&.open  ::v-deep .svg-icon {
			transform: rotate(0deg);

			path {
				fill: $white;
			}
		}

		&.open ::v-deep &__title {
			color: $white;
		}
	}
	&.active &-button {
		background: $primary;
	}

	&-content-wrapper {
		transition: .5s ease-in-out;
		overflow: hidden;
		background: $white;
		border-radius: 0 0 0.8rem 0.8rem;
		& .accordion-content {
			padding: 2rem;

			@include media-breakpoint-up(lg) {
				padding: 3rem 6rem 4rem 3rem;
			}
		}
		.accordion-content-inner {
			padding: 3rem 6rem 4rem 3rem;
		}
	}
}
</style>
