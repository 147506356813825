import { Page } from '~/cms/front/core/pages/Page'
import component from './ReportAbsencePage.vue'

class ReportAbsencePage extends Page {
	static alias = 'report-absence'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('report-absence')
	}

	component = component
}

export { ReportAbsencePage }
