export async function asyncForEach (array, callback) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array)
	}
}
export async function asyncMap (array, callback) {
	const result = []
	for (let index = 0; index < array.length; index++) {
		result.push(await callback(array[index], index, array))
	}
	return result
}
