import { Input } from './Input'
import FieldTextareaComponent from '~/website/front/components/molecules/fileds/FieldTextarea'

class Textarea extends Input {
	component = FieldTextareaComponent
	type = 'email'
	maxLength = null
	label = 'Wpisz wiadomość*'

	constructor ({ maxLength, ...data }) {
		super(data)
		this.maxLength = maxLength
	}
}

export { Textarea }
