import { render, staticRenderFns } from "./BaseFont.vue?vue&type=template&id=adb0ab84&scoped=true&functional=true"
import script from "./BaseFont.vue?vue&type=script&lang=js"
export * from "./BaseFont.vue?vue&type=script&lang=js"
import style0 from "./BaseFont.vue?vue&type=style&index=0&id=adb0ab84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "adb0ab84",
  null
  
)

export default component.exports