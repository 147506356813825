<template>
	<div class="wrapper">
		<breadrcumb />
		<cms-multi-section value="content"/>
	</div>
</template>

<script>
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
export default {
	components: { Breadrcumb, CmsMultiSection },
	props: {
		alias: Number,
		title: String,
		description: String
	}

}
</script>
