<template>
	<div :class="value.section_content ? 'wrapper content-wrapper' : 'wrapper'">
		<cms-editor-text :value="`${prefix}.content`" :props="{variant: 'paragraph', tag: 'div'}" class="text" />
		<cms-multi-section :value="`${prefix}.section_content`" />
	</div>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
export default {
	components: { CmsMultiSection },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
::v-deep .content{

	a {
		@include link_styles()
	}
}
::v-deep .text{
	margin-bottom: 0;

	div {
		margin-bottom: 0;
	}

	em {
		margin-top: 1rem;
	}

	a {
		@include link_styles();
		overflow-wrap: anywhere;

		@include media-breakpoint-up(xl) {
		overflow-wrap: initial;
	}
	}

	p {
		@include p_styles();
	}
}

.wrapper.content-wrapper {
	display: flex;
	flex-direction: column;
	gap: 0rem 3rem;

	@include media-breakpoint-up(xl) {
		flex-direction: row;
	}

	.text a {
		@include link_styles();
	}
}
</style>
