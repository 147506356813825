import { Page } from '~/cms/front/core/pages/Page'
import component from './PoleDancePage.vue'

class PoleDancePage extends Page {
	static alias = 'pole-dance'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('pole-dance')
	}

	component = component
}

export { PoleDancePage }
