class ArticleCategory {
	static async fetch ({ app }) {
		const service = app.getService('rext')
		const data = await service.getArticleCategories()

		if (data.error) return []
		return data.map(item => ArticleCategory.create(item))
	}

	static create (data) {
		const instance = new ArticleCategory()
		Object.entries(data).forEach(([property, value]) => {
			if (property in instance) instance[property] = value
		})
		return instance
	}

	id
	name
	alias
}

export { ArticleCategory }
