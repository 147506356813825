<template>
	<div class="wrapper">
		<breadrcumb />
		<base-container>
			<cms-multi-section value="content"/>
		</base-container>
	</div>
</template>

<script>
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'

export default {
	components: { Breadrcumb, CmsMultiSection }

}
</script>
<style lang="scss" scoped>
::v-deep .photo-section .container {
	padding: 0;
}
::v-deep  .section-wrapper .wrapper.content-wrapper {
	flex-direction: column;

	.buttons-wrapper {
		margin-top: 2rem;
	}
}

::v-deep(.photo-section h3) {
	margin-top: 2rem;
}
</style>
