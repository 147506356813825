<template>
	<section class="section-wrapper" v-background="background">
		<base-container>
			<base-font variant="heading" tag="h2" color="black" class="event__title" >{{ value.section_title }}</base-font>
			<grid-template cols="3" :container="false" v-if="upcomingEvents && upcomingEvents.length">
					<event-card :event="event"  v-for="event in upcomingEvents" :key="event.id"/>
			</grid-template>
			<base-font variant="h2" v-else>Niestety, ale nie ma eventów w najbliższy weekend</base-font>
		</base-container>
	</section>
</template>
<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import EventCard from '../../components/EventCard.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
export default {
	components: {
		BaseContainer,
		GridTemplate,
		EventCard,
		BaseFont
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		background () {
			const path = 'homepage/offer/background-image.jpg'

			return {
				src: path,
				breakpoints: {
					base: { width: 1000, height: 650 },
					lg: { width: 1920, height: 1500 }
				}
			}
		},
		upcomingEvents () {
			return this.$app.page.events.upcoming
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper{
	background-repeat: no-repeat;
	background-size: cover;
}
.section-wrapper {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $primary;
        opacity: .8;
        z-index: -1;
    }

	::v-deep .event__title {
        margin-bottom: 4rem;
		color: $white;
        @include media-breakpoint-up(xl) {
            margin-bottom: 6rem;
		}
	}
}
</style>
