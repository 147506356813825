<template>
        <section class="section-wrapper" ref="calendar">
			<base-container>
				<base-font variant="h2" color="black" v-if="value.calendar_title">{{ value.calendar_title }}</base-font>
				<cms-editor-text class="description" :prioritizedValue="value.description" :props="{variant: 'paragraph', tag: 'div'}"/>
				<base-tab :value="value.calendars" :hasParagraph="false"/>
			</base-container>
        </section>
</template>
<script>
import { removeParagraph } from 'utils/removeParagraph.js'
import BaseTab from '~/website/front/components/molecules/BaseTab/BaseTab.vue'

export default {
	components: {
		BaseTab
	},
	methods: { removeParagraph },
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		getName () {
			return this.value.calendar_title.slice(2).split(' ').join('').toLowerCase()
		}
	},
	mounted () {
		this.$root.$refs[this.getName] = this.$refs.calendar
	}
}
</script>
<style lang="scss" scoped>
	.section-wrapper {
		padding: 0;

		::v-deep(.tab-buttons) {
			grid-template-columns: auto;

			.tab-buttons__button {
				min-width: 30rem;
			}
		}

		::v-deep(.tab-content) {
			padding-bottom: 0;
			margin-top: 2rem;

			.ql-editor{
				overflow-x: auto;
				width: 100%;
			}
		}

		::v-deep(.wrapper) {
			display: flex;
			flex-direction: column;
			@include media-breakpoint-up(xl) {
				gap: 0 4rem;
				flex-direction: row;
            }

			@include media-breakpoint-up(xxl) {
                flex-direction: row;
				gap: 0 12rem;
            }
		}
	}
	::v-deep .description {
		p {
			@include p_styles()
		}

		a {
			@include link_styles()
		}
	}
</style>
