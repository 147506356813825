<template>
	<div class="field-wrapper"
		:info="input.info"
	>
		<div class="field-label">
			<base-font
				variant="label"
				color="black"
				:for="input.name"
				@click.stop="$emit('click')"
			>{{input.label}}:</base-font>
		</div>
		<base-input
			class="input"
			:autocomplete="$attrs.autocomplete"
			:input="input"
			type="email"
		/>
		<base-error :error="input.error" />
	</div>
</template>

<script>
import mixin from '../utils/input.mixin'
import BaseError from '@f/components/BaseError'

export default {
	mixins: [mixin],
	components: {
		BaseError
	}
}
</script>

<style lang="scss" scoped src="../utils/fields.scss" />
