<template>
    <li class="translate-item">
        <button class="translate-choice" @click="$emit('languageSelected', country)">
            <base-font variant="menu-mobile" class="translate-lang" :class="{active: activeLang.toLowerCase() === country.toLowerCase()}">{{country.toUpperCase()}}</base-font>
        </button>
    </li>
</template>
<script>
export default {
	props: {
		country: String,
		activeLang: String
	},
	methods: {
		setLanguage () {
			this.$app.changeLanguage(this.country)
		}
	}
}
</script>
<style lang="scss" scoped>
.translate {
	&-item {
		@include media-breakpoint-up(xl) {
			transition: .3s ease-in;
		}
        ::v-deep .active.translate-lang {
            color: $primary;
            font-weight: bold;
            @include media-breakpoint-up(xl) {
                color: $primary;
                font-weight: medium;
            }
        }

	}
    &-choice {
		border: none;
		background: transparent;
		cursor: pointer;
		padding: 0;
        ::v-deep {
            .translate-lang {
                color: $secondary;
            }
        }
	}
}
</style>
