<template>
		<base-link v-if="item" class="dance-type" v-background="background" :to="item.dance_type_link" :title="item.dance_type_title + ' we Wrocławskiej Szkole Tańca'">
			<base-font variant="offer-header" uppercase class="offer-header">{{item.dance_type_title}}</base-font>
			<div class="image-wrapper">
				<cms-image
					class="offer-image"
					:class="`item${elemIndex}`"
					:src="item.dance_type_image"
					:base="{height: 200, position: 'top'}"
					:xl="{height: initial, position: 'top'}"
				/>
			</div>
		</base-link>
</template>

<script>
import BaseLink from '~/cms/front/components/BaseLink/BaseLink.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'

export default {
	components: { BaseFont, BaseLink },
	props: {
		item: {
			type: Object
		}
	},
	computed: {
		background () {
			const path = this.item.dance_type_bckgr.path

			return {
				src: path,
				breakpoints: {
					base: { width: 450, height: 240 }
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.dance-type {
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.05);
    overflow: hidden;
	position: relative;
	border-radius: .4rem;
	text-decoration: none;
	@include transition;

	::v-deep .offer-header {
		text-align: center;
		position: relative;
		color: $white;
		position: absolute;
		top: 2rem;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		z-index: 1;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
	}

	.image-wrapper {
		position: relative;
		max-height: 16rem;
		overflow: hidden;
		@include media-breakpoint-up(xl) {
			max-height: 22.5rem;
		}
		::v-deep .base-image {
			display: block;
			margin:  0 auto;
		}
	}

	@include media-breakpoint-up(xl) {
            &:hover {
				transform: translateY(-10px);

			}
        }
}
</style>
