<template>
	<div class="video-wrapper" v-html="value.video"/>
</template>
<script>
export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
::v-deep .video-wrapper {
		margin: 0 auto 2rem auto;

		iframe {
			border-radius: 1.3rem;
			display: block;
			max-width: 100%;
			margin: 0 auto;
		}
}
</style>
