import { Cookies } from '@f/core/modules/cookies/Cookies'
import { Redirection } from '@f/core/modules/redirection/Redirection'

const contextPlugins = {
	async created (context) {
		context.app.cookies = new Cookies(context)
		context.app.redirection = new Redirection(context)
	}
}

export default contextPlugins
