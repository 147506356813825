<template>
  <div class="section-wrapper">
		<base-container>
			<base-font variant="h2" color="black" v-if="value.section_title">{{ value.section_title }}</base-font>
			<div class="tiles-wrapper">
				<grid-template cols="2" :container="false">
					<tile v-for="(item, index) in value.tile_items" :key="index" :tile="item"/>
				</grid-template>
			</div>
		</base-container>
	</div>
</template>

<script>
import Tile from '../../components/atoms/Tile/Tile.vue'
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'

export default {
	components: { Tile, GridTemplate },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.section-wrapper {
	padding-top: 0;
}

::v-deep .grid-wrapper {
	grid-template-columns: auto;

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2 ,1fr) !important;
	}
}
</style>
