<template>
	<article-base-blockquote
		:title="value.blockquote_section_title"
		:description="value.blockquote_section_description"
		:class="setPaddingClasses"
	/>
</template>

<script>
import ArticleBaseBlockquote from '~/blog/front/components/ArticleBaseBlockquote/ArticleBaseBlockquote'

export default {
	components: { ArticleBaseBlockquote },
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		setPaddingClasses () {
			if (!this.value.padding) return ''

			const classes = {}
			this.value.padding.forEach(item => { classes[`margin-${item}`] = true })
			return classes
		}
	}
}
</script>
