import { InputEmail, InputPassword } from '@f/core/forms/inputs'
import { email } from 'utils/inputs/validation-front'
import { Form } from '@f/core/forms/Form'

class SignIn {
	status = ''
	processing = false
	constructor (app, strategy) {
		this.app = app
		this.strategy = strategy
	}

	get translations () {
		return this.app.translator.get('forms.auth.signIn')
	}

	async createForm () {
		await this.app.translator.prefetch('errors')
		await this.app.translator.prefetch('forms.auth.signIn')

		this.form = new Form()
		this.form.addInput(InputEmail.create({
			translations: () => this.app.translator.get('forms.auth.signIn.inputEmail'),
			attribute: 'email',
			name: 'email',
			validation: [email()]
		}))
		this.form.addInput(InputPassword.create({
			translations: () => this.app.translator.get('forms.auth.signIn.inputPassword'),
			attribute: 'password',
			name: 'password',
			rules: []
		}))
	}

	async signIn () {
		if (!await this.form.validate()) return false
		this.processing = true

		const data = await this.form.getValues()
		const { error } = await this.app.authorization.signIn(data)

		if (error) return this.strategy.error(error)

		await this.strategy.success()
	}

	markAsError (error) {
		this.processing = false

		const message = {
			SIGN_IN_DATA_ARE_INCORRECT: this.translations.signInStatus.ERROR
		}[error] || this.translations.signInStatus.SERVER_ERROR

		this.status = {
			message,
			code: 'error'
		}
	}

	markAsSuccess () {
		this.status = {
			message: this.translations.signInStatus.success,
			code: 'correct'
		}
	}
}

export { SignIn }
