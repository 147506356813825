<template>
	<transition
		name="snackbar"
	>
		<div v-if="snackbar" @click="close" class="snackbar">
			<base-font weight="medium" color="white" size="sm">{{snackbar.content}}</base-font>
			<base-cart color class="snackbar-cart"/>
		</div>
	</transition>
</template>

<script>
import BaseCart from '~/website/front/components/molecules/BaseCart/BaseCart.vue'
export default {
	components: { BaseCart },
	computed: {
		snackbar () {
			return this.$app.snackbar
		}
	},
	methods: {
		close () {
			this.snackbar.close()
		}
	}
}
</script>

<style lang="scss" scoped>
.snackbar {
	position: fixed;
	bottom: 20px;
	left: 20px;
	right: 20px;
    z-index: $level4;
	background: $primary;
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-left: 5px solid $secondary;

	@include media-breakpoint-up(md) {
		left: auto;
		width: 350px;
	}
}
.snackbar-enter-active,
.snackbar-leave-active {
	transition: transform 0.3s ease, opacity 0.3s ease;
	transform: translateX(0);
}

.snackbar-enter,
.snackbar-leave-to {
	opacity: 0;
	transform: translateX(100%);
}

</style>
