<template>
	<div class="map" v-animate="`opacity`" v-map-background="background">
        <base-button type="primary" class="map-button" url="https://g.page/nolimitsartpl?share" title="Sprawdź dojazd do Wrocławskiej Szkoły Tańca">Sprawdź dojazd</base-button>
	</div>
</template>
<script>
export default {
	computed: {
		background () {
			const path = 'homepage/map.png'

			return {
				src: path,
				breakpoints: {
					base: { width: 1920, height: 253 },
					xl: { width: 1920, height: 253 }
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
 .map {
	display: flex;
	justify-content: center;
	height: 250px;
	overflow: hidden;
    border-top: 4px solid $red-300;
    border-bottom: 4px solid $red-300;
    position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
    &::before {
        content: '';
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        width: 100%;
        background: $white;
        z-index: $level0;
        opacity: .65;
    }
    .map-button {
        z-index: $level1;
        position: absolute;
        top:50%;
        transform: translateY(-50%);

    }
}
</style>
