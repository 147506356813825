<template>
		<section class="section-wrapper pricing-section-wrapper">
			<div class="background" v-background="tilesBackgroundImage" />
			<base-container>
				<client-only>
					<cms-multi-section :value="`${prefix}.section_content`"/>
					<base-pricing-tile :tiles="value.pricing"/>
					<base-font variant="paragraph" color="black" class="text" :html="removeParagraph(value.pricing_text)" v-if="value.pricing_text"/>
					<mobile-buttons :value="`${prefix}.section_content`"/>
				</client-only>
			</base-container>
		</section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import BasePricingTile from '../../components/molecules/BasePricingTile/BasePricingTile.vue'
import MobileButtons from '~/blog/front/components/MobileButtons/MobileButtons.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import { removeParagraph } from 'utils/removeParagraph.js'
import ClientOnly from 'vue-client-only'
export default {
	components: { CmsMultiSection, BasePricingTile, MobileButtons, BaseFont, ClientOnly },
	methods: { removeParagraph },
	computed: {
		tilesBackgroundImage () {
			const path = this.value.pricing_background.path

			return {
				src: path,
				breakpoints: {
					base: { width: 1920 },
					xl: { width: 1920, height: 703 }
				}
			}
		}
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.pricing-section-wrapper {
	position: relative;
	background-color: $dark-section-background;

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: top;
		background-size: cover;
		z-index: -1;
		opacity: .1;
	}

	::v-deep .text {
		margin-top: 3rem;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.buttons-wrapper {
		margin-top: 3rem;
	}

	::v-deep .grid-custom {
		margin-bottom: 2rem;
	}
}

.photo-section {
	padding-bottom: 0;
}
</style>
