import axios from 'axios'

const fetchPagesPlugin = {
	async beforeCreate (context, inject, vueOptions) {
		if (process.server) {
			const host = 'http://back:3000'
			const { data } = await axios.get(host + '/path')

			const routes = data.map(page => ({
				path: page.path,
				meta: {
					alias: page.alias,
					language: page.language
				}
			}))
			context.router.addRoutes(routes)

			inject('routes', routes)
		}
		if (process.client) {
			const routes = window.__ROUTES__
			context.router.addRoutes(routes)
			inject('routes', routes)
		}
	}
}

export default fetchPagesPlugin
