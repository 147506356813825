<template>
	<span class="price">{{ priceFormated }}</span>
</template>

<script>
import BasePrice from '@f/components/BasePrice'

export default {
	extends: BasePrice,
	name: 'BasePrice',
	globalComponent: true
}
</script>
