<template>
    <li class="graphic-button">
        <base-button :url="getButtonsHref('timetable')" type="menu" :title="langs.timetable">{{langs.timetable}}</base-button>
    </li>
</template>

<script>
export default {
	computed: {
		langs () {
			return this.$app.translator.get('menu')
		},
		link () {
			return this.$app.layout.value.timetable
		}
	},
	methods: {
		getButtonsHref (path) {
			return this.$app.layout.value[path]
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('menu')
	}
}
</script>

<style lang="scss">
.graphic-button {
    display: none;
    @include media-breakpoint-up(xl) {
        display: block;
		margin-bottom: 0;
		.btn.btn-menu {
			margin-bottom: 0;
		}
    }
}
</style>
