<template>
	<span class="event-tags__text" :class="{'active': isActive, 'homepage' : isHomepage}" @click="handleTagClick(tag)" ref="tag">#{{ tag }}</span>
</template>

<script>
export default {
	props: {
		tag: String,
		eventWatcher: Function || null,
		eventType: String || Array
	},
	computed: {
		isActive () {
			return (this.isHomepage || !this.eventType) ? false : this.$app.page.choosedTags[this.eventType].includes(this.tag)
		},
		isHomepage () {
			return this.$app.page.route.meta.alias === 'homepage'
		}
	},
	methods: {
		async handleTagClick (tag) {
			this.$app.page.toggleChoosedTags([this.eventType], tag)

			await this.$app.page.fetchEventsByTags([this.eventType])
		}
	}
}
</script>

<style lang="scss" scoped>
	.event-tags__text {
		font-size: 1.4rem;
		letter-spacing: 0.04em;
		line-height: 2.6rem;
		color: $secondary;
		opacity: 0.5;
		text-transform: uppercase;
		margin-right: .5rem;
		cursor: pointer;
		@include transition;
		will-change: color, opacity;
		height: fit-content;

		&.active {
			opacity: 1;
			background-color: $primary;
			color: $white;
			padding: 0 1rem;
		}

		&:hover {
			opacity: 1;
			background-color: $primary;
			color: $white;
			padding: 0 1rem;
		}

		&:last-of-type {
			margin-right: 0;
		}

        &.homepage {
            cursor: default;
        }
	}
</style>
