import { ReactiveClass } from '../../ReactiveClass'

class Input extends ReactiveClass {
	_value
	_touched = false
	error = null
	type = 'text'

	constructor ({ attribute, value, name, translations, validation = [], hidden, disable, inputmode = 'text' }) {
		super()
		this.attribute = attribute
		this.name = name
		this.translations = translations
		this.validation = validation
		this.inputmode = inputmode
		if (value) this.value = value
		if (hidden) this.hiddenChecker = hidden
		if (disable) this.disableChecker = disable
		this.setTranslations()
		this.on('value:changed', this.valueChangedHandler.bind(this))
	}

	setTranslations () {
		const { label, placeholder, info } = this.translations()
		this.label = label || ''
		this.placeholder = placeholder || ''
		this.info = info || false
	}

	get isValid () {
		return this.error === null
	}

	get isTouched () {
		return this._touched
	}

	get isInvalidAndTouched () {
		return this.isTouched && !this.isValid
	}

	get isValidAndTouched () {
		return this.isTouched && this.isValid
	}

	get value () {
		return this._value
	}

	set value (value) {
		this._value = value
		this._emit('value:changed', value)
	}

	get formValue () {
		return this.value || ''
	}

	get hidden () {
		if (!this.hiddenChecker) return false
		return this.hiddenChecker()
	}

	get disable () {
		if (!this.disableChecker) return false
		return this.disableChecker
	}

	valueChangedHandler () {
		this.validate()
	}

	markAsTouched () {
		this._touched = true
	}

	markAsUnTouched () {
		this._touched = false
	}

	async touchAndValidate () {
		this.markAsTouched()
		await this.validate()
		return this.isValid
	}

	async validate () {
		if (this.disable) {
			this.error = null
			return true
		}
		for (const rule of this.validation) {
			const isValid = await rule.validate(this.value)
			if (!isValid) {
				this.error = rule.message()
				return false
			}
		}
		this.error = null
		return true
	}

	hideError () {
		this.error = null
		this.markAsUnTouched()
	}

	clear () {
		this.value = ''
		this.markAsUnTouched()
	}

	getValue () {
		if (!this.attribute) return this.value
		return {
			[this.attribute]: this.value
		}
	}
}

export { Input }
