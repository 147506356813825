<template>
		<div v-if="item" class="file">
			<base-font variant="h3" color="black" class="files__title">{{item.item_title}}</base-font>
			<div class="files__links">
				<button class="files__links-btn" v-if="item.path">
					{{ getFileExtension(item.path) }}
				</button>
				<base-link type="primary" underline="true" :href="item.path" v-if="item.path">Pobierz plik</base-link>
				<cms-editor-text
					:prioritizedValue="item.item_description"
					v-if="item.item_description"
					:props="{variant: 'paragraph', tag: 'p'}"
				/>
			</div>
		</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import { removeParagraph } from 'utils/removeParagraph.js'

export default {
	components: { BaseFont },
	props: {
		item: {
			type: Object
		}
	},
	methods: {
		removeParagraph,
		getFileExtension (path) {
			return path.substring(path.lastIndexOf('.') + 1, path.length, path.length) || path
		}
	}
}
</script>

<style lang="scss">
.file {
	background-color: $white;
	border-radius: $border-radius;
	padding: 2rem 4.5rem 2rem 2.8rem;
	&__title {
		text-transform: uppercase;
	}

	&__links {
		display: flex;
		align-items: center;
		&-btn {
			background-color: $primary;
			border: none;
			color: $white;
			text-transform: uppercase;
			letter-spacing: 0.04em;
			font-size: 1.6rem;
			line-height: 2.8rem;
			font-weight: bold;
			border-radius: $border-radius;
			padding: .7rem 1.6rem;
			margin-right: 1rem;
		}
	}
}
</style>
