class Layout {
	static alias
	component
	value = {}
	app
	ready = false

	get alias () {
		return this.constructor.alias
	}

	constructor ({ app }) {
		this.app = app
	}

	async init () {
	}

	async fetch () {
		if (this.ready) return true
		const data = await this.app.getService('rext').getLayout(this.alias)
		this.value = data.value
		this.ready = true
		await this.init()
	}

	async restore () {
		this.value = window.__LAYOUT__.value
		this.ready = true
		await this.init()
	}

	store () {
		return {
			value: this.value
		}
	}
}

export {
	Layout
}
