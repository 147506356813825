<template>
	<div class="contact">
		<breadrcumb />
		<section class="section-wrapper">
				<grid-template cols="2" :className="`grid`">
					<template #title>
						<base-heading tag="h1" heading="contact_page.contact_title" subheading="contact_page.contact_subtitle"  :layout="true" class="section-header" />
					</template>
					<div class="company-details" v-background="background">
						<cms-text value="contact_page.contactDataTitle" class="contact-heading" :props="{variant: 'medium-header', tag: 'h2'}"/>
						<contact-item icon="home" :title="translate.owner" :content="settingsLangs.owner"/>
						<contact-item icon="pin" :title="translate.headquaters" :content="headquaters"/>
						<contact-item icon="label" :title="translate.nip" :content="settingsLangs.nip"/>
						<contact-item icon="label" :title="translate.regon" :content="settingsLangs.regon"/>
						<cms-text value="contact_page.questionTitle" class="contact-heading" :props="{variant: 'medium-header', tag: 'h2'}"/>
						<contact-item icon="email" type="mail" :title="translate.email" :content="settingsLangs.email"/>
						<contact-item icon="phone" type="phone" :title="translate.phone" :content="settingsLangs.phoneNumber"/>
						<cms-text value="contact_page.answerTitle" class="contact-heading" :props="{variant: 'medium-header', tag: 'h2'}"/>
						<contact-item icon="calendar" :title="translate.weekdays" :content="pageLangs.weekdayHours"/>
						<contact-item icon="calendar" :title="translate.weekends" :content="pageLangs.weekendHours"/>
						<cms-text value="contact_page.accountNumberTitle" class="contact-heading" :props="{variant: 'medium-header', tag: 'h2'}"/>
						<contact-item :content="pageLangs.accountNumber"/>
						<cms-text value="contact_page.absenceTitle" class="contact-heading" :props="{variant: 'medium-header', tag: 'h2'}"/>
						<contact-item :content="pageLangs.absenceContent"/>
					</div>
					<div class="form-section">
					<cms-text value="contact_page.formTitle" class="contact-heading" :props="{variant: 'medium-header', tag: 'h2'}"/>
						<contact-form/>
					</div>
				</grid-template>
		</section>
		</div>
</template>
<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import ContactItem from '~/website/front/components/molecules/ContactItem/ContactItem.vue'
import ContactForm from '~/website/front/components/sections/ContactForm/ContactForm.vue'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'
import Breadrcumb from '~/website/front/components/molecules/Breadcrumb/Breadrcumb.vue'

export default {
	components: { ContactForm, ContactItem, GridTemplate, BaseHeading, Breadrcumb },
	data () {
		return {
			value: this.$app.page.value
		}
	},
	mixins: [
		pageMixin
	],
	computed: {
		translate () {
			return this.$app.translator.get('contact')
		},
		headquaters () {
			return `${this.settingsLangs.streetAddress}, ${this.settingsLangs.postalCode} ${this.settingsLangs.addressLocality}`
		},
		settingsLangs () {
			return this.$app.settings.general.contact
		},
		pageLangs () {
			return this.$app.page.value.contact_page
		},
		background () {
			const path = 'contact/contact_logo.svg'

			return {
				src: path,
				breakpoints: {
					base: { width: 300, height: 650 },
					lg: { width: 450, height: 400 },
					xl: { width: 450, height: 400 },
					xxl: { width: 600, height: 535 }
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	::v-deep .section-header {
    margin-bottom: 4rem;
		@include media-breakpoint-up(xl) {
			margin-bottom: 6rem;
		}
	}
	.contact-heading {
		margin-bottom: 2rem;
		margin-top: 2rem;
		text-transform: uppercase;
		@include media-breakpoint-up(lg) {
			margin-bottom: 3rem;
		}
		&:first-of-type {
			margin-top: 0;
		}
	}
	.company-details {
		padding: 1rem 0;
		background-repeat: no-repeat;
		background-position: center;
		display: flex;
		flex-direction: column;
		@include media-breakpoint-up(md) {
			background-position: initial;
			background-position-y: 100px;
		}
		@include media-breakpoint-up(md) {
			padding: 3rem 2rem;
			padding-bottom: 0;
		}
		@include media-breakpoint-up(lg) {
			background-position: top center;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 20rem;
		}
		::v-deep p {
			margin-bottom: 0;
		}
	}
	.bottom-wrapper {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		position: relative;
		@include media-breakpoint-up(lg) {
			margin-top: 0;
		}
		@include media-breakpoint-up(xl) {
			flex-direction: row;
		}

		.contact-details {
			@include media-breakpoint-up(xl) {
				margin-right: 3rem;
			}
		}
	}
	.form-section {
		padding-top: 3rem;
	}

	::v-deep .grid {
		grid-template-columns: repeat(1, 1fr);
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
</style>
