import { Page } from '~/cms/front/core/pages/Page'
import { serialize } from 'utils/strings'

class AbstractArticleListPage extends Page {
	articles = Array(3).fill({})
	loading = true
	articleCategories = []
	numberOfPages = 1
	currentPage = 1
	location = {}
	mostRead = {
		monthly: null,
		threeMonths: null,
		sixMonths: null
	}

	get showArticleInital () {
		return this.value.articlePerPage || 2
	}

	get header () {
		return {
			title: this.value.title
		}
	}

	async init () {
		await super.init()

		this.loading = false
	}

	async afterRedirect () {
		await this.fetchCategories()
		await this.fetchMostReadPosts()
	}

	async changePage (page) {
		this.currentPage = page

		this.updatePageQuery()

		await this.fetchArticles()

		window.scroll({ top: 0, left: 0, behavior: 'smooth' })
	}

	async fetchCategories () {
		const ArticleCategory = this.app.getEntity('article-category')
		this.articleCategories = await ArticleCategory.fetch({
			app: this.app
		})
	}

	async fetchMostReadPosts () {
		const service = this.app.getService('rext')
		const mostReadMonthly = await service.getMostReadArticles({ timeInterval: 1, limit: 3 })
		const mostReadThreeMonths = await service.getMostReadArticles({ timeInterval: 3, limit: 2 })
		const mostReadSixMonths = await service.getMostReadArticles({ timeInterval: 6, limit: 2 })
		this.mostRead.monthly = mostReadMonthly
		this.mostRead.threeMonths = mostReadThreeMonths
		this.mostRead.sixMonths = mostReadSixMonths
	}

	loadSettingsFromQuery () {
		const { page } = this.$route.query
		if (page) this.currentPage = Number(page)
	}

	updatePageQuery () {
		const query = {
			page: this.currentPage
		}
		history.replaceState(
			{},
			null,
			this.route.path + serialize(query)
		)
	}
}

export { AbstractArticleListPage }
