import { Input } from './Input'
import InputCheckboxComponent from '~/website/front/components/molecules/fileds/FieldCheckbox'

class InputCheckbox extends Input {
	component = InputCheckboxComponent
	label = `Zgadzam się na przetwarzanie moich danych osobowych, które zostaną użyte jedynie w celu realizacji tego
	zapytania (<a href="/polityka-prywatnosci" target="_blank">Poznaj politykę prywatności</a>)<span
	class="police-star">*</span>.`

	toggle () {
		this._touched = true
		this.value = !this.value
	}

	get formValue () {
		return this.value ? 1 : 0
	}

	clear () {
		this.value = false
	}
}

export { InputCheckbox }
