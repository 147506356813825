<script>
export default {
	props: {
		fluid: {
			type: Boolean,
			default: false
		}
	},
	classes (props, data) {
		const dynamicClasses = data.class || {}
		const staticClasses = data.staticClass ? data.staticClass.split(' ').reduce((final, item) => {
			final[item] = true
			return final
		}, {}) : {}

		return {
			...staticClasses,
			...dynamicClasses
		}
	}
}
</script>
