<template>
	<cms-multi-section value="content"/>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	components: {
		CmsMultiSection
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>
::v-deep .event-wrapper .event-content{
	&__title {
		margin-bottom: 0;
	}
}
</style>
