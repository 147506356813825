<template>
	<section class="copyright">
		<div class="inner-wrapper">
			<cms-text value="copyright_text" :props="{ color: 'black', tag: 'span'}" layout/>
			<base-font variant="paragraph" tag="span"> - realizacja strony internetowej:</base-font>
			<base-link underline type="footer" url="https://www.rekinysukcesu.pl/" title="Agencja interaktywna Wrocław - RekinySukcesu.pl">Agencja marketingowa Wrocław - RekinySukcesu.pl</base-link>
		</div>
	</section>
</template>
<script>

export default {
	props: {
		link: {
			type: String
		}
	}
}
</script>
<style lang="scss" scoped>
.copyright {
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	padding: 3rem 2rem;
	padding-bottom: 8rem;
	text-align: center;
	color: $primary;
	font-size: 1.4rem;
	letter-spacing: 0.04em;
	line-height: 2.2rem;
	background-color: $gray-500;
	& * {
		display: inline;
		text-align: left;
	}
	@include media-breakpoint-up(xl) {
		font-size: 1.6rem;
		flex-direction: row;
		padding: 3rem 0;
	}

	@media (orientation: landscape) and (max-width: 813px) {
		padding-bottom: 3rem;
	}
	.inner-wrapper {
		text-align: center;
		@include media-breakpoint-up(xl) {
			text-align: left;
		}
	}
	& a {
		color: $secondary;
	}
}
</style>
