export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getTranslations (path) {
			const query = path ? `?path=${path}` : ''
			const response = await this.http.get(`/translations${query}`)

			if (response.status !== 200) return { error: true }

			return response.data
		}
	}
}
