import { Event } from '~/events/front/core/entities/Event'
import { EventTag } from '~/events/front/core/entities/EventTag'
import { rextServiceExtend } from '~/events/front/core/services/RextService'
const UpcomingEvents = () => import('./sections/UpcomingEvents')
const RemainingEvents = () => import('./sections/RemainingEvents')
const HighlightedEvent = () => import('./sections/HighlightedEvent')
const PastEvents = () => import('./sections/PastEvents')
const EventNavSection = () => import('./sections/EventNavSection')
const EventsPage = async () => (await import('./core/pages/EventsPage/EventsPage')).EventsPage
const EventPage = async () => (await import('./core/pages/EventPage/EventPage')).EventPage

class EventsModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
		app.addPage('events', EventsPage)
		app.addPage('event', EventPage)
	}

	readyToAddEntities (app) {
		app.addEntity('event', Event)
		app.addEntity('event-tag', EventTag)
	}

	beforeInitReactive (app) {
		app.addSection('upcoming_events_section', UpcomingEvents)
		app.addSection('past_events_section', PastEvents)
		app.addSection('remaining_events_section', RemainingEvents)
		app.addSection('highlighted_event_section', HighlightedEvent)
		app.addSection('event_nav_section', EventNavSection)
	}
}

export { EventsModule }
