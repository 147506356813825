<template>
		<div class="opinion">
			<div class="opinion__heading">
				<h2 class="opinion__heading-name">{{ opinionItem.name }}</h2>
				<div class="opinion__heading-stars">
					<div class="opinion__heading-stars_star" v-for="item in parseInt(opinionItem.stars)" :key="item">
						<base-svg name="opinion_star" class="icon"/>
					</div>
				</div>
			</div>
			<div class="opinion__writing">
				<p class="opinion__writing-text">pisze:</p>
			</div>
			<div class="opinion__description">
				<base-font  variant="paragraph" class="opinion__description-text" :html="removeParagraph(opinionItem.description)"/>
			</div>
		</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import { removeParagraph } from 'utils/removeParagraph.js'

export default {
	components: { BaseFont },
	props: {
		opinionItem: {
			type: Object
		}
	},
	methods: {
		removeParagraph
	}
}
</script>

<style lang="scss">
.opinion {
	padding: 3rem;
	border-radius: $border-radius;
	background-color: $white;
	border: .1rem solid $gray-700;
	min-width: 100%;
	@include media-breakpoint-up(md) {
		min-width: calc(50% - 15px);
	}
	@include media-breakpoint-up(lg) {
		min-width: calc(33.3% - 20px);
	}
	@include media-breakpoint-up(xl) {
		padding: 3rem 3rem 5rem 3rem;
		min-width: calc(33.3% - 19.5px);
	}
	@include media-breakpoint-up(xxl) {
		min-width: 450px;
	}

	&__heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 3rem;

		&-name {
			font-size: 1.4rem;
			letter-spacing: 0.06em;
			line-height: 2.4rem;
			font-weight: 600;
			color: $primary;
			text-transform: uppercase;

			@include media-breakpoint-up(xl) {
				font-size: 1.6rem;
				line-height: 1.9rem;
				letter-spacing: 0.1em;
			}
		}

		&-stars {
			display: flex;
			align-items: center;
			gap: 0 .45rem;
			&_star {
				.icon {
					min-width: 1.1rem;
					max-width: 1.1rem;
				}
			}
		}
	}

	&__writing {
		display: flex;
		align-items: center;
		margin-bottom: 2rem;

		&::after {
			content: "";
			display: inline-block;
			height: .1rem;
			flex-grow: 1;
			background-color: $gray-800;
			opacity: .3;
		}

		&-text {
			font-size: 1.2rem;
			font-weight: bold;
			letter-spacing: 0.1em;
			color: $secondary;
			line-height: 1.4rem;
			text-transform: uppercase;
			margin-right: 1.5rem;
		}
	}

	&__description {

		.opinion__description-text {
			font-style: italic;
			font-weight: 300;
			margin-bottom: 0;
		}
	}
}
</style>
